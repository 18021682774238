.accordian {
  padding: 1rem;
  border: 2px solid $primary-lighter;
  margin-bottom: 1rem;
  @include border-radius(10px);
  background: #fff;
  &-title {
    cursor: pointer;
    text-transform: none;
    line-height: 1.5;
    margin-bottom: 0;
    @include user-select(none);
  }
  .expand-view {
    border-color: transparent;
    width: 2rem;
    height: 2rem;
    background-color: $primary-lighter;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $lightblack;
    cursor: pointer;
    margin-left: 1rem;
    align-self: center;
    min-width: 2rem;
    font-weight: bolder;
    @include border-radius(50%);
    @include user-select(none);
  }
}
