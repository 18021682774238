footer {
    font-family: $ubuntu-font;
    .footer-block {
        width: calc(100% - 310px);
        position: fixed;
        bottom: 0;
        z-index: 2;
        height: 30px;
        left: 220px;
        margin: 0 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;
        background: #fff;
        color: $lightblack;
        @include border-radius(8px);
        @media only screen and (min-width: 1600px) {
            left: 280px;
            width: calc(100% - 400px);
        }
    }
    .copyright {
        font-size: $size12;
    }
    .login-block {
        font-size: $size12;
    }
    .help-block {
        display: flex;
        align-items: center;
        font-weight: $font-weight-medium;
        font-size: $size12;
    }
}
.grecaptcha-badge {
    visibility: hidden;
}