.flow-status {
  display: flex;
  align-items: center;
  margin-top: 18px;
  &-block {
    text-align: center;
    display: inline-block;
    overflow: hidden;
    counter-reset: flag;
    @include border-radius(5px);
    @include box-shadow(0 2px 5px rgba(0, 0, 0, 0.25));
    .breadcrumb__step {
      text-decoration: none;
      outline: none;
      display: block;
      float: left;
      font-size: $size12;
      line-height: 36px;
      padding: 0 10px 0 44px;
      position: relative;
      background: $white;
      color: $lightblack;
      font-weight: $font-weight-semibold;
      @include user-select(none);
      @include transition(background 0.5s);
      &:first-child,
      &:last-child {
        border-radius: 0 5px 5px 0;
        padding-right: 14px;
      }
      &:first-child {
        padding-left: 36px;
        border-radius: 5px 0 0 5px;
        &::before {
          left: 14px;
        }
      }
      &:last-child::after {
        content: none;
      }
      &::before {
        content: counter(flag);
        counter-increment: flag;
        width: 14px;
        height: 14px;
        line-height: 14px;
        margin: 10px 0;
        position: absolute;
        top: 0;
        left: 24px;
        font-weight: $font-weight-semibold;
        background: $white;
        color: $lightblack;
        @include border-radius(100%);
        @include box-shadow(0 0 0 1px $lightblack);
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: -16px;
        width: 35px;
        height: 35px;
        z-index: 1;
        background: $white;
        @include transform(scale(0.707) rotate(45deg));
        @include border-radius(0 5px 0 50px);
        @include transition(background 0.5s);
        @include box-shadow(2px -2px 0 1px $lightblack);
      }
      &--completed {
        color: $white;
        background: #2eb66e;
        &::before {
          color: $lightblack;
        }
        &::after {
          background: #2eb66e;
        }
      }
      &--active {
        color: $white;
        background: #8a8b91;
        &::before {
          color: $lightblack;
        }
        &::after {
          background: #8a8b91;
          @include box-shadow(2px -2px 0 2px $gray-700);
        }
      }
    }
  }
}

.right-content-block {
  .line-highlight {
    color: $red;
    font-size: 16px;
  }
}

.bid-compare-block {
  width: 100%;
  display: block;
  .compare-bid-resp {
    display: block;
    width: 100%;
    padding: 5px 8px 4px;
    .compare-table {
      overflow-x: auto;
      &::-webkit-scrollbar-track {
        @include box-shadow(inset 0 0 6px rgba(206, 206, 206, 0.3));
        background-color: #f5f5f5;
      }
      &::-webkit-scrollbar {
        height: 4px;
        background-color: #f5f5f5;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #e0e3e7;
        background-image: -webkit-linear-gradient(
          45deg,
          rgba(255, 255, 255, 0.2) 25%,
          transparent 25%,
          transparent 50%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0.2) 75%,
          transparent 75%,
          transparent
        );
      }
      .table-in-table td:last-child {
        &.border-right-2 {
          border: none !important;
        }
      }
      .border-right-2 {
        border-right: 1px solid #ccc !important;
      }
      .qty-table {
        .item-title {
          width: 140px;
          display: inline-flex;
          align-items: center;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          margin-right: 10px;
        }
      }
      table {
        // border-collapse: separate;
        width: 100% !important;
        border-spacing: 0;
        margin-bottom: 6px;
        font-size: $size14;
        th {
          font-size: 13px;
          &.normal {
            font-weight: normal !important;
          }
          &:not(.normal) {
            font-weight: $font-weight-semibold;
          }
        }
        td,
        th {
          border: none;
          padding: 8px;
        }
        .compare-suppliers th,
        .compare-suppliers td {
          border-right: 1px solid #ccc;
          font-size: 13px;
        }
        .compare-suppliers td:last-child {
          border-right: none;
        }
        .head-title {
          position: sticky;
          width: 240px;
          left: 9px;
          top: auto;
          z-index: 1;
          background: $white;
          &.bb-1 {
            border-bottom: 1px solid #dee2e6;
          }
          &.highlight {
            color: $blue;
            font-weight: $font-weight-bold;
          }
        }
        .detail-desc {
          min-width: 280px;
          width: 280px;
          &.highlight {
            color: $blue;
            font-weight: $font-weight-bold;
          }
        }
        td {
          table {
            width: calc(100% - 18px);
            margin: 0;
            // border-collapse: separate;
            border-spacing: 2px;
            text-indent: 6px;
          }
          tr {
            line-height: 0;
          }
          table,
          th,
          td {
            border: 1px solid $black;
            padding: 0.75rem;
          }
        }
      }
    }
  }
}
.evaluation-list {
  background: $white;
  height: 360px;
  overflow: auto;
  @include border-radius(8px);
  @include box-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
}
.award-list {
  background: $white;
  height: 285px;
  overflow: auto;
  @include border-radius(8px);
  @include box-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
}

.rightPart img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.committee-team-card {
  @include border-radius(8px);
  padding: 10px 10px;
}
.bid-card-gray i::before {
  color: #8a8b91 !important;
}
.bid-card-green i::before {
  color: #00ff00 !important;
}
.envolope {
  position: absolute;
  z-index: 9;
  background: #ffffffc7;
  width: 100%;
  cursor: pointer;
}
.envolope2 {
  position: absolute;
  z-index: 9;
  background: #ffffffc7;
  width: 100%;
  height: 410px;
}
.score-status {
  font-size: 12px;
}
.committe-card {
  background: #fff9dc;
  @include border-radius(8px);
  // @include box-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
  padding: 10px;
  border: 1px solid #ffc107;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #ffc107;
  }
  .email {
    text-overflow: ellipsis;
    width: 150px;
    overflow: hidden;
    margin-bottom: 0px;
  }
}
.response-modal-card {
  background: #fff;
  @include border-radius(8px);
  @include box-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
  padding: 10px;
  margin: 1px;
  label {
    border: 2px solid #03a9f4;
    padding: 15px 40px;
    @include border-radius(10px);
    .checkmark {
      top: 17px;
      left: 14px;
      border: 2px solid #03a9f4 !important;
    }
    &.label-selected {
      border: 2px solid #2e740e;
      background: #63cc67;
      color: #fff;
      .checkmark {
        border: 2px solid #fff !important;
        &::after {
          background: #fff !important;
        }
      }
    }
    &:hover {
      border: 2px solid #2e740e;
      background: #63cc67;
      color: #fff;
      .checkmark {
        border: 2px solid #fff !important;
      }
    }
  }
  .label {
    font-size: 14px;
    padding-bottom: 5px;
  }
}

.card-checkbox {
  label {
    border: 2px solid #03a9f4;
    padding: 15px 40px;
    @include border-radius(10px);
    width: 100%;
    .checkmark {
      top: 17px;
      left: 14px;
      border: 2px solid #03a9f4 !important;
    }
    &.label-selected {
      border: 2px solid #2e740e;
      background: #63cc67;
      color: #fff;
      .checkmark {
        border: 2px solid #fff !important;
        &::after {
          background: #fff !important;
        }
      }
    }
    &:hover {
      border: 2px solid #2e740e;
      background: #63cc67;
      color: #fff;
      .checkmark {
        border: 2px solid #fff !important;
      }
    }
  }
}

.response-dropdown {
  position: absolute;
  img {
    margin-top: -25px;
  }
  .drop-down-option {
    margin-top: 60px;
  }
}
.response-bid-color {
  color: #f37658;
}
h5.detail i::before {
  color: #133563 !important;
}
.evaluation-width {
  flex: 0 0 20%;
  max-width: 20%;
  padding-left: 10px;
  padding-right: 10px;
}
.evaluation-team-card {
  .selected-tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .tag {
      color: #003663;
      padding: 4px 8px;
      border: 1px solid #ffc107;
      font-size: $size14;
      font-weight: bold;
      margin-right: 10px;
      margin: 5px;
      width: 195px;
      @include border-radius(8px);
      span {
        font-size: 12px;
        color: #2196f3;
      }
      .remove {
        cursor: pointer;
        font-size: 18px;
        float: right;
        font-weight: bold;
        &::before {
          color: #ff0000 !important;
        }
      }
      img {
        border: 3px solid #f4f9ff;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
}
.award-status-check img {
  border: none;
  border-radius: 0px;
  width: 25px;
  height: 25px;
}
.supplier-card {
  background: #fff;
  @include border-radius(8px);
  overflow: hidden;
  // border: 1px solid #ffc107;
  position: relative;
  @include box-shadow(0px 1px 4px 0px #0887ff40);
  padding: 10px;
  p {
    margin-bottom: 0px;
  }
  .icon,
  .icon-mobile-new:before {
    color: #183563 !important;
  }
  .card-edit {
    position: absolute;
    right: 12px;
    top: 10px;
    display: flex;
    line-height: 1;
    z-index: 2;
    @include border-radius(4px);
    .action-btn {
      padding: 4px 0;
      cursor: pointer;
    }
  }
  .card-add {
    position: absolute;
    top: 10px;
    right: 10px;
    line-height: 1;
    z-index: 2;
    cursor: pointer;
  }
  .border-right-1 {
    border-right: 1px solid $gray-700;
  }
  .card-border {
    width: 100%;
    position: relative;
    .info-section {
      display: flex;
      align-items: center;
      overflow: hidden;
      margin-bottom: 9px;
    }
    .detail {
      display: flex;
      align-items: center;
      margin-bottom: 3px;
    }
  }
  .empty-icon {
    height: 72px;
  }
  .delete {
    float: right;
    margin-top: -25px;
    .icon {
      color: #ff0000 !important;
    }
  }
  .award-status-check img {
    width: 18px;
    height: 18px;
  }
  &.contracts-supplier-card {
    background: #fff;
  }
}
.evaluation-team-card-view {
  .selected-tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .tag {
      color: #003663;
      padding: 4px 8px;
      border: 1px solid #ffc107;
      font-size: $size14;
      font-weight: bold;
      @include border-radius(8px);
      width: 235px !important;
      margin: 10px;
      span {
        font-size: 12px;
        color: #6d6e71;
      }
      img {
        border: 3px solid #f4f9ff;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
}
.terms-card-modal {
  background: #fff;
  @include border-radius(8px);
  // @include box-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
  padding: 10px;
  border: 1px solid #ffc107;
  .edit {
    color: #fdbf00;
    margin-right: 10px;
  }
  .delete {
    color: #ff0000;
  }
}
.sticky-sidebar-icons {
  position: fixed;
  top: 60px;
  z-index: 999;
  right: 20px;
  text-align: end;
  .notes-card {
    text-align: initial;
    margin-bottom: 20px;
    background: #fff;
  }
  .card-color-white {
    background: #edf3f8;
    height: 380px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px;
    width: 310px;
    @include border-radius(8px);
    @include box-shadow(0 2px 5px rgba(0, 0, 0, 0.25));
  }
}
.procure-tabs .icon {
  color: $lightblack;
}
.supp-cmnt {
  width: 280px;
}
.compare-table tr.com-table-data {
  border: 1px solid #dee2e6 !important;
  font-size: 13px;
}
.index-tab-new {
  border-right: 1px solid #ccc;
  margin-right: 10px;
  padding: 0px 5px;
}