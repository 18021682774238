.form-group-input,
.form-group-date,
.form-group-phone {
  position: relative;
  margin-bottom: 30px;

  label {
    display: flex;
    font-size: $size12;
    height: 40px;
    font-weight: $font-weight-bold;
    align-items: center;
    justify-content: center;

    &:not(.btn) {
      position: absolute;
      z-index: 1;
      // left: 10px;
      top: -12px;
      background: #eef3f8;
      // padding: 2px 7px;
      height: 0px;
      margin-bottom: 0;
      @include border-radius(4px);
      @include transition(300ms ease-in-out);
    }

    span {
      color: $red;
      margin-left: 4px;
    }

    .sublabel {
      color: $lightblack;
      font-style: italic;
      font-weight: $font-weight-regular;
    }

    .label-right {
      position: absolute;
      right: 0;

      &-bottom {
        position: absolute;
        right: 0;
        bottom: -30px;
      }
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="date"],
  input[type="url"],
  input[type="domain"],
  input[type="password"],
  textarea {
    min-height: 40px;
    font-size: $size14;
    font-weight: $font-weight-medium;
    font-family: $mulish-font;
    color: $lightblack;
    background-color: #eef3f8;
    border: 1px solid #202a5b70;
    width: 100%;
    padding: 3px 10px;
    @include border-radius(4px);
    @include transition(300ms ease-in-out);

    &:focus {
      background-color: #eef3f8;
      border: 1px solid $purple;
      @include box-shadow(0px 0px 8px rgba(45, 140, 255, 0.5));

      ~label {
        color: $purple;
      }
    }

    &.invalid {
      border: 1px solid $red;
    }

    &.search {
      &.search-collapse {
        min-height: 45px;
        padding: 3px 10px;
        width: 40px;
        border: none;
        background-color: #edf3ff;
        @include border-radius(8px);

        &:focus {
          width: 120px;
          padding-right: 30px;
        }
      }
    }

    &:hover,
    &:active,
    &:focus {
      outline: none !important;
    }
  }

  input[type="email"] {
    padding-left: 45px;

    &:focus {
      padding-left: 45px;
    }
  }

  input[type="number"] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  input[type="password"] {
    padding-right: 45px;

    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }
  }

  .invalid-msg {
    color: $red;
    font-weight: $font-weight-medium;
    font-size: $size12;
    margin-top: 4px;
  }

  .icon-toggle-eye,
  .calendar-icon,
  .search-icon,
  .email-icon,
  .time-icon {
    cursor: pointer;
    position: absolute;
    display: flex;
    top: 10px;
    right: 15px;
  }

  .time-icon {
    i::before {
      color: $purple;
    }
  }

  .search-icon {
    top: 10px;
    right: 13px;
  }

  .email-icon {
    top: 10px;
    left: 12px;
    width: 24px;
  }

  .icon-toggle-eye {
    top: 10px;
  }

  .btn-link {
    font-size: $size14;
    font-weight: $font-weight-regular;
  }

  .link {
    margin-top: 4px;
    color: $blue;
    font-size: $size14;
    cursor: pointer;
    display: inline-block;
    @include user-select(none);
  }

  .label-right-bottom {
    position: relative;
    right: 0;
    bottom: -4px;
    font-size: $size14;
  }

  .react-datepicker {
    font-family: $mulish-font;

    &-wrapper {
      width: 100%;

      input {
        padding-right: 45px;
        outline: none !important;

        &:disabled {
          background-color: #e9ecef !important;
        }
      }
    }

    &-popper {
      z-index: 2;
      margin-top: 2px !important;

      .react-datepicker {
        border: 1px solid #f2f2f2;
        @include box-shadow(0px 0px 4px #c4c4c4);
        @include border-radius(8px);

        &__header {
          background-color: $white;
          border-bottom: none;

          &--time {
            padding: 0;
          }
        }

        &__time {
          &-contaner {
            border-left: none;
          }

          &-list {
            &::-webkit-scrollbar-track {
              background-color: $gray-300;
              @include border-radius(10px);
            }

            &::-webkit-scrollbar {
              width: 8px;
              background-color: $gray-300;
            }

            &::-webkit-scrollbar-thumb {
              background-color: $gray-700;
              @include border-radius(10px);
            }
          }

          &-box,
          &-container {
            text-align: left !important;
            width: 92px !important;
          }

          &-list-item {
            font-size: $size12 !important;
          }
        }

        &__navigation {
          background-image: url("./../../images/icons/dropdown-arrow.svg");
          background-repeat: no-repeat;
          background-position: 1.5px 2px;
          width: 22px;
          height: 22px;
          border: none;
          top: 6px;

          &--previous {
            @include transform(rotate(90deg));
          }

          &--next {
            @include transform(rotate(-90deg));
          }
        }

        &__day-name {
          color: $purple;
          font-weight: $font-weight-medium;
        }

        &__month {
          margin: 0;

          &-contaner {
            border-right: 1px solid #aeaeae;
          }
        }

        &__day--selected,
        &__day--keyboard-selected {
          @include border-radius(50%);
          background-color: $purple;
        }

        &__time-list-item--selected {
          background-color: $purple !important;
        }

        &.date-timePicker {
          display: flex !important;

          .react-datepicker__navigation--next {
            right: 158px !important;
          }
        }
      }
    }
  }

  .react-tel-input {
    font-family: $mulish-font;

    input {
      min-height: 40px;
      font-size: $size12;
      font-weight: $font-weight-medium;
      font-family: $mulish-font;
      background-color: #eef3f8;
      color: $lightblack;
      border: 1px solid #202a5b70;
      width: 100%;
      @include border-radius(4px);
      @include transition(300ms ease-in-out);

      &:hover {
        border: 1px solid #aaaaaa;
      }

      &:focus {
        border: 1px solid $purple;
        @include box-shadow(0px 0px 8px rgba(45, 140, 255, 0.5));
      }
    }

    &.invalid {
      border: 1px solid $red;
    }

    .special-label {
      left: -4px;
      font-size: $size12;
      font-weight: $font-weight-bold;
      top: -22px;
    }

    .flag-dropdown {
      .selected-flag {
        padding: 0 0 0 16px;
      }

      .country-list {
        overflow-x: hidden;

        li {
          margin: 0;
        }
      }
    }
  }

  &.required {
    .react-tel-input {
      .special-label {
        &::after {
          position: absolute;
          content: "*";
          color: $red;
          margin-left: 4px;
          top: 1px;
          background: $white;
          width: 12px;
        }
      }
    }
  }
}

.form-group-phone {
  width: 100%;
}

.form-group-input {
  input[type="file"] {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 0;
  }

  .file-hint {
    font-size: $size14;
    color: $gray-1000;
  }

  textarea {
    resize: none;
  }
}

.form-group-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  font-size: $size14;
  margin-bottom: 24px;

  &.span-visible {
    font-size: $base-font-size !important;
    font-weight: $font-weight-bold !important;
  }

  @include user-select(none);

  &.weekdays-selector {
    font-size: $size12;

    &:not(:last-of-type) {
      margin-right: 2px;
    }

    .checkmark {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include border-radius(8px);
    }

    input[type="checkbox"]:checked~.checkmark {
      color: $white;
      background-color: $dodger-blue;
      border: none;

      &:after {
        display: none;
      }
    }
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked~.checkmark {
      background-color: $purple;
      border-color: $purple;

      &:after {
        display: block;
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    width: 18px;
    height: 18px;
    border: 1px solid $purple;
    @include border-radius(2px);

    &::after {
      display: none;
      content: "";
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      text-align: center;
      top: 1px;
      width: 4px;
      height: 10px;
      border: solid $white;
      border-width: 0 2px 2px 0;
      @include transform(rotate(45deg));
    }
  }
}

.form-group-radio {
  display: inline-block;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  font-size: $size14;
  // margin-bottom: 30px;
  @include user-select(none);

  input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked~.checkmark {
      border-color: $purple;

      &:after {
        display: inline-block;
        background-color: $purple;
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    width: 18px;
    height: 18px;
    border: 1px solid $gray-900;
    @include border-radius(50%);

    &::after {
      display: none;
      content: "";
      position: absolute;
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      text-align: center;
      width: 8px;
      height: 8px;
      background-color: $white;
      @include border-radius(50%);
    }
  }
}

.form-group-select {
  position: relative;
  margin-bottom: 30px;

  label {
    position: absolute;
    z-index: 1;
    // left: 10px;
    top: -20px;
    background: $white;
    // padding: 2px 7px;
    height: 0px;
    display: block;
    font-size: $size12;
    font-weight: $font-weight-bold;
    margin-bottom: 0;
    @include border-radius(4px);
    @include transition(300ms ease-in-out);

    span {
      color: $red;
    }

    .label-right {
      position: absolute;
      right: 0;
    }
  }

  .invalid-msg {
    color: $red;
    font-weight: $font-weight-medium;
    font-size: $size12;
    margin-top: 4px;
  }

  .SelectWidget {
    &:has(.select__control--menu-is-open)~label {
      color: $purple;
    }

    &.invalid {
      border: 1px solid $red;
      border-radius: 4px;
    }

    .select__control {
      cursor: pointer;
      min-width: 100%;
      padding: 3px 10px;
      // margin-bottom: 10px;
      min-height: 40px;
      background-color: #eef3f8;
      border: 1px solid #202a5b70;
      width: 100%;
      @include border-radius(4px);
      @include transition(300ms ease-in-out);

      .select__value-container {
        height: 100%;
        padding: 0;

        &.select__value-container--has-value {
          .select__single-value {
            position: relative;
            font-size: $size14;
            font-weight: $font-weight-medium;
            font-family: $mulish-font;
            color: $lightblack;
            top: calc(50% - 7px);
            margin: 0;
            @include transform(translateY(0));
          }

          .select__multi-value {
            background-color: $primary-light;
            border: 1px solid $blue;
            margin-right: 8px;
            @include border-radius(20px);

            &__label {
              font-size: $size12;
              font-weight: $font-weight-regular;
              color: $blue;
            }

            &__remove {
              cursor: pointer;
              color: $blue;

              &:hover {
                background-color: transparent;
              }
            }
          }
        }

        .select__placeholder {
          // position: relative;
          font-size: $size14;
          font-weight: $font-weight-medium;
          font-family: $mulish-font;
          top: calc(50% - 10px);
          margin: 0;
          @include transform(translateY(0));
        }
      }

      .select__indicators {

        .select__indicator-separator,
        .select__clear-indicator {
          display: none;
        }

        .select__dropdown-indicator {
          width: 22px;
          @include transition(all linear 0.16s);

          &.select__dropdown-indicator {
            padding: 0;
            color: $purple;
          }
        }
      }

      &.select__control--menu-is-open {
        border: 1px solid $purple;
        @include box-shadow(0px 0px 8px rgba(45, 140, 255, 0.5));

        .select__indicators {
          .select__dropdown-indicator {
            color: $purple;
            @include transform(rotate(180deg));
          }
        }
      }

      &.select__control--is-focused {
        @include box-shadow(none);
      }
    }

    .select__menu {
      z-index: 99;
      margin: 0;
      background: $white;
      border: 1px solid rgba(45, 140, 255, 0.2);
      @include box-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
      @include border-radius(5px);

      .select__menu-list {
        max-height: 250px;
        padding: 4px 0;

        .select__option {
          cursor: pointer;
          font-size: $size14;
          padding: 10px 22px;
          color: $gray-1000;

          &.select__option--is-focused {
            background-color: $gallery;
          }

          &.select__option--is-selected {
            background-color: $primary;
            color: $white;
            font-weight: $font-weight-bold;
          }
        }
      }

      .select__menu-notice--no-options {
        padding: 0 10px;
        text-align: left;
        font-size: $size14;
      }
    }

    &.multiple {
      position: relative;

      &::before {
        font-family: "icomoon";
        content: "\e939";
        color: $lightblack;
        position: absolute;
        top: 8px;
        right: 14px;
        z-index: 1;
      }

      .select__control {
        padding-right: 40px;
        height: auto;

        .select__value-container {
          align-items: flex-end;
          // .select__placeholder {
          // @include transform(translateY(-20%));
          // }
        }
      }

      .select__menu {
        .select__menu-list {
          padding: 4px 0;

          .select__option {
            border-bottom: 1px solid $gray-700;
            padding: 4px 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:last-child {
              border-bottom: none;
            }

            .label-value {
              display: flex;
              align-items: center;
              color: $black;
              font-size: $size14;
              font-weight: $font-weight-medium;

              .label-subtext {
                color: red;
                font-size: $size12;
                font-weight: $font-weight-regular;
                margin-left: 8px;
              }
            }
          }
        }
      }

      .select__indicators {
        display: none;
      }
    }
  }

  &.select-search {
    .SelectWidget .select__control.select__control--menu-is-open {
      .select__indicators .select__dropdown-indicator {
        @include transform(rotate(0) !important);
      }
    }
  }
}

.form-group-switch {
  .switch {
    position: relative;
    display: flex;

    .slider-round {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $yellow-300;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      width: 30px;
      height: 12px;
      @include border-radius(6px);

      &::before {
        position: absolute;
        content: "";
        width: 16px;
        height: 16px;
        left: -2px;
        bottom: -2px;
        background-color: $white;
        @include transition(0.4s);
        @include border-radius(50%);
        @include box-shadow(0px 0px 2px $gray-500);
      }

      &.checked {
        background: $yellow;

        &:before {
          background-color: $purple;
          @include transform(translateX(20px));
        }
      }
    }

    input {
      opacity: 0;
      width: 0;
      height: 0;
      display: none;

      &:focus+.slider-round {
        @include box-shadow(0 0 1px $yellow);
      }
    }
  }

  &.color-green {
    display: flex;
    align-items: center;

    .switch {
      .slider-round {
        background: $gray-900;

        &::before {
          border: 1px solid $gray-900;
        }

        &.checked {
          background: #2ed573;

          &:before {
            background-color: $white;
            border: 1px solid #2ed573;
            @include transform(translateX(20px));
          }
        }
      }

      input {
        &:focus+.slider-round {
          @include box-shadow(0 0 1px #2ed573);
        }
      }
    }

    span.slider-status {
      display: flex;
      margin-left: 45px;
    }
  }
}

.form-control {

  &:disabled,
  &[readonly] {
    background-color: #e9ecef !important;
  }
}

.form-group-domain {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100%;

  input {
    @include border-radius(4px 0 0 4px !important);
  }

  span {
    font-size: $size14;
    // border-left: 0;
    @include border-radius(0 4px 4px 0 !important);
    height: 40px;
  }
}

.form-group-select .SelectWidget.invalid .select__control {
  border: none;
}

.rdw-editor-wrapper {
  height: 200px;
  margin-bottom: 15px;
  overflow: hidden;
  border: 1px solid #aaaaaa;
  border-radius: 8px;
}

textarea.editorTextArea {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
}

.content-editor-display {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

.rdw-editor-toolbar.toolbar-class {
  position: sticky;
  z-index: 9;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  display: none;
  font-family: $mulish-font;
}

span.react-datepicker__month-read-view--selected-month,
span.react-datepicker__year-read-view--selected-year {
  font-weight: bold;
}

.user-icon-label {
  height: 30px;
  width: 30px;
  border: 3px solid #f4f9ff;
  cursor: pointer;
  border-radius: 50%;
  margin-right: 5px;
}

.catlogue-upload-image {
  position: relative !important;
  height: 100% !important;
  background: none !important;

  .input-box {
    display: contents;

    .form-group-input {
      margin-bottom: 0 !important;
    }
  }

  .btn {
    margin-left: 12px;
  }
}

.icon-share-upload {
  border: 2px dotted #206ecc;
  padding: 50px;
}

span.unmlimited-amnt {
  position: relative;
  top: -30px;
  color: red;
  font-size: 14px !important;
}

.form-group-email {
  input {
    padding-left: 45px;

    &:focus {
      padding-left: 45px;
    }
  }
}

.import-excel {
  .form-group-input {
    margin-bottom: 0px !important;
  }
}

.report-input-style {

  .form-group-input input[type="text"],
  .form-group-input input[type="email"],
  .form-group-input input[type="number"],
  .form-group-input input[type="url"],
  .form-group-input input[type="domain"],
  .form-group-input input[type="password"],
  .form-group-input textarea,
  .form-group-date input[type="text"],
  .form-group-date input[type="email"],
  .form-group-date input[type="number"],
  .form-group-date input[type="url"],
  .form-group-date input[type="domain"],
  .form-group-date input[type="password"],
  .form-group-date textarea,
  .form-group-phone input[type="text"],
  .form-group-phone input[type="email"],
  .form-group-phone input[type="number"],
  .form-group-phone input[type="url"],
  .form-group-phone input[type="domain"],
  .form-group-phone input[type="password"],
  .form-group-phone textarea,
  .form-group-select .SelectWidget .select__control {
    background-color: #fff;
    border: 1px solid #202a5b70;
  }

  .form-group-date {
    margin-bottom: 10px !important;
  }
}

.attach-file {
  .helper-text {
    display: flex;
    font-size: $size12;
    font-weight: $font-weight-bold;
    height: 30px;
    margin-bottom: 0.25rem;

    span {
      color: $red;
      margin-left: 4px;
    }
  }

  .file-attach {
    padding: 40px 20px;
  }
}

.invalid-msg {
  color: $red;
  font-weight: $font-weight-medium;
  font-size: $size12;
}

.helper-input {
  font-size: $size12;
  height: 28px;
  font-weight: $font-weight-bold;
}

label.catlogue-upload-image {
  width: 425px;
}

.input-img {
  border-radius: 50%;
  box-shadow: 0px 3px 7px #0000004d !important;
  cursor: pointer;
}

.upload-product {
  position: relative;
  border: 2px dashed #a8a8a8;
  background-color: #ffffff;
  width: 305px;
  padding: 10px;
  text-align: center;
  margin: 0px 100px;
  height: 275px;
  @include border-radius(16px);
  font-size: 12px !important;

  .remove-block {
    display: none;
  }

  &:hover {
    .remove-block {
      background-color: #0b0e0e78;
      position: absolute;
      top: 0;
      left: 0px;
      border-radius: 10px;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        cursor: pointer;
      }
    }
  }
}

.upload-btn .btn {
  border: 2px dashed;
  box-shadow: none !important;
  color: #9d9d9d !important;
  font-size: 12px !important;
  border-color: #a8a8a8 !important;
  padding: 8px 25px;
}

.upload-btn p {
  font-size: 12px !important;
  margin-bottom: 0px !important;
}

.upload-btn .btn:hover {
  background-color: #fff;
}

.upload-btn .form-group-input {
  margin-bottom: 10px;
}

.addNewProd i:before {
  color: #3a436a;
}

.create-product-new {
  input[type="text"] {
    width: 480px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #898989;
    padding: 0px 50px 0px 160px;
    position: relative;
    left: -140px;
  }

  .select__control {
    z-index: 99;
    background: none !important;
    border: none !important;
    position: relative;
    left: 10px;
    box-shadow: none !important;
    border-radius: 0px !important;

    &.select__control--is-focused {
      box-shadow: none !important;
    }

    &.select__control--menu-is-open {
      border: none !important;
      box-shadow: none !important;
    }
  }

  i {
    position: relative;
    left: -175px;
    padding: 5px;
  }

  .icon-search:before {
    color: #3a436a99;
  }

  .prod-border {
    width: 1px;
    background: #3a436a99;
    height: 40px;
    z-index: 2;
  }

  .prod-border2 {
    width: 1px;
    // background: #3a436a99;
    height: 40px;
    z-index: 2;
    position: relative;
    left: -180px;
  }
}

.view-product-details {
  p {
    margin-bottom: 0px;
    font-size: 14px;

    &.highlight {
      font-weight: bold;
      font-size: 16px;
      color: $lightblack;

      span {
        margin-left: 25px;
      }
    }
  }
}

.prod-area-specs .highlight {
  border: 1px solid #ccc;
  padding: 5px;
}

.upload-excel-modal {
  h3 {
    color: $lightblack;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
  }

  .form-group-input {
    margin-bottom: 0px !important;

    label {
      width: 345px;
      display: inline-block;

      &:not(.btn) {
        position: relative !important;
        height: 100%;
        background: none;
        top: 0px;
      }
    }
  }

  .btn {
    &.btn-upload {
      border: 1px solid #f37658;
      border-radius: 0px;
      color: #f37658;
      box-shadow: none !important;
    }
  }
}

.view-prod a {
  color: #3a436a;
}

.view-prod-image img {
  border: 1px solid #ccc;
  width: 300px;
  padding: 5px;
}

a.prod-link {
  font-size: 16px;
  margin-left: 14px;
  color: #017aea;
  text-decoration: underline;
}

.search-msg {
  color: #ff0000;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  top: -26px;
  left: 20px;
}

.prod-specs {
  background: #f8f9fa;
  padding: 45px;
  @include border-radius(10px);
  @include box-shadow(0px 0px 8px rgba(45, 140, 255, 0.5));
}

.create-new-template {
  background-color: #fff;
  border: 1px double #eee;
  color: rgb(41, 146, 208);
  padding: 20px 10px 25px 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 10px;
  cursor: pointer;
  box-shadow: 4px 4px 3px #999999;
}

.based-auction {
  border: 1px solid #8a8a8a;
  padding: 25px 35px;
  margin: 5px;
  border-radius: 4px;
}

.switch {
  border: 1px solid transparent;
  display: flex;
  padding: 5px;
  border-radius: 8px;

  .btn-switch {
    border: none;
    background: none;
    padding: 8px;
    border-radius: 5px;

    &.active-switch {
      background: #5d4ce5;
      color: #fff;
    }
  }
}

.create-event-new {
  .prod-border2 {
    width: 1px;
    // background: #3a436a99;
    height: 40px;
    z-index: 2;
    position: relative;
  }

  .eventDurationAuction {
    width: 260px !important;
  }

  .form-group-input,
  .form-group-date {
    margin-bottom: 0px !important;
    width: 215px;
  }

  .form-group-select .SelectWidget .select__control {
    min-width: 110px;
  }
}

.checkbox-menu {
  padding: 10px;

  .form-group-checkbox {
    margin-bottom: 10px;
  }

  label.form-group-checkbox {
    font-size: 14px !important;
    font-weight: 600;
  }
}

button.create-new {
  background: none;
  border: none;
  color: #5d4ce5;
  text-decoration: underline;
  font-weight: bold;
}

.create-event {
  .form-group-select {
    margin-bottom: 0px;

    .select__option:nth-child(1) {
      color: #017aea !important;
      text-decoration: underline;
    }
  }
}

.auction-stratergy {
  // font-size: 14px;
  // color: #212529;

  h5 {
    font-size: 14px;
    // color: #212529;
    margin-bottom: 10px;
  }

  .form-group-checkbox.span-visible {
    font-size: 14px !important;
  }

  span {
    font-size: 14px !important;
    font-weight: bold;
  }

  .form-group-date .react-datepicker-popper .react-datepicker__time-container {
    width: 58px !important;
  }

  .react-datepicker__time-container .react-datepicker__time {
    width: 100px;
  }

  .auction-links p {
    color: $lightblack;
    cursor: pointer;
    font-weight: bold;

    span {
      text-decoration: underline;
      padding-left: 5px;
    }
  }

  .form-group-radio .checkmark {
    border: 2px solid #6c757d;
  }

  .rank:before {
    color: #ffc107;
  }
}

.select-suppliers {
  width: 100%;
  border: 1px solid #ccc;
  padding: 5px 10px;

  .prod-border2 {
    width: 1px;
    // background: #3a436a99;
    height: 35px;
    z-index: 1;
    position: relative;
    top: 0px;
  }

  .suppliers-box {
    width: 77%;
  }

  p.select-sup-btn {
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 0px;
  }

  .selected-tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: $size14;

    .tag {
      color: #5c5c5c;
      background-color: #f2f2f2;
      padding: 4px 8px;
      font-size: $size14;
      font-weight: $font-weight-regular;
      margin-right: 10px;
      margin: 5px;
      @include border-radius(20px);

      .remove {
        cursor: pointer;
        margin-left: 4px;
        color: #000;
      }

      .icon-close:before {
        color: #000;
      }
    }
  }
}

.quantity-sel {
  .SelectWidget .select__control {
    min-width: 120px;
    margin-left: -5px;
  }
}

.select-supp {
  .form-group-select .SelectWidget.multiple .select__control {
    width: 450px;
    height: 40px;
  }

  .form-group-select .SelectWidget.multiple::before {
    top: 8px;
    font-size: 18px;
    color: #6c757d;
    border-left: 1px solid #ccc;
    padding-left: 10px;
  }

  .icon-checkbox:before {
    color: #6c757d;
  }

  .invite-supp {
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    padding-top: 10px;
  }

  .icon-yes:before {
    color: #6c757d;
  }

  .form-group-select .SelectWidget.multiple .select__control .select__value-container {
    align-items: center;
  }
}

.select-proxy {
  .form-group-select .SelectWidget.multiple .select__control {
    // width: 450px;
    height: 40px;
  }

  .form-group-select .SelectWidget.multiple::before {
    top: 8px;
    font-size: 18px;
    color: #6c757d;
    border-left: 1px solid #ccc;
    padding-left: 10px;
  }

  .icon-checkbox:before {
    color: #6c757d;
  }

  .invite-supp {
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    padding-top: 10px;
  }

  .icon-yes:before {
    color: #6c757d;
  }

  .form-group-select .SelectWidget.multiple .select__control .select__value-container {
    align-items: center;
  }
}

.react-tel-input .special-label {
  background: none !important;

  &::after {
    background: none !important;
  }
}

.right-block {

  .form-group-input label:not(.btn),
  .form-group-date label:not(.btn),
  .form-group-phone label:not(.btn) {
    color: #5f6368;
    font-weight: 400;
    font-size: 15px;
  }

  .form-group-phone .react-tel-input .special-label {
    color: #5f6368;
    font-weight: 400;
    font-size: 15px;
  }

  .form-group-input,
  .form-group-date,
  .form-group-phone {
    margin-bottom: 40px !important;
  }

  h1 {
    color: #202124;
  }
}

.outSideMenuList {
  .select__option {
    cursor: pointer;
    font-size: $size14;
    padding: 10px 22px;
    color: $gray-1000;

    &.select__option--is-focused {
      background-color: $gallery;
    }

    &.select__option--is-selected {
      background-color: $primary;
      color: $white;
      font-weight: $font-weight-bold;
    }
  }
}