.editor-card {
  @include box-shadow(0px 0px 6px -1px rgba(0, 0, 0, 0.2));
}

.document-editor {
  position: relative;
  z-index: 10;
  height: 100%;
  .editor-header {
    display: flex;
    justify-content: space-between;
    padding: 12px 8px;
    background-color: $white;
    border-bottom: 1px solid $gray-700;
    .download-icon {
      display: flex;
      align-items: center;
      padding: 0 8px;
      cursor: pointer;
    }
  }
  #document-container {
    overflow-y: auto;
    overflow-x: hidden;
    .e-de-tlbr-wrapper {
      height: 50px;
    }
    .e-de-ctnr-toolbar,
    .e-de-toolbar.e-toolbar .e-toolbar-items,
    .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-separator,
    .e-de-showhide-btn {
      height: 52px;
      .e-toolbar-item:not(.e-de-separator) {
        height: 40px;
        width: 30px;
        margin: 0;
        justify-content: center;
      }
    }
    .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
      padding-bottom: 0 !important;
    }
    .e-de-toolbar.e-toolbar
      .e-toolbar-items
      .e-toolbar-item
      .e-tbar-btn.e-btn
      .e-tbar-btn-text {
      display: none;
    }
    .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
      margin: 0;
    }
    .e-toolbar .e-tbar-btn:hover {
      border-radius: 2px;
    }
    .e-documenteditor .e-de-op .e-de-op-close-icon {
      height: auto !important;
    }
    .e-de-tool-ctnr-properties-pane {
      height: calc(100% - 90px) !important;
    }
    .e-de-review-pane {
      width: 320px;
    }
    // pdf stylings
    #document-container_editor_viewerContainer {
      width: calc(100% - 600px);
    }
    .e-pv-viewer-container {
      overflow-x: hidden;
    }
    input:focus {
      @include box-shadow(none);
    }
    .e-pv-canvas-signature {
      width: 714px;
    }
    .e-pv-font-sign {
      margin: 12px;
    }
  }
  .webviewer {
    overflow: auto !important;
    height: 1150px;
  }
}

#document-container_editor > *,
#viewer-container_viewerContainer > * {
  ::-webkit-scrollbar {
    height: 12px !important;
    @include border-radius(0 !important);
  }
  ::-webkit-scrollbar-thumb {
    @include border-radius(0 !important);
  }
}

// licence popup hide
#js-licensing {
  display: none;
}
