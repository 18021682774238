.circle-colonial-white {
  i::before {
    color: #efba17;
  }
}
.InnerSpan {
  margin-left: 5px;
}

.border-set {
  border: 2px solid #efba17;
  border-radius: 10px;
}
.approvers-card {
  .approvers-card-main {
    @include box-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
    border-radius: 5px;
    .apcard {
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      background-color: #fff;
      p {
        color: #6d6e71;
        font-size: 14px;
        margin-bottom: 0px;
        word-break: break-word;
      }
    }
    .apcard:not(:last-child) {
      border-bottom: 1px solid rgba(197, 197, 197, 0.5);
    }
  }
  .leftPart {
    display: flex;
    align-items: start;
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 15px;
    }
    h4 {
      margin-bottom: 0px;
    }
  }
  .rightPart {
    white-space: nowrap;
    font-weight: bold;
    display: block;
    margin: 0 auto;
    text-align: center;
  }
}
.Procurement-Step {
  text-align: center;
  padding: 0px 20px;
  ul {
    border-top: 5px solid dodgerblue;
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
  }
  .link {
    position: relative;
    margin-top: 10px;
    width: 100%;
  }

  .link p {
    font-weight: bold;
    text-decoration: none;
    color: dodgerblue;
    font-size: 13px;
    padding-left: 10px;
  }

  .link::after {
    content: "";
    width: 15px;
    height: 15px;
    background: #fff;
    position: absolute;
    border-radius: 10px;
    top: -21px;
    left: 47%;
    transform: translatex(-50%);
    border: 2px solid dodgerblue;
  }

  .active::after,
  .link:hover::after {
    background: dodgerblue;
  }
  .link:first-child {
    margin-left: -85px;
  }
  .link:last-child {
    margin-right: -106px;
  }

  span.dot-circle {
    position: absolute;
    top: -40px;
    left: 170px;
    background: dodgerblue;
    width: 50px;
    height: 50px;
    color: #fff;
    text-align: center;
    border-radius: 50px;
    line-height: 50px;
    font-size: 21px;
  }
}
.custom-card {
  .head-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: auto;
    h5 {
      margin-bottom: 0;
    }
    h6 {
      font-size: 10px;
      font-weight: 500;
    }
    i {
      cursor: pointer;
      &::before {
        color: $gray-700;
      }
    }
    .more-option::after {
      top: -16px;
    }
  }
}

.year-select {
  background-color: $gray-300;
  display: flex;
  max-width: max-content;
  @include border-radius(8px);
  &:hover {
    background-color: $gallery;
    .form-group-select {
      .SelectWidget {
        .select__control {
          background-color: $gallery !important;
        }
      }
    }
  }
  .form-group-select {
    margin-bottom: 0;
    .SelectWidget {
      .select__control {
        background-color: $gray-300 !important;
        min-width: 115px !important;
        border: none !important;
        min-height: 38px;
        @include transition(none);
        @include box-shadow(none !important);
      }
    }
  }
}

.chart-filter {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  .dropdown {
    .btn-group {
      // width: 200px;
      background-color: #fee1da;
      width: 100%;
      // border-right: 1px solid #ccc;
      // #caret{
      //     background: #FEE1DA;

      // }
      .dropdown-toggle {
        background: #fee1da;
        border-color: #fee1da;
      }
    }
  }
  .filterapply {
    background-color: orange;
    // width: 190px;
    height: 38px;
    border-radius: 0px 10px 10px 0px;
    //    .btn{
    //        width: 190px;
    //    }
  }
  .select__control {
    cursor: pointer;
    min-width: 150px;
    padding: 3px 10px;
    // margin-bottom: 10px;
    min-height: 40px;
    background-color: #eef3f8 !important;
    border: 1px solid #eef3f8 !important;
    width: 100%;
    @include border-radius(0px !important);
  }
}
// .btn-group, .btn-group-vertical{
//     // display: inline-flex !important;
// }
.locationList {
  .btn-group {
    border-radius: 4px !important;
  }
}

// .btn-group:nth-child(1) {
//     border-radius: 10px 0px 0px 10px;

// }
.chart-bar-size {
  width: 600px;
  overflow: auto;
}
.chart-scroll-bar {
  &::-webkit-scrollbar {
    display: block;
    width: 2px;
    height: 5px;
  }
}

.card-budget {
  background: $white;
  overflow: auto;
  // @include border-radius(8px);
  // @include box-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
  padding: 12px;
  text-align: center;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  h5 {
    color: #52a2ec;
  }
  h4.budget-amt-avail {
    color: red;
  }
}

.bg-reserved {
  background-color: #607d8b;
}
.bg-available {
  background-color: #ffc107;
}
.bg-consumed {
  background-color: #ff0000;
}
.consumed::before {
  background-color: #007bff !important;
}
.po-amount-consumed::before {
  background-color: #ff0000 !important;
}
.non-po-amount-consumed::before {
  background-color: #607d8b !important;
}
