/*------------------*
# Variables
*------------------*/

/*
 * This file includes default variables to get going quickly on a project.
*/

/*
 * Importing Fonts - Mulish, Ubuntu
*/

@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;700&family=Ubuntu:wght@400;500;700&family=PT+Sans:wght@400;700&display=swap");
// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;1,300&display=swap');
// @import url('http://fonts.cdnfonts.com/css/sofia-pro');
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;1,400&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400&display=swap");
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
$ubuntu-font: "Open Sans", sans-serif;
$mulish-font: "Open Sans", sans-serif;
$pt-sans-font: "PT Sans", sans-serif;
$roboto-sans-font: "Roboto", sans-serif;
$Nato-sans-font: "Nato Sans", sans-serif;

/*
 * Base font sizes
*/

$base-font-size: 1rem !default;

$h1-size: 2.25rem !default; // 36px size
$h2-size: 1.5rem !default; // 24px size
$h3-size: 1.125rem !default; // 18px size
$h4-size: $base-font-size !default; // 16px size
$h5-size: 0.875rem !default; // 14px size
$h6-size: 0.75rem !default; // 12px size

$size40: 2.5rem !default; // 40px size
$size32: 2rem !default; // 32px size
$size24: 1.5rem !default; // 24px size
$size22: 1.375rem !default; // 22px size
$size20: 1.25rem !default; // 20px size
$size18: 1.111rem !default; // 18px size
$size14: 0.875rem !default; // 14px size
$size12: 0.75rem !default; // 12px size
$size10: 0.625rem !default; // 10px size

$font-weight-regular: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-heavybold: 900 !default;

/*
 * Base colors
*/

$primary: #2a3c7a !default;
$landing-yellow: #677bbd;
$primary-light: #d4ecff !default;
$primary-lighter: #ebf3ff !default;
$white: #ffffff !default;
$black: #0a142d !default;
$lightblack: #133563 !default;
$maliblu: #4ecffc !default;
$pink-salmon: #ff94a4 !default;
$quick-sand: #bd988b !default;
$maliblu-dark: #8c9dfb !default;
$blue: #206ecc !default;
$malachite: #27e579 !default;
$amber: #fdbf00 !default;
$zumthor: #e9f2ff !default;
$yellow-orange: #ffb531 !default;
$java: #14daa430 !default;
$tea-green: #cdf3c2 !default;
$titan-white: #e0e6ff !default;
$fair-pink: #ffeeea !default;
$dodger-blue: #54a0ff !default;
$water-blue: #cbe4f1 !default;
$colonial-white: #ffeebe !default;
$moon-raker: #d7c2f3 !default;

$gray-trout: #505565 !default;
$gray-emperor: #4f4f4f !default;
$gray-1000: #737373 !default;
$gray-900: #d0d0d0 !default;
$gray-700: #e0e3e7 !default;
$gray-500: #e9eef2 !default;
$gray-400: #f6f7f9 !default;
$gray-300: #f4f9ff !default;
$gray: #6d6e71 !default;
$gray-silver: #c4c4c4 !default;
$orange: #fbad83 !default;
$pink: #ff728f !default;
$burnt-sienna: #eb5757 !default;
$gray-dusty: #989898 !default;
$gray-nevada: #6d6e71 !default;
$fair-pink: #ffeeee !default;
$foam: #e8f6fd !default;

$yellow: #f8df60 !default;
$yellow-300: #fae100 !default;

$green: #0baa97 !default;
$green-500: #51ffa7 !default;
$green-300: #74d276 !default;

$red: #ef4141 !default;
$darkblue: #00469a !default;
$background-bg: #fbfbfb !default;
$purple: #5d4ce5 !default;
$gallery: #eeeeee !default;
$silver: #bdbdbd !default;
