.chat-window-icon {
  background-color: $dodger-blue;
  position: absolute;
  top: 40%;
  right: 0px;
  z-index: 5;
  padding: 6px 8px;
  cursor: pointer;
  display: none;
  @include border-radius(50%);
}

.chat-window {
  position: absolute;
  bottom: 36px;
  right: 18px;
  z-index: 200;
  height: 338px;
  width: 320px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: $white;
  @include border-radius(8px 8px 0px 0px);
  @include box-shadow(0px 4px 20px #eaeaea);

  &.h-10 {
    .chat-window-head {
      padding: 2px 8px;
    }
  }

  &-head {
    background-color: #368bfc;
    padding: 4px 8px;
    @include user-select(none);
    img {
      max-width: 34px;
    }
    .title-text {
      font-size: $size14;
      color: $white;
      padding-left: 8px;
    }
    .icon-box {
      height: 22px;
      width: 22px;
      cursor: pointer;
      &:hover {
        background-color: $water-blue;
        @include border-radius(4px);
      }
    }
    i::before {
      color: $white;
      cursor: pointer;
    }
  }

  &-body {
    padding: 10px;
    overflow: auto;
    min-height: 234px;
    .details-text {
      font-size: $size12;
      font-weight: 400;
      line-height: 1.2;
      margin-bottom: 0;
      word-break: break-all;
    }

    .container-box {
      padding: 12px;
      font-weight: $font-weight-semibold;
      display: inline-block;

      &-dark {
        background-color: #202a5b;
        @include border-radius(0 16px 16px 16px);
        .details-text {
          color: $white;
        }
      }

      &-light {
        background-color: #206ecc;
        @include border-radius(16px 0px 16px 16px);

        .details-text {
          color: #fff;
        }
      }
    }
    .time {
      margin-top: 4px;
      font-size: $size10;
      color: $gray;
      @include user-select(none);
    }

    img.user-img {
      height: 35px;
      width: 35px;
      @include border-radius(50%);
    }

    .chat-item {
      display: flex;
      align-items: center;
      padding-bottom: 8px;
      margin-bottom: 8px;
      border-bottom: 1px solid #c7c7c7;
      cursor: pointer;
      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
      }
      .chat-content-details {
        .chat-name {
          font-size: $size14;
          color: $lightblack;
        }
        .chat-time {
          font-size: $size12;
          color: $gray;
          @include user-select(none);
        }
      }
      .unread-message-count {
        font-size: $size12;
        color: $white;
        background-color: $primary;
        min-width: 12px;
        height: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 6px;
        @include border-radius(4px);
        @include user-select(none);
      }
    }
  }

  &-foot {
    .input-group {
      textarea {
        height: 55px;
        border: 0;
        border-top: 1px solid #c7c7c7;
        min-height: 42px;
        font-size: $size14;
        @include border-radius(0);
        &:focus {
          @include box-shadow(none);
        }
      }
      .btn {
        border: 0;
        padding: 8px 12px;
        border-top: 1px solid #c7c7c7;
        background-color: inherit;
        @include box-shadow(none !important);
        @include border-radius(0);
        &.active {
          cursor: pointer !important;
          i::before {
            color: $lightblack;
          }
        }
      }
    }
  }

  .icon-chat-send {
    &.active {
      &::before {
        content: "\e95a";
        color: $lightblack;
      }
    }
  }
}
