// Headers

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $ubuntu-font;
    font-weight: 600;
}

h1 {
    font-size: $h1-size;
}

h2 {
    font-size: $h2-size;
}

h3 {
    font-size: $h3-size;
}

h4 {
    font-size: $h4-size;
}

h5 {
    font-size: $h5-size;
}

h6 {
    font-size: $h6-size;
}