.react-bootstrap-table {
  table {
    table-layout: auto !important;
    font-family: "Roboto", sans-serif !important;
    //color: #7c869c;
    color: $lightblack;
    thead {
      background-color: #fee1da;
      th {
        font-weight: 700;
        border-top: none;
        border-bottom: 1px solid #fff;
        position: relative;
        font-size: $size12;
        outline: none !important;
        color: $lightblack;
        &::after {
          content: "";
          position: absolute;
          width: 2px;
          height: 16px;
          top: auto;
          bottom: auto;
          right: 10px;
        }
        &:first-child {
          border-radius: 10px 0 0 0;
        }
        &:last-child {
          border-radius: 0 10px 0 0;
          &::after {
            display: none;
          }
        }
        .react-bootstrap-table-sort-order {
          margin: 10px 6.5px;
          .caret {
            display: inline-block;
            width: 0;
            height: 0;
            margin-left: 2px;
            vertical-align: middle;
            border-top: 4px dashed;
            border-right: 4px solid transparent;
            border-left: 4px solid transparent;
          }
          &.dropup .caret {
            border-top: 0;
            border-bottom: 4px dashed;
          }
        }
      }
    }
    tbody {
      border-radius: 10px !important;
      // border: 1px solid #ccc;
      // box-shadow: -1px 1px 0px 4px #fff;
      // background: #fff;
      .disable-row {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
        input {
          pointer-events: none;
          cursor: not-allowed;
        }
      }
    }
    th,
    td {
      font-size: 0.715rem;
      vertical-align: middle;
      // white-space: pre;
      .form-group-input {
        max-width: 130px;
        margin-bottom: 0;
        input {
          padding: 10px;
          min-height: 30px !important;
        }
      }
    }
    td {
      background: $white;
      white-space: nowrap;
      text-align: left;
      vertical-align: middle;
      font-size: $size14 !important;
      //padding:2px 2px;
      color: #7c869c;
      //color: #343a40;
      font-family: "Roboto", sans-serif !important;
      line-height: 1.2;
      border: none;
    }
    .action-items {
      display: flex;
      justify-content: center;
      .btn {
        padding: 5px !important;
        @include border-radius(4px);
        display: flex;
        align-items: center;
        margin-right: 12px;
        &.view {
          background-color: $blue;
        }
        &.edit {
          background-color: $amber;
        }
        &.delete {
          background-color: $red;
        }
        &:last-child {
          margin-right: 0;
        }
        i::before {
          color: $white;
        }
      }
    }
    .action-status {
      display: flex;
      justify-content: flex-start;
    }
  }
  .selected-row {
    background-color: #ddf7de !important;
  }
  .selection-cell-header {
    width: 30px;
    &::after {
      display: none;
    }
  }
  .table-hover {
    tbody tr:hover {
      background-color: rgba(33, 150, 243, 0.1) !important;
    }
  }
  .expand-cell-header {
    opacity: 0;
  }
  .show-more {
    cursor: pointer;
    width: 26px;
    height: 26px;
    margin: 0 auto;
    background: #f6f7f9;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include border-radius(50%);
    @include user-select(none);
    @include box-shadow(0px 2px 4px rgba(229, 229, 229, 0.8));
    &:before {
      content: "";
      height: 10px;
      width: 10px;
      display: inline-block;
      border-top: 2px solid $dodger-blue;
      border-left: 2px solid $dodger-blue;
      transform: rotate(130deg);
      position: relative;
      left: -3px;
      top: 0;
      transition-duration: 400ms;
    }
    &.expand {
      &:before {
        top: -2px;
        left: 0;
        transform: rotate(225deg);
        transition-duration: 400ms;
      }
    }
  }
  .reset-expansion-style {
    padding: 0;
    .expanded-content {
      padding: 20px 12px;
      font-size: $size14;
      background-color: $white;
      span {
        font-weight: $font-weight-semibold;
      }
      .block-item {
        margin-bottom: 10px;
        &:nth-child(2) {
          margin-top: 1rem;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .dropdown {
    .more-option::after {
      top: -14px;
      right: -10px;
      &:hover {
        background-color: $gallery;
        @include border-radius(50%);
      }
    }
  }
  .react-bs-table-no-data {
    text-align: center;
  }
}

button#pageDropDown {
  background: #fff;
  color: $lightblack;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 2px;
  line-height: 1.25;
  color: $lightblack;
  background-color: #fff;
  border: 1px solid $lightblack;
}
.page-item.active .page-link {
  z-index: 3;
  color: $lightblack;
  background-color: #edf3ff;
  border-color: $lightblack;
}
.page-link:focus {
  box-shadow: 0 0 0 0.2rem #fff;
}

.pagination {
  justify-content: flex-end;
}

.sup-approval-tabs-content .filter {
  background: #fff !important;
  padding: 5px !important;
  font-size: 10px !important;
  border: 1px solid #133563 !important;
  border-radius: 5px !important;
}

label.filter-label {
  padding-top: 5px;
  width: 120px;
}

.filter.text-filter {
  font-size: 12px;
}
.column-width {
  width: 200px !important;
  white-space: normal;
}
.column-width-role {
  // width: 210px !important;
  white-space: normal;
}
.react-bootstrap-table {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 8px #00000140;
}
label.form-group-checkbox.mb-0 {
  white-space: nowrap;
}
span.table-columns strong {
  color: $lightblack;
}
span.Inactive-delegate {
  background: #ccc;
  padding: 2px 5px;
  border-radius: 5px;
  color: #2283bf;
}
span.active-delegate {
  background: #2dc52d;
  padding: 2px 13px;
  border-radius: 5px;
  color: #fff;
}
.data-table-search {
  position: absolute;
  top: 11.7%;
  label.search-label {
    width: 250px;
    input {
      border: 1px solid $lightblack;
    }
  }
}
.datatable-custom-search {
  display: flex;
}
label.search-label {
  margin-top: -57px;
  position: relative;
  width: 250px;
  border: 1px solid #aaaaaa;
  height: 37px;
  border-radius: 5px;
  input#search-bar-0 {
    font-size: 0.875rem;
    font-weight: 600;
    font-family: "Mulish", sans-serif;
    color: $lightblack;
    border: none;
  }
}
.datatable-custom-search .icon-search {
  margin-top: -45px;
  margin-left: -25px;
  z-index: 9;
}

.payment-details {
  margin-top: 15px;
}
