.custom-card {
  background-color: #fff;
  border: 1px solid #ffc107;
  position: relative;
  word-break: break-word;
  //@include box-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
  @include border-radius(8px);

  // Variations Start
  &-small {
    padding: 8px;
  }

  &-medium {
    padding: 20px;
  }

  &-large {
    padding: 20px;
    min-height: 212px;
  }

  &-extra-large {
    padding: 24px;
  }

  // Variations End

  .badge {
    padding: 0.5em 0.6em;
  }

  &-head {
    display: flex;
    justify-content: space-between;
    padding-bottom: 17px;
    border-bottom: 1px solid $gray-500;

    &-link {
      font-size: $size14;
    }

    h3 {
      font-weight: $font-weight-bold;
      font-size: $size20;
    }
  }

  &.preview-block {
    padding-top: 100px;

    .preview {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      width: 100%;
      height: 92px;
      overflow: hidden;
      background-color: #fff9dc;
      @include border-radius(5px 5px 0 0);
      @include transition(300ms ease-in-out);

      &:hover {
        .remove-block {
          background-color: #0b0e0e78;
          position: absolute;
          top: 0;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            cursor: pointer;
          }
        }
      }

      .content-uploading {
        padding: 8px;
        width: 100%;
        height: 92px;
        display: grid;
        align-items: center;

        .label-text {
          margin-bottom: 6px;
          font-size: $size12;
        }

        .link {
          font-size: $size12;
          font-weight: $font-weight-bold;
          cursor: pointer;
        }
      }

      img {
        z-index: 1;
        display: block;
        max-width: 100%;
        margin: 0 auto;
        // height: 92px;
        padding: 5px;
      }

      .remove-block {
        display: none;
      }

      .unapproved {
        font-size: $size10;
        color: white;
        padding: 0 8px;
        background-color: #eb5757;
        position: absolute;
        z-index: 2;
        @include border-radius(0 0 0 8px);
      }
    }

    .file-name {
      font-size: $size12;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  // .btn-outline-secondary{
  //     background-color: $white;
  // }
}

.noDataCard {
  height: 250px;
  width: 400px;
  padding: 50px 100px;
  border-left: 1px solid rgb(116, 111, 111);
  border-bottom: 1px solid grey;
}

.stripe-card {
  overflow: hidden;
  position: relative;
  padding: 20px;
  background-color: #fafbff;
  @include border-radius(8px);

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    width: 100%;
    background-color: #f8df60;
  }

  // Remove this class once get the content
  &-demo-height {
    min-height: 135px;
  }

  &-head {
    padding-bottom: 10px;
    border-bottom: 1px solid #dfddf3;

    &-link {
      font-size: $size12;
      color: #54a0ff;
      font-weight: $font-weight-semibold;
    }
  }

  .circle {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .stripe-card-head {
    h5 {
      font-weight: 700;
    }
  }
}

.preview-overview {
  // word-break: break-all;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 1px 4px 0px #0887ff40;
  @include border-radius(8px);

  // @include box-shadow(0px 1px 2px 1px #ffc107);
  &-head {
    padding-bottom: 10px;
    border-bottom: 1px solid #dfddf3;

    h3,
    h2 {
      font-weight: 600;
    }

    .sub-heading {
      span {
        font-size: $size14;
        color: "#133563";
        line-height: 1.2;
      }

      h3 a {
        padding-left: 8px;
      }

      i {
        cursor: pointer;

        &::before {
          color: $lightblack;
        }
      }
    }

    h4 {
      font-weight: $font-weight-bold;
      font-size: $size18;

      em {
        font-size: $size24;
        font-style: normal;
      }
    }

    .imp-info {
      color: $lightblack;
      font-weight: $font-weight-bold;

      &-label {
        font-size: $size14;
      }
    }

    .status {
      padding: 5px 12px;
      font-size: $size10;
      font-weight: $font-weight-bold;
      color: $white;
      @include border-radius(12px);

      &-paid {
        background-color: #57d95c;
      }

      &-pending {
        background-color: #f8df60;
      }

      &-malibu {
        background-color: $maliblu;
      }

      &-pink-salmon {
        background-color: $pink-salmon;
      }

      &-yellow-orange {
        background-color: $yellow-orange;
      }

      &-malachite {
        background-color: $malachite;
      }

      &-light-green {
        background-color: #96b859;
      }

      &-voilet {
        background-color: #984ba4;
      }

      &-light-blue {
        background-color: #48b2bc;
      }

      &-light-brown {
        background-color: #b28a42;
      }

      &-amber {
        background-color: #fb4;
      }

      &-dodger-blue {
        background-color: #5093e1;
      }

      &-danger {
        background-color: $red;
      }

      &-tea-green {
        background-color: #2eb66e;
      }

      &-false {
        background-color: #ef4141;
      }

      &-met-blue {
        background-color: #7624a4;
      }
    }
  }

  &-details {
    padding: 20px 0;
    border-bottom: 1px solid #dfddf3;

    span {
      font-size: $size14;
      color: $gray-nevada;
    }

    i {
      cursor: pointer;

      &::before {
        color: $lightblack;
      }
    }
  }

  &-body {
    font-size: $size14;
    color: "#5c636a";
    font-weight: 500;

    .label {
      color: $lightblack;
      font-weight: $font-weight-bold;
      font-size: 13px;
    }

    span {
      font-size: $size14;
      color: $gray-nevada;
    }
  }

  &-showmore {
    font-size: $size14;
    color: $gray;
  }

  .link {
    font-size: $size12;
    color: $dodger-blue;
    font-weight: $font-weight-bold;
  }
}

.sup-approval-overview {
  &-head {
    .status {
      padding: 5px 12px;
      font-size: $size10;
      font-weight: $font-weight-bold;
      color: $white;
      @include border-radius(12px);

      &-paid {
        background-color: #57d95c;
      }

      &-pending {
        background-color: #f8df60;
      }

      &-malibu {
        background-color: $maliblu;
      }

      &-pink-salmon {
        background-color: $pink-salmon;
      }

      &-yellow-orange {
        background-color: $yellow-orange;
      }

      &-malachite {
        background-color: $malachite;
      }

      &-amber {
        background-color: $amber;
      }

      &-dodger-blue {
        background-color: $dodger-blue;
      }

      &-danger {
        background-color: $red;
      }

      &-tea-green {
        background-color: #33691e;
      }
    }
  }
}

.bg-pearl {
  background-color: #fcf4dd;
}

.bg-white {
  background-color: #fff;
}

.bg-peppermint {
  background-color: #ebf8e7;
}

.bg-foam {
  background-color: #e8f6fd;
}

.bg-snow {
  background-color: #deffd4;
}

.bg-pink {
  background-color: #ffeeea;
}

.bg-selago {
  background-color: #f1e8fd;
}

.bg-zircon {
  background-color: #fafbff;

  .btn.btn-outline-secondary {
    background-color: $white;

    &:hover {
      color: #6c757d;
    }
  }
}

.bg-red-orange {
  background-color: #ff2b2b;
}

.bg-brunt {
  background-color: #eb5757;
}

.bg-background {
  background-color: #ebecf0;
}

.overlay-card {
  background-color: "#f3f3f3f" !important;
}

.ImageSizeError {
  color: red;
}

img.download {
  width: 100px;
  cursor: pointer;
}

.notes-card {
  box-shadow: 0px 3px 7px #0000004d !important;
  padding: 10px;
  border-radius: 10px;
}

.pr-preview,
.approval-response-block {
  .icon-info:before {
    color: #ffc107 !important;
  }
}

.report-card {
  padding: 20px 10px;
  height: 140px;
  // width: 180px;
  @include box-shadow(0px 0px 5px rgba(0, 0, 0, 0.2) !important);
  @include border-radius(10px !important);

  // border: 1px solid #9ba8b1;
  h4 {
    color: #133563 !important;
    // padding-right: 73px;
    word-break: break-word;
    font-size: 1.1rem;
    font-weight: 400;
  }

  p {
    font-weight: 600;
    margin-top: 5px;
    position: absolute;
    top: 90px;
  }

  .circle-lg {
    height: 125px !important;
  }

  .create-new {
    font-weight: bold;
    font-size: 17px;
  }
}

.ingegration-card {
  @include box-shadow(0px 0px 5px rgba(0, 0, 0, 0.2) !important);
  border: none !important;
}

.bid-time-left {
  font-size: 14px;
  font-weight: bold;
  color: red;
}

.auction-card-view {
  min-height: 114px;
  @include box-shadow(0px 0px 5px rgba(0, 0, 0, 0.2) !important);
  border: none !important;

  &.cp {
    cursor: pointer;
  }

  &.lg {
    .auction-price-icon {
      border: none;
      position: initial;

      img {
        position: relative;
        top: -24px;
        left: -24px;
        padding: 16px;
        border: 1px solid #ccc;
      }
    }

    .forward-auction {
      position: absolute;
      top: 52px;
      left: -5px;
      width: 80px;
      text-align: center;
      margin-left: 0;
    }
  }

  .auction-price-icon {
    border: 1px solid #ccc;
    padding: 4px;
    position: relative;
    top: -30px;
    left: -16px;
  }

  .forward-auction {
    background: #34ba5d;
    padding: 5px;
    border-radius: 4px;
    height: 21px;
    margin-left: 10px;
    position: absolute;
    right: 0;
    top: 0;

    p {
      color: #fff;
      font-size: 8px;
      font-weight: bold;
    }
  }

  h4 {
    word-break: break-word;
  }

  p {
    span {
      padding-top: 1px;
      padding-left: 7px;
    }
  }

  .status {
    padding: 5px;
    font-size: 9px;
    line-height: 15px;
    font-weight: 700;
    color: #ffffff;
    border-radius: 4px;
    margin-left: 5px;

    &-paid {
      background-color: #57d95c;
    }

    &-pending {
      background-color: #f8df60;
    }

    &-malibu {
      background-color: $maliblu;
    }

    &-pink-salmon {
      background-color: $pink-salmon;
    }

    &-yellow-orange {
      background-color: $yellow-orange;
    }

    &-malachite {
      background-color: $malachite;
    }

    &-light-green {
      background-color: #96b859;
    }

    &-voilet {
      background-color: #984ba4;
    }

    &-light-blue {
      background-color: #48b2bc;
    }

    &-light-brown {
      background-color: #b28a42;
    }

    &-amber {
      background-color: #fb4;
    }

    &-dodger-blue {
      background-color: #5093e1;
    }

    &-danger {
      background-color: $red;
    }

    &-tea-green {
      background-color: #2eb66e;
    }

    &-false {
      background-color: #ef4141;
    }
  }
}

.cert-preview {
  border: 1px dashed #ccc;
  border-radius: 8px;
  padding: 10px;
  margin-top: -70px;
}

.basket-auction {
  padding: 4px 6px;
  border-radius: 4px;
  height: 26px;
  width: auto;
  position: static;
  font-size: small;
  color: $white;

  &-active {
    background-color: #34ba5d;
  }

  &-open {
    background-color: #f8df60;
  }

  &-closed {
    background-color: #ef4141;
  }
}

.right-btn-menu {
  background: #fff;
  padding: 10px;
  box-shadow: 0px 1px 4px 0px #0887ff40;
  border-radius: 8px;
}
