// BOX SHADOW
@mixin box-shadow($shadow) {
    box-shadow: $shadow;
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    -o-box-shadow: $shadow;
    -ms-box-shadow: $shadow;
}

// BORDER RADIUS
@mixin border-radius($radius) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -o-border-radius: $radius;
    -ms-border-radius: $radius;
}

// BACKGROUND SIZE
@mixin background-size($size) {
    background-size: $size;
    -webkit-background-size: $size;
    -moz-background-size: $size;
    -o-background-size: $size;
    -ms-background-size: $size;
}

// TRANSITION
@mixin transition($effects) {
    transition: $effects;
    -webkit-transition: $effects;
    -moz-transition: $effects;
    -o-transition: $effects;
    -ms-transition: $effects;
}

// TRANSFORM
@mixin transform($props) {
    transform: $props;
    -webkit-transform: $props;
    -moz-transform: $props;
    -o-transform: $props;
    -ms-transform: $props;
}

// ANIMATION
@mixin animation($animation) {
    animation: $animation;
    -webkit-animation: $animation;
    -moz-animation: $animation;
    -o-animation: $animation;
    -ms-animation: $animation;
}

// ANIMATION-DELAY
@mixin animation-delay($delay) {
    animation-delay: $delay;
    -webkit-animation-delay: $delay;
    -moz-animation-delay: $delay;
    -o-animation-delay: $delay;
    -ms-animation-delay: $delay;
}

// USER-SELECT
@mixin user-select($selecttype) {
    user-select: $selecttype;
    -webkit-user-select: $selecttype;
    -moz-user-select: $selecttype;
    -o-user-select: $selecttype;
    -ms-user-select: $selecttype;
}

// FILTER
@mixin filter($filter) {
    filter: $filter;
    -webkit-filter: $filter;
    -moz-filter: $filter;
    -o-filter: $filter;
    -ms-filter: $filter;
}

// FILL-IMAGE
@mixin object-fit($objectfit) {
    object-fit: $objectfit;
    -moz-object-fit: $objectfit;
    -o-object-fit: $objectfit;
    -ms-object-fit: $objectfit;
}
