.MuiPaper-root {
  &.MuiPaper-elevation1 {
    @include box-shadow(0px 2px 8px #00000140);
  }

  &.MuiPaper-rounded,
  &.MuiPaper-rounded > div {
    @include border-radius(6px !important);
  }

  > div.MuiToolbar-regular {
    padding: 0;
    position: absolute;
    top: -70px;
    width: 100%;
  }

  .MuiToolbar-root {
    color: $lightblack;
  }

  .MuiTypography-h6 {
    font-size: $h4-size !important;
    font-family: $ubuntu-font !important;
    font-weight: $font-weight-medium !important;
  }

  .Component-horizontalScrollContainer-12 {
    @include border-radius(6px);
  }

  div > * ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.15) !important;
  }
}

.MuiInputBase-input {
  padding: 5px 0px 5px !important;
}

.MuiTable-root {
  .MuiTableRow-head {
    th {
      background-color: #368bfc;
      font-weight: $font-weight-bold;
      color: #fff;
      font-family: "Open Sans", sans-serif !important;
      font-size: $size14;

      .MuiCheckbox-colorSecondary {
        padding: 0;
      }
    }
  }

  tr td {
    &:first-child {
      border-left: 0px !important;
    }

    div.MuiTextField-root {
      width: 100%;
    }
  }

  // thead {
  //   border: 2px solid #ccc;
  // }

  tbody {
    // border: 2px solid #ccc;

    tr {
      &.MuiTableRow-root {
        height: auto !important;
        border-bottom: none !important;
        border-block: 1px solid #eeeded;
      }

      .form-group-input {
        // max-width: 130px;
        margin-bottom: 0;

        input {
          padding: 10px;
          min-height: 30px !important;

          &:hover,
          &:active,
          &:focus {
            outline: none !important;
          }
        }
      }

      button {
        font-size: $size12;

        &.MuiIconButton-root {
          padding: 0;
          width: 54px;

          &:hover,
          &:active,
          &:focus {
            // background-color: transparent;
            background-color: transparent !important;
          }

          .MuiTouchRipple-root {
            display: none;
          }
        }
      }

      .MuiCheckbox-colorSecondary {
        padding: 0;
      }
    }
  }

  tfoot {
    tr {
      .MuiSelect-icon {
        position: relative !important;
      }
    }
  }

  .MuiTableRow-root {
    .action-items {
      display: flex;
      justify-content: center !important;

      .btn {
        padding: 5px !important;
        @include border-radius(4px);
        display: flex;
        align-items: center;
        margin-right: 12px;

        &.view {
          background-color: $blue;
        }

        &.edit {
          background-color: $amber;
        }

        &.delete {
          background-color: $red;
        }

        &:last-child {
          margin-right: 0;
        }

        i::before {
          color: $white;
        }

        &.primary-light {
          font-size: $size10 !important;
          background-color: #e8e6fc;
          padding: 6px 12px !important;
          color: #7367f0 !important;
          @include box-shadow(none !important);
        }
      }
    }

    .action-items-products {
      display: flex;
      text-align: center;

      .btn {
        padding: 5px !important;
        @include border-radius(4px);
        margin-right: 12px;
        box-shadow: none !important;

        &:last-child {
          margin-right: 0;
        }

        i::before {
          color: #206ecc !important;
        }

        span {
          color: $lightblack;
        }

        &.primary-light {
          font-size: $size10 !important;
          background-color: rgba(243, 119, 88, 0.3);
          padding: 6px 12px !important;
          color: $primary !important;
          @include box-shadow(none !important);
        }
      }
    }
  }

  td.MuiTableCell-root {
    border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
  }

  td.MuiTableCell-root,
  td.MuiTableCell-root div {
    // background: #fff;
    white-space: nowrap;
    text-align: left;
    vertical-align: middle;
    font-size: 14px !important;
    //padding:2px 2px;
    color: #133563 !important;
    //color: #343a40;
    font-family: "Open Sans", sans-serif !important;
    line-height: 2;
    // border: none;
    font-weight: 500;

    .err-msg-table {
      color: #ff0000 !important;
    }

    strong {
      //color: #343a40;
      color: $lightblack;
      font-size: 0.97em !important;
      font-weight: 600;
    }
  }

  .MuiTableCell {
    &-root {
      font-family: "Open Sans" sans-serif !important;
      padding: 3px 7px !important;
    }

    &-footer {
      padding: 0 !important;
      border: 0;
    }

    &-paddingNone {
      width: 30px;
    }
  }
}

.in-table-icon i {
  padding: 5px;
  vertical-align: middle;
  color: #183563 !important;

  &::before {
    color: #183563 !important;
  }
}

.in-table-icon img {
  height: 35px;
  width: 35px;
  border: 3px solid #f4f9ff;
  border-radius: 50%;
  margin-right: 5px;
  object-fit: cover;
}

.catalogue-img img {
  border: 3px solid #2196f3;
  padding: 5px;
  border-radius: 4px;
}

.dept-position {
  padding-left: 38px;
}

.material-ui-table {
  position: relative;

  > .btn {
    position: absolute;
    top: 4px;
    right: 0;
    z-index: 2;
  }
}

.catalogue-span span {
  color: #206ecc !important;
  cursor: pointer;
}

.link-url-view {
  color: #206ecc !important;
  cursor: pointer;
}

.blink-text {
  font-weight: bold;
  color: #ff0000;
  animation: blink 1s linear infinite;
  margin: 0px;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.material-ui-table.sealed-Data {
  background: #fff;
  pointer-events: none;
  opacity: 0.5;
}

.evaluate-table {
  background: $white;
  height: 360px;
  overflow: auto;
  @include border-radius(8px);
  @include box-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
}

.MuiPaper-elevation2 {
  box-shadow: none !important;
}

tbody {
  // tr:nth-child(odd) {
  //   background-color: #f2f2f2;
  // }

  tr:nth-child(even) {
    background-color: #fff;
  }
}

.template-table {
  .main-head-column {
    background: #f3f3f3;
    width: 100%;
    text-align: right;
    padding: 5px;
    border: 1px solid #aaaaaa;
  }

  .table {
    width: 100%;
    border: 1px solid #aaaaaa;
    font-size: 14px;

    thead {
      th {
        border-left: 1px solid #aaaaaa;
        border-bottom: 1px solid #aaaaaa;
        text-align: center;
        vertical-align: middle;
      }
    }

    tbody {
      td {
        border-left: 1px solid #aaaaaa;
        border-top: 0px;
        text-align: center;
        vertical-align: middle;
      }

      tr:nth-child(odd) {
        background-color: #fff;
      }
    }

    tr td:first-child {
      color: #5d4ce5;
    }

    tr {
      @include border-radius(8px !important);
    }
  }
}

.create-event-table {
  .table {
    width: 100%;
    border: 1px solid #aaaaaa;
    font-size: 14px;

    thead {
      th {
        background: #f3f3f3;
        border-left: 1px solid #aaaaaa;
        border-bottom: 1px solid #aaaaaa;
        text-align: center;
      }
    }

    tbody {
      td {
        border-left: 1px solid #aaaaaa;
        border-top: 0px;
      }

      tr:nth-child(odd) {
        background-color: #fff;
      }

      tr:not(:last-child) {
        border-bottom: 1px solid #aaaaaa;
      }
    }

    tr td:first-child {
      color: #5d4ce5;
    }

    tr {
      @include border-radius(8px !important);
    }

    &.no-cell-padding tbody td {
      padding: 0 !important;
    }

    .form-group-input input[type="number"] {
      min-width: 120px;
    }

    .form-group-input input:disabled {
      background-color: #adb5bd !important;
    }
  }
}

.evaluate-table {
  .MuiTable-root .MuiTableRow-head th .MuiCheckbox-colorSecondary {
    display: none;
  }
}

// .terms-table-new {
//   .MuiTable-root tr td:first-child::before {
//     content: "::";
//     font-size: 18px;
//     font-weight: bold;
//     cursor: grab;
//   }
// }
.MuiTable-root tbody tr button.MuiIconButton-root {
  width: 15px;
}
li.sub-terms-new {
  list-style-type: square;
}

