// circle
.circular-bar {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 0px 15px !important;
    .circular-chart {
        height: 80px;
        width: 80px;
        fill: transparent;
        stroke-linecap: round;
        .disabled-circle {
            stroke-width: 3.5px;
            stroke: $white;
        }
        .circle {
            stroke-width: 2px;
            stroke: $primary;
            transform-origin: center;
            @include animation(circle-animate 3s reverse);
        }
        @keyframes circle-animate {
          to { stroke-dasharray: 0 100; }
        }
    }
    .chart-label {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 70px;
        height: 80px;
        top: 0;
        position: absolute;
        .value-number {
            position: relative;
            font-size: $size14;
            font-weight: $font-weight-bold;
            font-family: $ubuntu-font;
        }
    }
    .value-label {
        display: flex;
        margin-left: 20px;
        font-size: $base-font-size;
        font-weight: $font-weight-bold;
        font-family: $ubuntu-font;
    }
    &.size-54{
        .circular-chart, .chart-label{
            width: 54px;
            height: 54px;
        }    
    }
}

// line
.progress-bar-block {
    display: block;
    &-data {
        position: relative;
        overflow: hidden;
        background: rgba(255, 255, 255, 0.32);
        width: 100%;
        height: 5px;
        @include box-shadow(2px 4px 20px rgba(216, 216, 216, 0.25));
        @include border-radius(8px);
        .bar-area {
            background: $green;
            height: 5px;
            position: absolute;
            left: 0;
            animation-name: animateBar;
            animation-iteration-count: 1;
            animation-timing-function: ease-in;
            animation-duration: 1s;
            @include transition(width 1s ease-in);
            @include border-radius(8px);
        }
        @keyframes animateBar {
            0% {
                @include transform(translateX(-100%));
            }
            100% {
                @include transform(translateX(0));
            }
        }
    }
    .label {
        font-size: $size10;
        font-weight: $font-weight-regular;
        color: $white;
        margin-top: 10px;
    }
    .value {
        font-size: $h3-size;
        font-family: $ubuntu-font;
        font-weight: $font-weight-bold;
        color: $white;
        margin-top: 10px;
    }
}
