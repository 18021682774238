.function-list {
  border: 1px solid $lightblack;
  height: 350px;
  overflow: auto;
  @include border-radius(6px);
  &::-webkit-scrollbar-track {
    background-color: $gray-300;
    @include border-radius(10px);
  }
  &::-webkit-scrollbar {
    width: 8px;
    background-color: $gray-300;
    height: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $gray-700;
    @include border-radius(10px);
  }
  div.list {
    height: 40px;
    padding: 12px 22px;
    display: flex;
    align-items: center;
    // &:nth-child(even) {
    //     background: $gray-400;
    // }
    .form-group-checkbox {
      color: $lightblack;
      font-size: $size14;
    }
  }
}

.function-list.red {
  border: 1px solid #ff1100;
}

.content-center {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ag-row {
  z-index: 0;
  &.ag-row-focus {
    z-index: 1;
  }
}

.detail-tab {
  @include box-shadow(0px 0px 4px #ffc107);
  @include border-radius(4px);
  [class*="col-"] {
    // border: 1px solid #dfddf3;
    // @include border-radius(4px);
    &:nth-child(1) {
      background-color: $gray-400;
      border-right: 1px solid #dfddf3;
      @include border-radius(4px 0 0 4px);
    }
    &:nth-child(2) {
      background: $white;
      // @include border-radius(0 4px 4px 0);
    }
  }
  .tabs-vertical {
    display: block;
    border: none;
    height: 775px;
    margin: 5px 0;
    overflow: auto;
    &::-webkit-scrollbar-track {
      background-color: $gray-300;
      @include border-radius(10px);
    }
    &::-webkit-scrollbar {
      width: 8px;
      background-color: $gray-300;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $gray-700;
      @include border-radius(10px);
    }
    .nav-item {
      position: relative;
      padding: 18px;
      cursor: pointer;
      border: none;
      background-color: $gray-400;
      &::after {
        content: "";
        position: absolute;
        height: 1px;
        background-color: $gray-400;
        left: 15px;
        bottom: 0;
        width: calc(100% - 30px);
        margin: 0 auto;
      }
      span {
        font-size: $size12;
        color: $gray-900;
      }
      &.active {
        position: relative;
        background-color: $white;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          background-color: $yellow;
          width: 6px;
          height: 100%;
        }
      }
      &:first-child {
        &.active {
          &::before {
            border-top-left-radius: 4px;
          }
        }
      }
      &:last-child {
        &::after {
          display: none;
        }
        &.active {
          &::before {
            border-bottom-left-radius: 4px;
          }
        }
      }
    }
  }
  p {
    color: $gray;
  }
}
.approve-card-block {
  padding: 28px 0;
  counter-reset: card-counter;
  .approve-card {
    position: relative;
    .number {
      position: relative;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 42px;
      text-align: center;
      &::before {
        position: absolute;
        height: 26px;
        width: 26px;
        background-color: #f37658;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $size14;
        counter-increment: card-counter;
        content: counter(card-counter);
        @include border-radius(50%);
      }
      &::after {
        content: "";
        position: absolute;
        top: 5px;
        right: -16px;
        border: solid $gray-900;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 7px;
        @include transform(rotate(-45deg));
      }
    }
    &-content {
      box-shadow: 0px 2px 8px #00000140;
      padding: 20px 14px;
      margin-bottom: 16px;
      @include border-radius(10px);
    }
    &-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .action-btns {
        span i {
          cursor: pointer;
          &:hover {
            &.icon-delete-account::before {
              color: $red;
            }
          }
        }
      }
    }
    .add-new-assignee,
    .action-btns {
      span i {
        cursor: pointer;
      }
    }
    &-body {
      border: 1px solid #ccc;
      padding: 12px;
      margin-bottom: 8px;
      @include border-radius(5px);
      &:last-child {
        margin-bottom: 0;
      }
      .card-description {
        font-size: $size14;
        color: $lightblack;
        span {
          color: $gray-1000;
        }
      }
      .create-new {
        cursor: pointer;
        font-size: $size18;
        text-align: center;
        color: $blue;
        text-transform: capitalize;
      }
    }
  }
  .create-step {
    padding-top: 42px;
  }
}

.email-menu a {
  display: inline-block;
  padding: 10px;
  color: $lightblack;
  font-weight: bold;
  font-size: 14px;
}
.email-menu i {
  vertical-align: middle;
  color: #133563 !important;
  padding-right: 10px;
}
.email-tabss {
  padding-top: 10px;
}

.budget-lines {
  position: relative;
  max-width: 98%;
  .delete-block {
    display: flex;
    position: absolute;
    right: -34px;
    bottom: 26px;
  }
}
.image-upload input {
  display: none;
}
.account-pic-upload label {
  position: relative;
}
.image-upload img {
  background: #fff;
  border-radius: 50%;
  border: 1px solid #ccc;
  width: 200px;
  height: 200px;
  object-fit: cover;
}
.hover-image {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  border-radius: 50%;
  opacity: 0.8;
  width: 200px;
  height: 200px;
  align-items: center;
  justify-content: center;
}
.account-pic-upload:hover .hover-image {
  display: flex;
}

.subscription-card {
  position: relative;
  background: $white;
  padding: 20px;
  @include border-radius(8px);
  @include box-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
  .sub-item {
    line-break: anywhere;
    padding: 8px 0;
    width: 100%;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
    .label {
      font-size: $size14;
    }
    .detail {
      margin-bottom: 0;
    }
  }
}

.sub-label {
  font-size: $size12;
  color: $gray-1000;
  text-transform: uppercase;
}

.right-border {
  border-right: 1px solid $gray-silver;
}

.pricing-card {
  background: $white;
  padding: 8px;
  text-align: center;
  height: 100%;
  display: grid;
  align-items: center;
  @include box-shadow(0px 5px 25px rgba(0, 0, 0, 0.1));
  @include border-radius(5px);
  h1 {
    font-size: $size18 !important;
  }
  .talk {
    font-size: $size20 !important;
  }
  &.highlight {
    background: $landing-yellow;
    color: $white;
  }
}

.info-block {
  background: $gallery;
  padding: 6px 10px;
  @include border-radius(6px);
  @include box-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
}
