.file-upload-container {
  &.drag {
    background-color: $gray-700;
  }
  input[type="file"] {
    display: none;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      margin-right: 4px;
    }
  }
  .upload-preview {
    // border: 1px solid $gray-700;
    display: flex;
    flex-direction: column;
    padding: 5px;
    word-break: break-all;
    position: relative;
    @include border-radius(4px);
    list-style-type: none;
    margin: 0;
    height: 100%;
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    p {
      word-break: break-word;
      span.required {
        color: $red;
        margin-left: 4px;
      }
    }
    &.inline {
      a.c-pointer {
        max-width: 80%;
        cursor: pointer;
      }
    }
    .uploader {
      position: relative;
      border: 2px dotted $blue;
      background-color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      display: flex;
      text-align: center;
      margin: 0;
      height: 100%;
      img {
        width: 100% !important;
        height: 315px !important;
      }
    }
    li {
      z-index: 1;
      position: relative;
      overflow: hidden;
      // display: flex;
      // justify-content: space-between;
      margin-bottom: 4px;
      @include user-select(none);
      &.uploaded-doc {
        .hover-doc {
          display: none;
        }
        &:hover {
          .hover-doc {
            background-color: #3c383870;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 10;
            width: 100%;
            height: 100%;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
              margin: 0;
            }
          }
        }
      }
      img {
        width: 16px;
        height: 16px;
        &.prev {
          width: 100%;
          height: 100%;
        }
      }
      i {
        display: table;
        cursor: pointer;
        margin-left: 12px;
        z-index: 1;
        &:hover {
          &::before {
            color: $red;
          }
        }
      }
      span {
        white-space: nowrap;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
      }
      .uploader {
        position: relative;
        border: 2px dotted $blue;
        background-color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        display: flex;
        text-align: center;
        margin: 0;
        height: 180px;
        i {
          margin: 0;
          z-index: 0;
        }
        input {
          display: block !important;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
          z-index: 1;
        }
      }
    }
  }
}
