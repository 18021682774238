.block-pads {
  padding-left: 250px;
  padding-right: 30px;
  padding-bottom: 40px;
  background: #f0f2f5;

  @media only screen and (min-width: 1600px) {
    padding-left: 305px;
    padding-right: 70px;
  }
}

.unapproved-detail {
  color: $burnt-sienna;
  & > * {
    color: $burnt-sienna !important;
  }

  &:not(h3) {
    font-size: $size14;
  }

  i {
    cursor: pointer;

    &::before {
      color: $burnt-sienna !important;
    }
  }
}

.content-block {
  height: calc(100vh - 30px);
  overflow: auto;
  scroll-behavior: smooth;
  scrollbar-color: #e4e5e7 #ffffff;
  scrollbar-width: thin;
  background: #fff;

  &::-webkit-scrollbar-track {
    background-color: $gray-300;
    @include border-radius(10px);
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: $gray-300;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-700;
    @include border-radius(10px);
  }

  .fixed-footer {
    position: fixed;
    bottom: 0;
    padding: 20px 40px;
    height: 80px;
    z-index: 4;
    background: $white;
    width: calc(100% - 220px);
    left: 220px;

    &::before {
      content: "";
      position: absolute;
      width: calc(100% - 76px);
      height: 1px;
      background-color: #bdbdbd;
      top: 0;
      left: 35px;
    }

    @media only screen and (min-width: 1600px) {
      width: 100%;
      left: 0;
    }

    @media only screen and (max-width: 767px) {
      width: 100%;
      left: 0;
      height: 90px;
      padding: 10px 20px;
      .form-group-checkbox {
        width: 100%;
        margin-bottom: 20px !important;
      }
    }
  }

  &-view {
    margin: 20px 0;

    &.banking {
      margin: 20px !important;
    }

    &-section {
      margin-bottom: 5px;
      padding: 10px 20px;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);

      .section-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;

        .title-heading {
          font-weight: $font-weight-bold;
          color: $lightblack;
          display: flex;
          align-items: flex-end;

          i {
            margin-left: 4px;
          }

          .subtitle {
            color: $lightblack;
            font-style: italic;
            font-size: $base-font-size;
            font-weight: $font-weight-regular;
          }

          .sub-heading {
            margin-left: 16px;
            font-size: $base-font-size;
            font-weight: $font-weight-regular;
            color: $lightblack;
          }
        }

        &-text {
          font-size: $size14;
          color: $gray-trout;
          width: 100%;
        }
      }

      .label-info {
        color: $lightblack;
        font-style: italic;
        font-size: $size12;
        padding-bottom: 10px;

        span {
          color: $red;
          font-style: normal;
        }
      }

      .label {
        display: block;
        font-size: $size14;
        font-weight: $font-weight-bold;
        margin-bottom: 30px;
      }

      .label-head {
        display: block;
        color: $lightblack;
        font-size: $size14;
        font-weight: $font-weight-bold;
        margin-bottom: 30px;
      }

      .label-text {
        font-size: $size12;
        font-weight: $font-weight-regular;
        color: $lightblack;
      }

      .label-block {
        position: relative;
        display: block;
        font-size: $size14;
        font-weight: $font-weight-semibold;
        margin-bottom: 8px;

        span {
          color: $red;
        }
      }

      .suggested-categories {
        &-block {
          width: 136px;
          cursor: pointer;
          padding: 10px;
          display: block;
          text-align: center;
          border: 1px solid $primary-lighter;
          margin-right: 30px;
          @include border-radius(8px);

          &:hover {
            background-color: $primary-light;
          }

          img {
            margin-bottom: 20px;
          }

          .label-text {
            // font-family: $ubuntu-font;
            font-size: $size14;
            font-weight: $font-weight-semibold;
          }

          &:last-child {
            margin-right: 0;
          }

          @media only screen and (max-width: 1024px) {
            margin-right: 15px;
          }
        }
      }

      .selected-tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .tag {
          color: $blue;
          background-color: $primary-light;
          padding: 4px 8px;
          border: 1px solid $blue;
          font-size: $size14;
          font-weight: $font-weight-regular;
          margin-right: 10px;
          margin: 5px;
          @include border-radius(20px);

          .remove {
            cursor: pointer;
            margin-left: 4px;
          }
        }
      }
    }

    .fixed-footer {
      position: fixed;
      bottom: 18px;
      padding: 20px 40px;
      height: 80px;
      z-index: 4;
      background: $white;
      width: calc(100% - 220px);
      left: 220px;

      &::before {
        content: "";
        position: absolute;
        width: calc(100% - 76px);
        height: 1px;
        background-color: #bdbdbd;
        top: 0;
        left: 35px;
      }

      @media only screen and (min-width: 1600px) {
        width: calc(100% - 300px);
        left: 280px;
      }

      @media only screen and (max-width: 767px) {
        width: 100%;
        left: 0;
        height: 90px;
        padding: 10px 20px;
        .form-group-checkbox {
          width: 100%;
          margin-bottom: 20px !important;
        }
      }
    }

    button.p-custom {
      padding: 10px 20px;
    }

    .banking-list {
      // background-color: #fbfbfb;
      position: relative;
      width: calc(100% + 100px);
      left: -50px;
      padding: 16px 50px;
    }
  }

  &-p {
    padding: 30px 30px 30px 130px;
  }

  &-sm {
    .leftsidebar {
      z-index: 999;
    }

    .header-block {
      padding: 15px 20px;
      left: 0;
      width: calc(100% - 10px);
      background: #f6f6f6;
    }

    .block-pads {
      padding-left: 30px;
    }

    .fixed-footer,
    .footer-block {
      width: 100% !important;
      left: 0 !important;
      margin: 0;
    }
  }

  .dropdown-menu {
    .dropdown-item {
      padding: 0.25rem 0.75rem;
    }
  }
}

.survey-template {
  .card-img {
    width: 100%;
    height: 180px;
    display: block;
    overflow: hidden;
    background: $white;
    @include box-shadow(0px 0px 10px rgba(0, 0, 0, 0.15));
    @include border-radius(8px);
    cursor: pointer;

    &:hover,
    &:focus {
      @include box-shadow(0px 0px 10px #f8df60);
    }

    img {
      height: 100%;
      width: 100%;
    }
  }
}

.feedback-layout {
  .blck-radio-brder {
    .pad-10 {
      padding: 10px;
      border: 1px solid $silver;
      margin-bottom: 16px;
      @include border-radius(4px);

      .form-group-radio {
        display: block;
        margin-bottom: 0;
        padding-left: 24px !important;
      }
    }
  }

  span.required {
    color: $red;
    margin-left: 4px;
  }

  .scroll-arrow {
    position: fixed;
    bottom: 30px;
    left: 0;
    display: block;
    width: 100%;
    z-index: 150;
    height: auto !important;
    justify-content: center;
    text-align: center;

    span.arrow-down {
      margin: 0 4px;
      cursor: auto;
    }

    span.up {
      margin-bottom: -4px;
      @include transform(rotate(224deg));
    }

    span.disabled {
      border-color: #dfddf3;
    }

    span.scroll-text {
      font-size: $size14;
    }
  }

  &-block {
    padding-bottom: 30px;

    .title-block {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background: $yellow;
        left: 0;
        bottom: -10px;
        @include border-radius(8px);
      }
    }

    .question-preview {
      input[type="text"],
      textarea {
        border: none;
        border-bottom: 2px solid $purple;
        padding: 10px 0;
        @include border-radius(0);

        &:active,
        &:focus {
          border: none;
          border-bottom: 2px solid $purple;
          @include box-shadow(none);
        }

        &::-webkit-input-placeholder {
          color: $purple;
        }

        &::-ms-input-placeholder {
          color: $purple;
        }

        &::placeholder {
          color: $purple;
        }
      }
    }

    .edit-card-block {
      position: relative;
      padding: 15px 20px 20px 20px;
      border: 1px solid #dfddf3;
      @include border-radius(0px 0px 8px 8px);

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 4px;
        background: $yellow;
      }

      .shuffle-block i {
        cursor: move;
      }

      .question-title input[type="text"] {
        font-size: $size18;
      }

      input[type="text"],
      textarea {
        border: none;
        border-bottom: 1px solid $gray;
        padding: 10px 0;
        @include border-radius(0);

        &:active,
        &:focus {
          border: none;
          border-bottom: 2px solid $purple;
          @include box-shadow(none);
        }
      }

      .more-option::after {
        top: 12px;
      }

      .show .more-option::after {
        background-color: $yellow;
        @include border-radius(50%);
      }

      .drop-down-item {
        display: flex;
        align-items: center;

        i::before {
          color: $purple;
        }
      }

      .SelectWidget {
        .select__control i::before,
        .select__menu i::before {
          color: $purple;
        }
      }

      .required-block {
        display: flex;
        align-items: center;
        position: relative;
        font-size: $size14;
        line-height: 28px;
        right: 34px;

        .slider-round {
          top: -2px;
        }
      }

      .floating-add {
        position: absolute;
        right: -110px;
        bottom: 0;
        cursor: pointer;

        .add-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 72px;
          height: 72px;
          background: #e8f6fd;
          @include box-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
          @include border-radius(50%);
        }

        .dropdown-menu {
          min-width: 72px;
          background: #e8f6fd;
          @include box-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
          @include border-radius(36px);

          button {
            padding: 10px;
            justify-content: center;

            &:hover {
              background-color: transparent;
            }

            i::before {
              color: $lightblack;
            }
          }
        }
      }
    }

    .sup-approval-tabs {
      padding: 10px 0;
      width: 100%;
      left: 0;
      border-bottom: 1px solid $yellow;
    }
  }
}

.template-layout {
  &-block {
    height: 100%;

    > div {
      height: calc(100vh - 90px) !important;
      padding: 20px 0;

      > div {
        height: 100vh !important;
      }
    }

    .label-text {
      font-size: $size14;
      color: $gray;
    }
  }

  .vhc-100 {
    height: calc(100vh - 110px);

    [class*="form-group-"] {
      padding: 0 6px;
    }

    button {
      margin-left: 6px;
    }
  }
}

.report-card {
  .text-content {
    font-size: $size14;

    span {
      color: $gray-dusty;
    }
  }

  .more-option::after {
    top: -14px;
  }
}

.help-text {
  font-size: $size12;
  color: $gray-trout;
}

.status-block {
  display: flex;

  .form-group-switch {
    width: 30px;
  }

  span {
    &.status {
      opacity: 0.5;
    }

    &.statusActive {
      opacity: 1;
    }
  }
}

.function-list-supplier {
  border: 1px solid $lightblack;
  height: 250px;
  overflow: auto;
  margin-bottom: 15px;
  @include border-radius(6px);

  &::-webkit-scrollbar-track {
    background-color: $gray-300;
    @include border-radius(10px);
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: $gray-300;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-700;
    @include border-radius(10px);
  }

  div.list {
    height: 40px;
    padding: 12px 22px;
    display: flex;
    align-items: center;

    // &:nth-child(even) {
    //     background: $gray-400;
    // }
    .form-group-checkbox {
      color: $lightblack;
      font-size: $size14;
    }
  }
}

.function-list-supplier.red {
  border: 1px solid #ff1100;
}

.comment-section {
  border: 1px solid $gray-700;
  word-break: break-all;
  @include border-radius(4px);

  .date,
  .btn-smd {
    font-size: $size10;
  }

  .btn-smd {
    padding: 5px;
    display: flex;
    align-items: center;
    margin-left: auto;

    i::before {
      color: $white;
    }
  }

  .comment-text {
    font-size: $size12;
    margin-bottom: 0;
  }

  textarea {
    outline: none !important;
    padding: 4px 8px;
  }

  .name {
    font-size: $size12;
    color: $lightblack;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .comment-list {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .approver-view {
    background: $dodger-blue;
    padding: 6px;
    margin-bottom: 6px;
    text-align: left;
    display: inline-block;
    max-width: 80%;
    @include border-radius(0 8px 8px 8px);

    p {
      color: $white;
    }
  }

  .owner-view {
    background: $gray-dusty;
    padding: 6px;
    margin-bottom: 6px;
    text-align: right;
    display: inline-block;
    max-width: 80%;
    @include border-radius(8px 0 8px 8px);

    p {
      color: $white;
    }
  }
}

// .base-change {
//   margin: 30px -15px !important;
// }
