.custom-modal {
  span.required {
    color: $red;
    margin-left: 2px;
  }

  label.search-label {
    margin-top: 0;
  }

  .react-bootstrap-table {
    table {
      margin-bottom: 0;
    }
  }

  .react-bootstrap-table-pagination {
    margin-top: 10px;
  }

  &.confirmation {
    &.size-lg {
      .modal-dialog {
        max-width: 800px !important;
      }
    }

    &.size-lg-large {
      .modal-dialog {
        max-width: 1000px !important;
      }
    }

    &.size-sm {
      .modal-dialog {
        max-width: 400px !important;
      }
    }

    .modal-dialog {
      max-width: 700px;

      .modal-header {
        padding: 30px 30px 0;
        border-bottom: none;

        .modal-title {
          font-size: $h3-size;
          font-weight: $font-weight-bold;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .label-info {
        display: flex;
        align-items: center;
        color: $lightblack;
        font-style: italic;
        font-size: $size12;
        font-weight: $font-weight-regular;
        margin-top: 10px;

        span {
          color: $red;
          font-style: normal;
        }
      }

      .modal-content {
        @include box-shadow(0px 0px 20px rgba(0, 0, 0, 0.5));
        @include border-radius(15px);

        .modal-body {
          padding: 30px !important;
        }
      }

      .confirm-dialog {
        text-align: center;

        .content {
          &-title {
            font-size: $size32;
            margin-bottom: 20px;
            color: $black;
            font-style: normal;
            font-weight: 300;
          }

          &-description {
            font-size: $size24;
            margin-bottom: 36px;
            color: $gray;
            font-style: normal;
            font-weight: 300;
          }
        }

        textarea {
          outline: none !important;
        }

        .no-results-found {
          font-size: $h5-size;
          color: $gray;
        }
      }
    }
  }

  .modal-content {
    background-color: $white;
    @include box-shadow(0px 4px 20px rgba(224, 224, 224, 0.25));
    @include border-radius(8px);

    a {
      font-weight: 700;
      font-size: 14px;
      color: $dodger-blue;
    }

    .form-group-checkbox {
      color: $gray-trout;
      font-size: $size12;

      .checkmark {
        width: 14px;
        height: 14px;

        &::after {
          height: 8px;
        }
      }
    }
  }

  .modal-backdrop.show {
    opacity: 0.7;
  }

  @media screen and (min-width: 600px) {
    .modal-sm {
      max-width: 420px;

      .modal-body {
        padding: 2rem;

        button {
          margin: 0 auto;
          max-width: 260px;
        }
      }
    }
  }

  .icon-remove {
    cursor: pointer;

    &:hover {
      &::before {
        color: $red;
      }
    }
  }

  .no-gutter-inp {
    [class*="form-group-"] {
      margin-bottom: 0;
    }
  }
}

.fixed-right {
  .modal {
    &.show .modal-dialog {
      right: 0;
    }

    .block-center {
      background-color: rgba(255, 255, 255, 0.3);
    }

    .modal-dialog {
      position: fixed;
      margin: 0;
      top: 0;
      width: 62%;
      max-width: 100%;
      height: 100vh;
      right: -62%;
      @include transition(right 0.3s ease-out);

      .modal-content {
        height: 100vh;
        overflow: auto;
        border: none;
        @include border-radius(8px 0 0 8px);

        &::-webkit-scrollbar-track {
          background-color: $gray-300;
          @include border-radius(0);
        }

        &::-webkit-scrollbar {
          width: 0;
          background-color: $gray-300;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $gray-700;
          @include border-radius(0);
        }

        .modal-header {
          padding: 30px 30px 0;
          border-bottom: none;

          .modal-title {
            font-size: $h3-size;
            font-weight: $font-weight-bold;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }

        .modal-body {
          padding: 40px 30px;
          max-height: calc(100vh - 125px);
          overflow: auto;

          &::-webkit-scrollbar-track {
            background-color: $gray-300;
            @include border-radius(10px);
          }

          &::-webkit-scrollbar {
            width: 8px;
            background-color: $gray-300;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $gray-700;
            @include border-radius(10px);
          }

          .form-group-input input[type="text"],
          .form-group-input input[type="email"],
          .form-group-input input[type="number"],
          .form-group-input input[type="url"],
          .form-group-input input[type="password"],
          .form-group-input textarea,
          .form-group-select .SelectWidget .select__control {
            background-color: #eef3f8;
          }

          table {
            tbody tr td {
              .form-group-select .SelectWidget .select__control {
                min-width: 150px;
              }
            }
          }

          .label-block {
            position: relative;
            display: block;
            font-size: $size12;
            font-weight: $font-weight-semibold;
            margin-bottom: 8px;

            span {
              color: $red;
            }
          }

          .modal-label {
            display: block;
            font-size: $h6-size;
            font-weight: $font-weight-bold;
          }

          span {
            // font-size: $size12;

            &.material-icons {
              font-size: $size18;
            }
          }

          .icon-po-download {
            cursor: pointer;
          }

          .invite-list {
            position: relative;
            width: calc(100% + 60px);
            left: -30px;
            // background-color: $background-bg;
            padding: 10px 30px;

            &-head {
              .checkmark {
                top: 6px;
              }

              .search.search-collapse {
                background-color: #edf3ff;
              }

              .more-option::after {
                top: -8px;
              }

              .label {
                color: $gray-dusty;
                font-weight: $font-weight-semibold;
                font-size: $base-font-size;
              }

              .value {
                color: $purple;
                font-weight: $font-weight-semibold;
                font-size: $base-font-size;
              }

              .icon-box {
                margin-left: 20px;
                padding: 12px;
                background-color: #edf3ff;
                cursor: pointer;
                @include border-radius(8px);

                i::before {
                  color: $lightblack;
                }

                &.active {
                  background-color: #ffeeea;

                  i::before {
                    color: #f37658;
                  }
                }
              }
            }
          }
        }

        .label-info {
          display: flex;
          align-items: center;
          color: $lightblack;
          font-style: italic;
          font-size: $size12;
          font-weight: $font-weight-regular;
          margin-top: 10px;

          span {
            color: $red;
            font-style: normal;
          }
        }

        .title-text {
          font-size: 1 rem;
          font-weight: 500;
          width: 100%;
          display: flex;
        }

        .action-items {
          justify-content: space-between !important;
        }
      }

      .fixed-footer {
        bottom: 0;
        right: -62%;
        position: fixed;
        z-index: 2;
        width: 62%;

        .custom-p {
          padding: 16px;
          background: $white;
          @include border-radius(0 0 0 8px);
        }
      }
    }

    &.show .modal-dialog {
      .fixed-footer {
        right: 0;
        @include transition(right 0.3s ease-out);
      }
    }
  }

  &.size-sm .modal .modal-dialog {
    width: 34%;

    .fixed-footer {
      width: 34%;
    }

    .modal-title {
      span {
        font-size: $base-font-size;
      }
    }
  }

  &.size-smd .modal .modal-dialog {
    width: 50%;

    .fixed-footer {
      width: 50%;
    }
  }

  &.size-lmd .modal .modal-dialog {
    width: 80%;

    .fixed-footer {
      width: 80%;
    }
  }

  &.size-lxd .modal .modal-dialog {
    width: 90%;

    .fixed-footer {
      width: 90%;
    }
  }

  &.size-md .modal .modal-dialog {
    width: 62%;

    .fixed-footer {
      width: 62%;
    }

    .modal-body {
      padding: 30px 30px;
    }
  }
}

nav.nav.nav-tabs a {
  padding: 10px;
  width: 20%;
  margin-bottom: 20px;
  background: #ffeeea;
  border: 1px solid #ccc;
}

.nav-tabs {
  border-bottom: none !important;
  margin-top: -15px;
}

.fixed-right.manage-roles .modal-dialog {
  width: 70%;
}

.fixed-right.manage-users .modal-dialog {
  width: 70%;
}

.remove-block i::before {
  color: #f37658 !important;
  padding-left: 15px;
}

.import-excel {
  .modal-dialog {
    max-width: 800px !important;
  }

  counter-reset: paragraph;

  p {
    font-size: 1.1rem;
    margin-bottom: 5px;
  }

  //   p::before {
  //     counter-increment: paragraph;
  //     content: counter(paragraph);
  //     border-radius: 50%;
  //     border: 1px solid;
  //     padding: 3px 8px;
  //     margin-right: 10px;
  //     position: relative;
  //     background-color: #fff;
  //     z-index: 9;
  //   }
  //   p::after {
  //     content: "";
  //     position: absolute;
  //     width: 21%;
  //     left: -40px;
  //     border-bottom: 1px dashed #505565;
  //     top: calc(8.7em - 0px);
  //     transform: rotate(90deg);
  //   }
}

@media screen and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
  .fixed-right .modal .modal-dialog .modal-content .modal-body {
    padding: 40px !important;
  }

  .fixed-right .modal .modal-dialog .fixed-footer .custom-p {
    padding: 16px 30px !important;
  }
}

span.close {
  cursor: pointer;

  i:before {
    color: #000 !important;
  }
}

.bid-spacing {
  margin-top: -40px;
}

.savings-modal {
  span {
    font-size: 14px;
    padding-top: 4px;
  }
}

.aucion-modal-text {
  span {
    font-size: 14px;
    width: 100%;
  }

  .form-control.event-ext {
    width: 155px;
  }
}

.event-extension-modal {
  .SelectWidget.css-2b097c-container {
    height: 40px;
  }

  .prod-border2 {
    width: 1px;
    // background: #3a436a99;
    height: 40px;
    z-index: 99;
    position: relative;
  }

  .form-group-select .SelectWidget .select__control {
    min-width: 85px;
    height: 40px;
  }

  .form-group-input {
    margin-bottom: 0px !important;
    width: 65px;
  }

  .form-group-select
    .SelectWidget
    .select__control
    .select__value-container.select__value-container--has-value
    .select__single-value {
    top: calc(10% - 7px);
  }
}

.suppliers-list-modal {
  p {
    margin-top: -30px !important;
  }

  .form-group-checkbox {
    margin-bottom: 5px;
    font-size: 14px !important;
  }

  label.sup-loc {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0px;
  }

  .icon {
    background: #f37658;
    padding: 5px 10px;
  }

  .icon-addnew:before {
    color: #fff;
  }

  .btn {
    border: 1px solid #888888;
    color: #888888;
  }
}

.tnc-modal {
  border-right: 1px solid #ccc;
  height: 400px;
  overflow-y: auto;

  .form-group-checkbox.span-visible {
    font-weight: 400 !important;
  }
}

.prefered-tnc-list {
  height: 280px;
  overflow-y: auto;
}

ul.tncList {
  padding-left: 0px;

  li {
    list-style-type: none;
    font-weight: bold;

    b {
      font-weight: bold;
    }
  }
}

.auction-evnt.dropdown-menu.show {
  margin-top: 5px;
  width: 175px;
  margin-left: -10px;
}

.icon-dropdown-arrow.drodown-auction:before {
  color: #fff;
}

.drodown-auction {
  padding-top: 3px;
  padding-left: 10px;
}

.place-proxy-card {
  border: 1px solid #ccc;
  padding: 10px;

  p {
    font-size: 12px;
  }

  h5 {
    font-size: 13px;
    color: $lightblack;
  }
}

.terms-card {
  border: 1px solid #ccc;
  padding: 10px;
}

.content-description {
  .MuiTable-root td.MuiTableCell-root div {
    font-weight: 400;
  }

  .MuiTable-root td.MuiTableCell-root p {
    width: 215px;
    white-space: break-spaces;
    font-weight: 400;
    color: $lightblack;
    margin-bottom: 0px;
    line-height: initial;
  }

  &.order-activity {
    height: 400px;
    overflow-y: auto;
  }

  .item-details-scroll {
    height: 450px;
    overflow: auto;

    &::-webkit-scrollbar {
      height: 6px !important;
    }
  }
}

.compare-table {
  &::-webkit-scrollbar {
    height: 6px !important;
  }

  font-size: 14px;
  // width: 540px;
  // height: 400px;
  color: $lightblack;
  overflow: auto;

  .compare-bid-card-main {
    border: 1px solid #f2f2f2;
    padding: 10px;
    font-size: 14px;
    display: flex;
    margin-left: 149px;
  }

  table {
    line-height: 20px;
    border: 1px solid #f2f2f2;

    th {
      background: #fee1da;
      padding: 5px;
      border: 1px solid #dee2e6;
    }

    td {
      padding: 5px;
      border: 1px solid #dee2e6;
    }

    .column-1 {
      width: 150px;
    }

    .column-2 {
      width: 200px;
      text-align: center;
    }
  }
}

.compare-bid-card {
  line-height: 20px;

  .info-company-name {
    font-weight: bold;
  }

  .info-created-date {
    font-size: 11px;
    word-break: break-word;
  }

  span {
    font-size: 24px;
    font-weight: bold;
  }

  .icon-eye-view {
    cursor: pointer;
  }
}

.compare-table-expand {
  font-size: 14px;
  color: $lightblack;

  .compare-bid-card-main {
    border: 1px solid #f2f2f2;
    padding: 10px;
    font-size: 14px;
    display: flex;
    margin-left: 149px;
  }

  table {
    line-height: 30px;
    border: 1px solid #f2f2f2;
    overflow-x: scroll;

    th {
      background: #fee1da;
      padding: 5px;
      border: 1px solid #dee2e6;
    }

    td {
      padding: 5px;
      border: 1px solid #dee2e6;
    }

    .column-1 {
      width: 150px;
    }

    .column-2 {
      width: 200px;
      text-align: center;
    }

    .form-group-radio .checkmark {
      border: 2px solid #5d4ce5;
    }

    .form-group-radio .checkmark::after {
      top: 1px;
      left: 1px;
      width: 10px;
      height: 10px;
    }
  }
}

.auction-details.mbd {
  max-height: 400px;
  overflow-y: scroll;

  table {
    width: 100%;
    line-height: 30px;
    border: 1px solid #f2f2f2;
    text-align: center;

    th {
      padding: 10px;
      border: 1px solid #dee2e6;
    }

    td {
      padding: 10px;
      border: 1px solid #dee2e6;
      background: #fff;
    }

    .form-group-input input[type="text"] {
      width: 150px;
    }
  }
}

.time-remain-modal {
  .SelectWidget.css-2b097c-container {
    height: 40px;
  }

  .prod-border2 {
    width: 1px;
    // background: #3a436a99;
    height: 40px;
    z-index: 99;
    position: relative;
  }

  .form-group-select .SelectWidget .select__control {
    min-width: 85px;
    height: 40px;
  }

  .form-group-input {
    margin-bottom: 0px !important;
    width: 260px;
  }

  .form-group-select
    .SelectWidget
    .select__control
    .select__value-container.select__value-container--has-value
    .select__single-value {
    top: calc(10% - 7px);
  }
}
.rdw-editor-main {
  padding: 10px;
}
