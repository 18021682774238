@media only screen and (max-width: 768px) {
  .pro-sidebar {
    width: 270px !important;
    min-width: 270px !important;
  }
  .pro-sidebar .toggle-block {
    display: none !important;
  }
  .pro-sidebar:hover .toggle-block {
    display: none !important;
  }
  .main-screen {
    display: none !important;
  }
  .mobile-screen {
    display: block;
  }
  .pro-sidebar .pro-sidebar-header .block-logo.mobile-screen img {
    display: block;
    padding: 10px;
  }
  .block-pads {
    padding-left: 20px !important;
  }
  header .header-block {
    left: 0px;
    width: calc(100% - 0px);
  }
  .dashboard-cards-row .col-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .dashboard-cards-row .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .dashboard-cards-row .col-6 .mobile-card {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .btn-menu-toggle {
    display: block;
  }
  .cards-row-new .custom-card .circle-lg {
    width: 80px !important;
    height: 80px !important;
  }
  footer .footer-block {
    width: 100%;
    left: 0px;
    margin: 0 0px;
  }
  .suppliers-list-head {
    .col-7,
    .col-5,
    .col-10,
    .col-8,
    .col-4 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .col-2 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  .fixed-right .modal .modal-dialog {
    width: 100% !important;
    .col-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .pr-preview {
    .col-9,
    .col-4,
    .col-3,
    .col-7,
    .col-5,
    .col-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .d-flex {
      display: block !important;
    }
    .text-right {
      text-align: left !important;
    }
  }
  .fixed-right.size-sm .modal .modal-dialog .fixed-footer {
    width: 100%;
  }
  .circle-size40 {
    width: 40px;
  }
  .sup-approval-tabs li:not(:first-of-type) {
    margin-left: 9px !important;
  }
  .suppliers-list {
    .col-6,
    .col-8,
    .col-4 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .float-right {
    float: right;
  }
  .right-content-block {
    .col-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .image-upload img {
      width: 75px;
      height: 75px;
    }
  }
  .sup-approval-overview-approved > div:first-child {
    border-right: none !important;
  }
  label.catlogue-upload-image {
    width: 180px;
  }
  .landing_section {
    background-position: inherit !important;
    .right-content {
      width: 100% !important;
    }
  }
  body > * ::-webkit-scrollbar {
    height: 5px;
  }
  .content-block-view {
    margin: 0px !important;
    .col-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .fixed-right.size-smd .modal .modal-dialog .fixed-footer {
    width: 100%;
  }
  .sup-approval-tabs li {
    font-size: 10px !important;
  }
  .sup-approval-tabs-content.mobile-screen {
    .col-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  .mb-screen {
    .d-flex {
      display: flex !important;
    }
    .mb-name {
      margin-top: 7px;
    }
  }
  .preview-overview-body {
    .col-4, .col-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
.supplier-view-cont {
  height: 600px;
  overflow-x: scroll;
}