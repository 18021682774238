.popreview-dn-polines{
    padding-top: 20px;
    border-top: 1px solid #EDF3FF;
    table{
        width: 100%;
        font-size: $size14;
        table-layout: fixed;
        
        th,td{
            font-weight: $font-weight-regular;
            &:nth-of-type(1){
                width: 60%;
                padding-right: 24%;
                text-overflow: ellipsis;

                /* Required for text-overflow to do anything */
                white-space: nowrap;
                overflow: hidden;
            }
            &:nth-of-type(2){
                width: 20%;
                padding-left: 10px;
            }
            &:nth-of-type(3){
                width: 20%;
                padding-left: 10px;
            }
        }

        tbody{
            color: $gray;
        }
    }
}