.auction-details-block {
  display: flex;
  align-items: center;

  i::before {
    color: $gray !important;
  }

  .auction-details {
    margin-left: 16px;
  }
}

.auction-links p {
  color: $lightblack;
  cursor: pointer;
  font-weight: bold;

  span {
    text-decoration: underline;
    padding-left: 5px;
  }
}

.auction-action {
  color: $blue;
  margin-bottom: 14px;
  font-family: $mulish-font;
  font-style: normal;
  font-weight: $font-weight-semibold;
  font-size: $size14;
  line-height: 20px;
  text-transform: capitalize;
  cursor: pointer;
}

.auction-right-sec-report {
  p {
    font-size: 16px;
    color: $lightblack;
    font-weight: bold;
  }
}

.auction-right-sec {
  max-height: 450px !important;
  overflow-x: hidden;
  overflow-y: auto;

  p {
    font-size: 16px;
    color: $lightblack;
    font-weight: bold;
  }
}

.latest-bid-card {
  // background: $background-bg;
  // border: 0.5px solid #d9d9d9;
  // @include box-shadow(0px 1px 4px rgba(0, 0, 0, 0.12));
  // @include border-radius(4px);
  padding: 10px 0px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dbdbdb;

  &-info {
    margin-left: 10px;

    .info-company-name {
      font-family: $mulish-font;
      font-style: normal;
      font-weight: $font-weight-bold;
      font-size: 16px;
      line-height: 20px;
      color: #14181b;
    }

    .info-created-date {
      font-family: $mulish-font;
      font-style: normal;
      font-weight: $font-weight-regular;
      font-size: $size14;
      line-height: 20px;
      color: #14181b;
      word-break: break-word;
    }

    .info-bid-amount {
      font-family: $mulish-font;
      font-style: normal;
      font-weight: 700;
      font-size: $size14;
      line-height: 20px;
      color: #56b542;
    }
  }

  span.rank-supp {
    display: inline-flex;
    position: relative;
    left: -35px;
    margin-top: 19px;
    font-size: 16px;
    color: #133563 !important;
    font-weight: bold;
    white-space: nowrap;
  }
  span.rank-supp.two-digits {
    left: -38px;
  }
}

.auction-details-modal {
  .selected-suppliers-list {
    display: flex;
    align-items: center;

    .supplier-name {
      font-size: $size14;
    }
  }

  .sent-or-seen-icon {
    display: flex;
    align-items: center;
    height: 21px;
  }

  .title-description {
    font-size: $size14;
  }

  .auction-details-modal-block {
    display: flex;
    align-items: center;

    label {
      color: $gray !important;
      font-weight: $font-weight-regular;
      font-size: $size14;
    }
  }
}

.lot-based-block {
  display: flex;
  align-items: center;

  i::before {
    color: $gray !important;
  }

  .auction-details {
    background: #f2f2f2;
    padding: 10px 20px;
    border: 1px solid #ccc;
  }

  img {
    padding: 5px;
    border: 1px solid #ccc;
  }
}

.rank-head {
  color: #ff8900;
  font-size: 22px;
}

.auction-table {
  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 8px;
  }

  table {
    width: 100%;
    border: 1px solid #adb5bd;
    font-size: 14px;

    thead {
      th {
        border-left: 1px solid #adb5bd;
        border-bottom: 1px solid #adb5bd;
        text-align: center;
        vertical-align: middle;
        background: #fee1da;
        color: $lightblack;
        padding: 12px;
        text-transform: capitalize;
        white-space: nowrap;
      }
    }

    tbody {
      td {
        border-left: 1px solid #adb5bd;
        border-bottom: 1px solid #adb5bd;
        border-top: 0px;
        text-align: center;
        vertical-align: middle;
        padding: 12px;
        white-space: nowrap;
        background: white;
      }
    }

    tr {
      @include border-radius(8px !important);
    }
  }
}

.editItemTable {
  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 8px;
  }

  table {
    width: 100%;
    border: 1px solid #adb5bd;
    font-size: 14px;

    thead {
      th {
        border-left: 1px solid #adb5bd;
        border-bottom: 1px solid #adb5bd;
        text-align: center;
        vertical-align: middle;
        background: #fee1da;
        color: $lightblack;
        padding: 12px;
        white-space: nowrap;
        text-transform: capitalize;
      }
    }

    tbody {
      td {
        border-left: 1px solid #adb5bd;
        border-top: 0px;
        text-align: center;
        vertical-align: middle;
        color: $lightblack;
        padding: 12px 12px 2px 12px;
        white-space: nowrap;
        min-width: 125px;
      }

      tr:nth-child(odd) {
        background-color: #fff;
      }
    }

    tr {
      @include border-radius(8px !important);
    }
  }
}

span.terms-check-auct {
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}

.auctions-items-scroll {
  // width: 725px;
  overflow-x: hidden;

  .auct-scroll {
    overflow-x: auto;
    white-space: nowrap;
  }

  .auct-item-dtls {
    border: 2px solid #e8e8e8;
    padding: 5px;
    width: 230px;
    margin: 5px;
    background: #edf7ff;
    display: inline-block;
    border-radius: 4px;
  }

  ::-webkit-scrollbar {
    height: 4px;
  }
}

.scrolling-message {
  display: flex;
  align-items: center;

  p {
    color: #ff0000;
    font-weight: bold;
    margin-bottom: 0;
    margin-right: 75px;

    &::before {
      content: "*";
      padding: 5px;
    }
  }
}

.auction-drop-menu {
  .task-dropdown {
    background: $white;
    @include border-radius(4px);

    .task-block {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      height: 38px;
      width: 38px;
      @include border-radius(50%);

      &:hover {
        background-color: $gallery;
      }

      &-count {
        position: absolute;
        top: 0;
        right: 0;
        height: 20px;
        width: 20px;
        background-color: $primary;
        border: 2px solid $white;
        color: $white;
        font-size: $size10;
        font-family: $ubuntu-font;
        font-weight: $font-weight-semibold;
        display: flex;
        align-items: center;
        justify-content: center;
        @include border-radius(50%);
      }
    }

    &-menu {
      max-height: 450px;
      width: 285px;
      overflow: auto;
      padding: 12px;

      &::-webkit-scrollbar-track {
        background-color: $gray-700;
        @include border-radius(10px);
      }

      &::-webkit-scrollbar {
        width: 4px;
        background-color: $gray-700;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $gray-700;
        @include border-radius(10px);
      }

      @media only screen and (max-width: 1024px) {
        width: 220px;
      }

      .task-item {
        background: transparent;
        padding: 0;

        .content-description {
          .title {
            color: $lightblack;
          }

          .label {
            color: $gray;
            font-size: $size12;
          }
        }
      }

      .view-all {
        font-weight: $font-weight-semibold;
        font-size: $size12;
        color: $dodger-blue;
      }
    }
  }
}

.auction-location {
  p {
    margin-bottom: 0px;
  }
}

p.mbd-error {
  margin-bottom: 0px;
  color: #ff0000;
  font-weight: bold;
  margin-top: -6px;
  padding: 0px;
  font-size: 12px;
}

.reverse-arrow {
  transform: rotate(180deg);
}

.slick-prev:before,
.slick-next:before {
  color: #000000;
}

.slick-prev {
  left: 0 !important;
  z-index: 1000 !important;
}

.slick-prev:before {
  color: #000000 !important;
}

.slick-next {
  right: 0 !important;
  z-index: 1000 !important;
}

.slick-next:before {
  color: #000000 !important;
}

.center .slick-center h3 {
  opacity: 1;
  -ms-transform: scale(1.08);
  transform: scale(1.08);
}

.compare-design-table {
  h1 {
    font-size: 18px;
    padding: 10px;
    background: #fee1da;
    // border: 1px solid #DBDBDB;
    margin-bottom: 0px;
  }

  .column-view {
    border: 1px solid #dbdbdb;

    p {
      margin-bottom: 0px;
      padding: 5px;
      justify-content: center;
    }
  }
}

.compare-design-table-aution-details {
  h1 {
    font-size: 18px;
  }

  .column-view {
    border: 1px solid #dbdbdb;

    p {
      margin-bottom: 0px;
      justify-content: center;
    }
  }
}

span.rank-table {
  color: #ff8900;
  font-size: 18px;
  font-weight: bold;
  /* margin-top: 9px; */
}
.star-mark {
  color: #ff0000;
}
