.leftsidebar {
  background-color: #111c43;
  width: 220px;
  position: fixed;
  height: 100vh;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include transition(200ms);

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 60px;
    left: 0;
    bottom: 0;
    background-image: url("../../../assets/images/illustrations/leftsidebar-bg.svg");
    background-repeat: no-repeat;
    background-position: 75%;
  }

  .block-logo {
    position: absolute;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 6px 10px;
    @include transition(0.5s);

    @media only screen and (max-width: 1024px) {
      padding: 6px 10px;
    }
  }

  .menu-block {
    display: flex;
    justify-content: center;
    height: 100vh;

    .menu-list {
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      max-height: calc(100vh - 140px);
      margin: 85px 0;
      padding: 0 24px;
      scroll-behavior: smooth;
      scrollbar-color: $gray-300 $gray-500;
      scrollbar-width: thin;

      &::-webkit-scrollbar-track {
        background-color: transparent;
        @include border-radius(10px);
      }

      &::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ffed92;
        @include border-radius(10px);
      }

      &-name {
        display: flex;
        align-items: center;
        color: #a3aed1;
        font-size: $base-font-size;
        font-weight: $font-weight-medium;
        font-family: $ubuntu-font;
        line-height: 40px;
        cursor: pointer;
        margin-bottom: 5px;
        @include transition(none);

        i {
          line-height: 40px;
          margin-right: 14px;

          &::before {
            color: #a3aed1;
          }
        }

        &.complete {
          position: relative;
          padding-left: 36px;

          i {
            display: none;
          }

          &::before {
            content: "";
            position: absolute;
            z-index: 1;
            height: 24px;
            width: 24px;
            left: 3px;
            top: 6px;
            background-color: $green;
            @include border-radius(50%);

            @media only screen and (max-width: 1024px) {
            }
          }

          &::after {
            content: "";
            position: absolute;
            z-index: 2;
            left: 12px;
            top: 9px;
            width: 7px;
            height: 16px;
            border: solid $white;
            border-width: 0 3px 3px 0;
            @include border-radius(2px);
            @include transform(rotate(45deg));
          }

          @media only screen and (max-width: 1024px) {
            padding-left: 38px;

            &::before {
              height: 22px;
              width: 22px;
            }

            &::after {
              left: 10px;
              top: 6px;
            }
          }
        }

        &.activeMenu {
          color: $primary;

          i::before {
            color: $primary;
          }
        }

        &.disableMenu {
          pointer-events: none !important;
          cursor: default !important;
          color: #607d8b !important;

          i::before {
            color: #607d8b !important;
          }
        }
      }

      @media only screen and (max-width: 1024px) {
        padding: 0 10px;

        &-name {
          line-height: 30px;
        }
      }
    }
  }
}

.leftsidebar .logo-lg {
  display: block;
  width: 180px;
  margin-top: 20px;
}

.pro-sidebar {
  color: #a3aed1 !important;
  position: absolute !important;
  width: 225px !important;
  min-width: 225px !important;

  &-header {
    border: 0 !important;
    height: 55px;
    background: #202a5b;

    .block-logo {
      img {
        display: block;
        width: 160px;
        margin: 10px;
      }
    }
  }

  &:hover {
    .toggle-block {
      display: block;
    }
  }

  .toggle-block {
    display: none;
    cursor: pointer;
    position: absolute;
    top: 26px;
    right: -10px;
    z-index: 9;
    width: 24px;
    height: 24px;
    background-color: $white;
    @include border-radius(50%);
    @include box-shadow(0px 4px 4px rgba(6, 17, 39, 0.1));
    @include transition(0.5s);

    @media only screen and (max-width: 1200px) {
      display: block;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: 2;
      right: 6px;
      top: 8px;
      width: 8px;
      height: 8px;
      border: solid #a3aed1;
      border-width: 0 2px 2px 0;
      @include transition(0.5s);
      @include transform(rotate(136deg));
    }
  }

  &.collapsed {
    width: 68px !important;
    min-width: 68px !important;

    .pro-sidebar-header {
      .block-logo {
        img {
          display: block;
          width: 40px;
          margin: 10px auto;
        }
      }

      .toggle-block {
        &::after {
          right: 10px;
          @include transform(rotate(316deg));
        }
      }
    }

    .popper-inner .pro-item-content a {
      color: $white !important;
    }

    .pro-menu-item {
      padding: 4px !important;

      .pro-inner-list-item ul li {
        padding: 0 !important;
      }
    }

    .pro-inner-list-item {
      @include border-radius(0 8px 8px 0 !important);
    }
  }

  &-inner {
    background-color: #111c43 !important;
  }

  &-layout {
    &::-webkit-scrollbar-track {
      background-color: rgba(255, 255, 255, 0.45);
      @include border-radius(10px);
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray-700;
      @include border-radius(10px);
    }
  }

  .pro {
    &-sidebar-layout {
      overflow: inherit !important;

      .pro-sidebar-content {
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
        max-height: 100vh;
        scroll-behavior: smooth;
        scrollbar-color: #202a5b #111c43;
        scrollbar-width: thin;

        &::-webkit-scrollbar-track {
          background-color: transparent;
          @include border-radius(10px);
        }

        &::-webkit-scrollbar {
          width: 4px;
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #ffed92;
          @include border-radius(10px);
        }
      }
    }

    &-menu {
      padding: 0 !important;

      .pro-menu-item > .pro-inner-item {
        padding: 6px 7px !important;
      }

      a {
        font-size: $base-font-size;
        font-family: $ubuntu-font;
        color: #a3aed1 !important;
        font-weight: $font-weight-semibold;
      }

      &-item {
        font-size: $base-font-size;
        font-family: $ubuntu-font;
        color: #a3aed1;
        font-weight: $font-weight-semibold;
        margin: 5px 7px;
        border-radius: 8px;

        &:hover {
          background-color: #2a3c7a;

          .pro-item-content,
          a {
            color: $white !important;
          }

          i::before {
            color: #fff !important;
          }
        }

        .pro-icon-wrapper {
          display: block !important;
          background-color: transparent !important;

          i::before {
            color: #a3aed1;
          }
        }

        &.active {
          background-color: #2a3c7a;
          color: $white !important;

          a,
          .pro-item-content {
            font-family: $ubuntu-font;
            font-weight: $font-weight-heavybold;
            color: $white !important;
          }

          i::before {
            color: #fff;
          }
        }

        .pro-inner-item {
          display: flex !important;
          padding: 0 12px !important;
          text-align: left;
          font-family: $ubuntu-font;

          .pro-icon-wrapper {
            display: block !important;
            background-color: transparent !important;
          }

          .pro-item-content {
            white-space: inherit !important;
            font-size: $base-font-size;
            font-family: $ubuntu-font;
            color: #a3aed1;
            font-weight: $font-weight-semibold;
          }
        }
      }
    }

    &-sub-menu {
      .pro-inner-list-item {
        padding: 0 !important;
        background-color: #111c43 !important;

        ul {
          padding: 0 !important;

          li {
            padding: 0 !important;
            padding-left: 10px !important;

            a {
              color: #a3aed1 !important;
              font-size: $size14;
            }
          }
        }
      }

      .pro-arrow {
        border-color: #a3aed1 !important;
      }

      .popper-inner {
        background-color: #111c43 !important;
        @include border-radius(0 8px 8px 0 !important);

        .pro-menu-item {
          font-size: $size14 !important;

          &:hover {
            background-color: transparent !important;
          }
        }

        .pro-inner {
          &-list-item {
            padding: 0;
          }

          &-item {
            text-align: left;
            padding: 8px !important;

            .pro-item-content {
              font-size: $size14 !important;
            }

            &::before {
              display: none !important;
            }

            a {
              font-size: $size14 !important;
              font-weight: $font-weight-regular !important;
              color: #a3aed1 !important;

              &:hover {
                font-weight: $font-weight-heavybold !important;
              }
            }
          }
        }
      }
    }

    &-sidebar-footer {
      position: relative;
      min-height: 60px !important;
      border: 0 !important;
      content: "";
      width: 100%;
      height: 60px;
      left: 0;
      bottom: 0;
      background-image: url("../../../assets/images/illustrations/leftsidebar-bg.svg");
      background-repeat: no-repeat;
      background-position: 75%;
    }
  }

  .disableMenu {
    pointer-events: none !important;

    i::before {
      color: #607d8b !important;
    }
  }
}

.main-screen {
  display: block;
}

.mobile-screen {
  display: none;
}

.btn-toggle {
  cursor: pointer;
  width: 35px;
  height: 35px;
  background: #353535;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  display: none;
}

@media (max-width: 768px) {
  .btn-toggle {
    display: flex;
  }

  .rtl {
    .btn-toggle {
      margin-left: auto;
    }
  }
}

a.menu-list-name.disableMenu {
  pointer-events: none !important;
  cursor: default !important;
  color: #607d8b !important;
}

.disableMenu {
  i::before {
    color: #607d8b !important;
  }
}
