.queryBuilder {
  select,
  input {
    width: auto;
    height: 45px;
    max-width: 200px;
    line-height: 1;
    border-radius: 0.25em;
    padding: 6px 12px;
    border: 1px solid #aaaaaa;
    &:hover,
    &:focus {
      outline: none !important;
    }
  }
  button {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    @include user-select(none);
    @include border-radius(0.25rem);
    @extend .btn, .btn-primary;
  }
  .rule {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    [class*="rule-"] {
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
    &-value {
      width: 200px;
    }
  }
  .ruleGroup {
    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      [class*="ruleGroup-"] {
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  button {
    &:disabled {
      background-color: #9e9e9e !important;
      color: $lightblack !important;
      border-color: #9e9e9e !important;
      @include box-shadow(none !important);
      &:hover,
      &:active,
      &:focus {
        background-color: #9e9e9e !important;
      }
    }
  }
}
