.version-section {
  word-break: break-all;
  @include border-radius(4px);

  &.selected {
    border: 1px solid $primary;
  }

  .version-container {
    padding-bottom: 8px;
    margin-bottom: 8px;

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .version-number {
      font-weight: $font-weight-bold;
      font-size: $h4-size;
      color: $blue;
      cursor: pointer;
      margin-bottom: 4px;
    }

    .version-updated-date {
      font-size: $size12;
      margin-bottom: 4px;
    }

    .version-updated-user {
      font-weight: $font-weight-regular;
      font-size: $h5-size;
    }
  }
}

.select-template-block {
  .template-card {
    width: 100%;
    background-color: $white;
    position: relative;
    margin-bottom: 20px;
    @include transition(transform 0.3s);
    @include border-radius(4px);

    &:hover {
      @include transform(scale(1.05));
      @include box-shadow(5px 5px 10px rgba(0, 0, 0, 0.15));

      .template-radio {
        border-color: $gray-900;

        &:checked {
          border-color: $primary;
        }
      }

      .plan-details {
        border-color: $gray-500;
      }
    }
  }

  .template-radio {
    font-size: inherit;
    margin: 0;
    position: absolute;
    right: 10px;
    top: 14px;
  }

  @supports (-webkit-appearance: none) or (-moz-appearance: none) {
    .template-radio {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: $white;
      border: 2px solid $gray-700;
      cursor: pointer;
      height: 20px;
      width: 20px;
      outline: none;
      transition: background 0.2s ease-out, border-color 0.2s ease-out;
      @include border-radius(50%);

      &::after {
        content: "";
        border: 2px solid $white;
        border-top: 0;
        border-left: 0;
        display: block;
        height: 10px;
        left: 4px;
        top: 8px;
        position: absolute;
        transform: rotate(45deg) translate(-50%, -50%);
        width: 0.375rem;
      }

      &:checked {
        background: $primary;
        border-color: $primary;

        ~.template-details {
          border-color: $primary;
        }
      }

      &:focus~.template-details {
        @include box-shadow(0 0 0 2px $gray-500);
      }
    }
  }

  .template-details {
    border: 2px solid $gray-400;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 10px;
    transition: border-color 0.2s ease-out;
    @include border-radius(4px);

    .template-type {
      font-size: $size14 !important;
      font-weight: $font-weight-bold;
      margin-bottom: 8px;
    }

    .template-description {
      font-size: $size12 !important;
      color: $gray-dusty;
    }
  }
}

.review-comments {
  padding: 10px;
  // background: #fff;
  border-radius: 8px;
}
