header {
  height: 55px;
  width: 100%;
  .header {
    &-block {
      background: $white;
      display: flex;
      align-items: center;
      padding: 15px 30px 15px 15px;
      position: fixed;
      z-index: 99;
      height: 55px;
      top: 0;
      left: 220px;
      width: calc(100% - 220px);
      @include box-shadow(0px 4px 4px rgba(224, 224, 224, 0.25));
      @media only screen and (max-width: 1024px) {
        padding: 15px 20px;
      }
      @media only screen and (min-width: 1600px) {
        padding: 15px 70px;
      }
    }
    &-preview {
      background: $white;
      display: flex;
      align-items: center;
      width: calc(100% - 60px);
      left: 60px;
      position: fixed;
      z-index: 99;
      height: 60px;
      top: 0;
      padding: 15px 50px;
      @include box-shadow(0px 4px 4px rgba(224, 224, 224, 0.25));
      @media only screen and (max-width: 1024px) {
        padding: 15px 20px;
      }
      @media only screen and (min-width: 1600px) {
        padding: 15px 70px;
      }
    }
    &-content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h3 {
        font-weight: $font-weight-bold;
        align-items: end;
        a {
          color: $lightblack;
          line-height: 1;
        }
        .label {
          font-size: $base-font-size;
          color: $lightblack;
        }
      }
      .autosave {
        color: $green;
        font-size: $size12;
        font-weight: $font-weight-bold;
        display: flex;
        align-items: center;
        &:after {
          content: "\2026"; /* ascii code for the ellipsis character */
          overflow: hidden;
          display: inline-block;
          vertical-align: bottom;
          @include animation(ellipsis steps(4, end) 900ms infinite);
          width: 0px;
        }
        @keyframes ellipsis {
          to {
            width: 20px;
          }
        }

        @-webkit-keyframes ellipsis {
          to {
            width: 20px;
          }
        }
        i::before {
          color: $green;
        }
      }
      .quicklink-dropdown {
        @include user-select(none);
        span {
          cursor: pointer;
        }
        &-menu {
          top: 2px !important;
          padding: 0 !important;
          min-width: 250px;
          max-width: 380px;
          background: $white;
          border: 1px solid rgba(45, 140, 255, 0.2);
          @include box-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
          @include border-radius(5px);
          .menu-option {
            color: $black;
            font-size: $size14;
            font-weight: $font-weight-regular;
            padding: 0;
            &:hover {
              background-color: $gallery;
            }
            &.selected {
              background-color: $yellow;
              color: $black;
              font-weight: $font-weight-medium;
            }
            a {
              display: block;
              color: $black;
              padding: 0.75rem 1.5rem;
              @include user-select(none);
            }
          }
        }
      }
      .approval-status {
        padding: 6px 12px;
        background-color: #57d95c;
        font-size: $size10;
        font-weight: $font-weight-bold;
        color: $white;
        @include border-radius(12px);
      }
      .task-dropdown {
        background: $white;
        @include border-radius(4px);
        .task-block {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          height: 38px;
          width: 38px;
          @include border-radius(50%);
          &:hover {
            background-color: $gallery;
          }
          &-count {
            position: absolute;
            top: 0;
            left: 20px;
            height: 20px;
            width: auto;
            background-color: #368bfc;
            border: 2px solid $white;
            color: $white;
            font-size: $size10;
            font-family: $ubuntu-font;
            font-weight: $font-weight-semibold;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 4px;
            @include border-radius(20%);
          }
        }
        &-menu {
          max-height: 450px;
          width: 285px;
          overflow: auto;
          padding: 12px;
          &::-webkit-scrollbar-track {
            background-color: $gray-700;
            @include border-radius(10px);
          }
          &::-webkit-scrollbar {
            width: 4px;
            background-color: $gray-700;
          }
          &::-webkit-scrollbar-thumb {
            background-color: $gray-700;
            @include border-radius(10px);
          }
          @media only screen and (max-width: 1024px) {
            width: 220px;
          }
          .task-item {
            background: transparent;
            padding: 0;
            .content-description {
              .title {
                color: $lightblack;
              }
              .label {
                color: $gray;
                font-size: $size12;
              }
            }
          }
          .view-all {
            font-weight: $font-weight-semibold;
            font-size: $size12;
            color: $dodger-blue;
          }
        }
      }
      .notification-dropdown {
        background: $white;
        @include border-radius(4px);
        .notification-block {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          height: 38px;
          width: 38px;
          @include border-radius(50%);
          &:hover {
            background-color: $gallery;
          }
          &-count {
            position: absolute;
            top: 0;
            left: 20px;
            height: 20px;
            width: auto;
            background-color: #368bfc;
            border: 2px solid $white;
            color: $white;
            font-size: $size10;
            font-family: $ubuntu-font;
            font-weight: $font-weight-semibold;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 4px;
            @include border-radius(20%);
          }
        }
        &-menu {
          max-height: 450px;
          width: 285px;
          overflow: auto;
          padding: 12px;
          &::-webkit-scrollbar-track {
            background-color: $gray-700;
            @include border-radius(10px);
          }
          &::-webkit-scrollbar {
            width: 4px;
            background-color: $gray-700;
          }
          &::-webkit-scrollbar-thumb {
            background-color: $gray-700;
            @include border-radius(10px);
          }
          @media only screen and (max-width: 1024px) {
            width: 220px;
          }
          .notification-item {
            background: transparent;
            padding: 0;
            .content-description {
              .title {
                color: $lightblack;
              }
              .label {
                color: $gray;
                font-size: $size12;
              }
            }
          }
          .view-all {
            font-weight: $font-weight-semibold;
            font-size: $size12;
            color: $dodger-blue;
          }
        }
      }
      .lang-select {
        background-color: $gray-300;
        @include border-radius(8px);
        &:hover {
          background-color: $gallery;
          .form-group-select {
            .SelectWidget {
              .select__control {
                background-color: $gallery !important;
              }
            }
          }
        }
        .form-group-select {
          margin-bottom: 0;
          .SelectWidget {
            .select__control {
              background-color: $gray-300 !important;
              min-width: 90px !important;
              border: none !important;
              padding: 0 4px !important;
              min-height: 38px;
              @include transition(none);
              @include box-shadow(none !important);
            }
            .select__menu {
              width: 153px;
              left: -30px;
              @media only screen and (max-width: 1199px) {
                width: 142px;
                left: -20px;
              }
            }
          }
        }
      }
      .user-icon {
        height: 40px;
        width: 40px;
        object-fit: cover;
        border: 3px solid $gray-300;
        cursor: pointer;
        @include border-radius(50%);
        &:hover,
        &.active {
          border: 3px solid $gallery;
        }
      }
      .rightblock {
        .header-status {
          color: $green;
          cursor: text;
        }
        .approval-history {
          position: relative;
          font-size: 14px;
          color: #505565;
          &::before {
            content: "";
            position: absolute;
            bottom: -12px;
            left: 0;
            right: 0;
            width: 8px;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #368bfc;
            margin: 0 auto;
            transition: transform 0.5s;
          }
          &.collapsed {
            &::before {
              transform: rotate(180deg);
            }
          }
        }
        .btn-success {
          i {
            margin-left: 4px;
            &::before {
              color: $white;
            }
          }
        }
      }
    }
  }
}

.user-block-head {
  background: $gallery;
  padding: 6px 10px;
  @include border-radius(4px);
}

.user-dropdown {
  &-menu {
    top: 12px !important;
    padding: 15px 0 !important;
    min-width: 200px;
    max-width: 260px;
    background: $white;
    border: 1px solid rgba(45, 140, 255, 0.2);
    @include box-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
    @include border-radius(5px);
    .user-name {
      color: $black;
      font-family: $mulish-font !important;
      font-weight: $font-weight-bold;
      padding: 0.25rem 1.5rem;
      margin-bottom: 0;
      font-size: 14px;
    }
    .user-role {
      color: $black;
      padding: 0 1.5rem 0.25rem;
      margin-bottom: 0;
    }
    .menu-option {
      color: $black;
      font-size: $size14;
      font-weight: $font-weight-regular;
      padding: 10px 1.5rem !important;
      a {
        display: flex;
        align-items: center;
        color: $black;
        @include user-select(none);
      }
      &:hover {
        background-color: $gallery;
        color: $black;
        font-weight: $font-weight-medium;
      }
      &.active {
        background-color: $primary;
        font-weight: $font-weight-medium;
        a {
          color: $white !important;
        }
      }
    }
    .dropdown-divider {
      margin: 0.5rem 15px;
    }
  }
  .btn {
    padding: 6px 10px;
    font-size: $size12;
    .icon-dropdown-arrow {
      margin-left: 20px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: -10px;
        height: 80%;
        top: calc(50% - 42%);
        width: 1px;
        background-color: #fafbff;
      }
      &::before {
        color: $white;
      }
    }
  }
  .user-icon {
    height: 40px;
    width: 40px;
    object-fit: cover;
    border: 3px solid $gray-300;
    cursor: pointer;
    @include border-radius(50%);
    &:hover,
    &.active {
      border: 3px solid $gallery;
    }
  }
}

.nav-another-menu {
  background: #f8f9fa;
  width: 100%;
  display: flex;
  padding: 10px 5px 0px 1px;
  position: absolute;
  top: 50px;
  height: 38px;
  left: -1px;
  box-shadow: 0px 2px 1px #f1f1f1;
}
.nav-another-menu a {
  color: $lightblack;
  font-size: 14px;
  //font-weight: bold;
  font-family: "Ubuntu", sans-serif;
}

.nav-another-menu a.activate {
  color: $lightblack;
  border-bottom: 3px solid #ff5722;
  padding-bottom: 7px;
}
ul.nav-ul-menu {
  display: flex;
  padding: 0px 0px 0px 20px;
}
ul.nav-ul-menu li {
  list-style-type: none;
  padding-right: 20px;
  padding-left: 10px;
}
.nav-another-menu a:hover {
  color: $lightblack;
  border-bottom: 3px solid #ff5722;
  padding-bottom: 7px;
}
img.logo-lg {
  width: 225px;
}
.header-logo {
  // width: 210px;
  height: 65px;
  object-fit: contain;
  padding: 2px 2px 5px 2px;
}
.btn-menu-toggle {
  display: none;
}

.blink-text {
  color: $red !important;
  animation: blink 3s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0.3;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
