.tab-topline {
  margin: 0 !important;
  li {
    position: relative;
    z-index: 1;
    display: block;
    margin: 0;
    flex: 1;
    border: 1px solid #ffc107;
    min-width: 150px;
    padding: 8px;
    background: #fff9dc;
    color: $lightblack;
    line-height: 1;
    transition: color 0.2s;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: $font-weight-bold;
    font-size: $size10;
    text-align: center;
    cursor: pointer;
    @include user-select(none);
    &:not(:last-child) {
      border-right: none;
    }
    &:hover,
    &:focus {
      color: $primary;
    }
    &.active {
      background: none;
      color: $primary;
      position: relative;
      &::before {
        content: "";
        width: 100%;
        height: 3px;
        position: absolute;
        top: -1px;
        left: 0;
        background-color: #f37658;
      }
      i {
        color: $primary;
      }
    }
    i {
      display: block;
      margin-bottom: 10px;
    }
  }
  &.flex-0 {
    li {
      min-width: 220px !important;
      flex: 0 !important;
    }
  }
}

.tab-outline-detail {
  border: 1px solid #ccc !important;
  top: -1px !important;
  padding: 30px 15px !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  // box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
  //   0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
