.block-center {
  height: calc(100vh - 120px);
  display: flex;
  align-items: center;
}

.prlines-block {
  background-color: $background-bg;
  min-height: 400px;
  padding: 30px 50px;
  position: relative;
  width: calc(100% + 100px);
  left: -50px;
  @media only screen and (min-width: 1600px) {
    padding: 30px 70px;
    width: calc(100% + 140px);
    left: -70px;
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .badge-primary {
      margin-left: 10px;
      font-size: $size12;
      color: #71da40;
      background-color: rgba(113, 218, 64, 0.1);
    }
  }
}

.show-more {
  color: #207ab6;
  cursor: pointer;
  position: relative;
  display: inline-block;
  font-size: $h5-size;
  padding-left: 16px;
  @include user-select(none);
  &::before {
    content: "";
    height: 8px;
    width: 8px;
    display: inline-block;
    border-top: 2px solid #2e92b7;
    border-left: 2px solid #2e92b7;
    transform: rotate(225deg);
    position: absolute;
    top: 5px;
    left: 0;
    transition-duration: 400ms;
  }
  &.expand {
    &:before {
      transform: rotate(45deg);
      transition-duration: 400ms;
      top: 2px;
    }
  }
}

.link {
  font-size: $h6-size;
  color: $dodger-blue;
  font-weight: $font-weight-bold;
  cursor: pointer;
}

//PO Preview Start
.po-preview-print {
  background-color: #fafbff;
  padding: 100px;
  &-head {
    padding-bottom: 20px;
    border-bottom: 1px solid #aaaaaa;
    font-size: $size12;
    .value {
      font-weight: $font-weight-bold;
    }
  }
  &-body {
    color: $gray-trout;
    font-size: $size12;
    h5,
    .sub-heading {
      font-weight: $font-weight-heavybold;
      color: $lightblack;
    }
    p {
      margin-bottom: 0;
    }
    table {
      width: 100%;
      // border-collapse: collapse;
      table-layout: fixed;
      thead {
        th {
          font-weight: $font-weight-heavybold;
        }
      }
      thead,
      tfoot {
        border-bottom: 2px solid $lightblack;
      }
      thead,
      tbody {
        tr {
          th,
          td {
            &:nth-of-type(1) {
              width: 10%;
            }
            &:nth-of-type(2) {
              width: 60%;
            }
            &:nth-of-type(3) {
              width: 10%;
            }
            &:nth-of-type(4) {
              text-align: right;
              width: 10%;
            }

            &:nth-of-type(5) {
              text-align: right;
              width: 10%;
            }
          }
        }
      }
      tr {
        vertical-align: middle;
        border-bottom: 1px solid #e0e0e0;
        border-top: 1px solid #e0e0e0;
        font-size: $size10;
        th,
        td {
          padding: 10px;
          color: $lightblack;
        }
        td:last-of-type {
          text-align: right;
        }
      }
      tfoot {
        th {
          font-size: $size12;
          text-align: right;
          em {
            font-size: $size18;
          }
        }
      }
    }
    ul.t-and-c {
      padding-left: 14px;
      list-style: none;
      li::before {
        content: "";
        height: 6px;
        width: 6px;
        position: absolute;
        left: 0px;
        top: 6px;
        background-color: $lightblack;
        @include border-radius(50%);
      }
    }
  }
}
//PO Preview End

/* PR Preview Page Start*/
.pr-preview {
  .preview-overview-body {
    .label {
      color: $lightblack;
      line-height: 28px;
    }
  }
  .show-more {
    &::before {
      transform: rotate(135deg);
    }
    &.expand {
      &::before {
        transform: rotate(225deg);
      }
    }
  }
}
/* PR Preview Page End*/

.invoice-doc {
  position: relative;
  border: 1px solid #ccc;
  @include box-shadow(0px 0px 4px #ffc107);
  @include border-radius(4px);
  .ribbon-left {
    width: 80px;
    height: 80px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    span {
      position: absolute;
      display: block;
      width: 140px;
      padding: 6px 0;
      background-color: $blue;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      color: $white;
      font-size: $size12;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      text-align: center;
      right: -25px;
      left: -40px;
      top: 15px;
      transform: rotate(-45deg);
    }
    // &::before,
    // &::after {
    //     position: absolute;
    //     z-index: -1;
    //     content: "";
    //     display: block;
    //     border: 5px solid $blue;
    //     border-top-color: transparent;
    //     border-left-color: transparent;
    // }
    // &::before {
    //     top: 0;
    //     right: 0;
    // }
    // &::after {
    //     bottom: 0;
    //     left: 0;
    // }
  }
  .doc-block {
    max-height: 240px;
    overflow: auto;
    padding: 20px;
  }
}

.card-label {
  display: flex;
  font-size: $size14;
  height: 40px;
  font-weight: $font-weight-bold;
  align-items: center;
  margin-bottom: 0;
  &.required span {
    color: $red;
    margin-left: 4px;
  }
}
