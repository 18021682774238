.app-pagination {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  bottom: 6px;

  &-wrapper {
    background-color: $white;
    padding: 11px 15px;
    @include border-radius(8px);
    @include box-shadow(0px 4px 30px rgba(0, 0, 0, 0.1));

    nav {
      @include user-select(none);

      ul {
        margin-bottom: 0;
      }
    }

    .arrow-left,
    .arrow-right {
      content: "";
      cursor: pointer;
      height: 10px;
      width: 10px;
      display: inline-block;
      border-top: 2px solid;
      border-left: 2px solid;
      transform: rotate(-45deg);
      border-color: #3f3d56;
      @include border-radius(2px);
    }

    .arrow-right {
      transform: rotate(135deg);
    }

    span {
      font-size: $size14;
      font-weight: $font-weight-semibold;
    }
  }
}

.sup-approval-tabs-content {
  .app-pagination {
    &-wrapper {
      background-color: none !important;
      box-shadow: none !important;
    }
  }
}

.pagination-custom {
  margin: 30px auto;
  display: flex;
  list-style: none;
  outline: none;
  justify-content: center;
}
.pagination-custom > .active > a {
  background-color: $lightblack;
  border-color: $lightblack;
  color: #fff;
}
.pagination-custom > .disabled > a {
  border-color: #ccc;
  color: #989898;
}

.pagination-custom > li > a {
  border: 1px solid $lightblack;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination-custom > .active > a,
.pagination-custom > .active > span,
.pagination-custom > .active > a:hover,
.pagination-custom > .active > span:hover,
.pagination-custom > .active > a:focus,
.pagination-custom > .active > span:focus {
  background-color: $lightblack;
  border-color: $lightblack;
  outline: none;
  color: #fff;
}
.pagination-custom > li > a,
.pagination-custom > li > span {
  color: $lightblack;
}
.pagination-custom > li:first-child > a,
.pagination-custom > li:first-child > span,
.pagination-custom > li:last-child > a,
.pagination-custom > li:last-child > span {
  border-radius: unset;
}
