.site-landing {
  font-family: $roboto-sans-font !important;
  line-height: 28px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $roboto-sans-font !important;
    font-weight: 500;
  }

  .btn {
    font-size: 16px;
    font-weight: 500;
    box-shadow: none !important;
  }

  .btn-demo {
    font-family: $roboto-sans-font !important;
  }

  .btn-demo-light {
    background: #1a73e8;
    border-radius: 8px;
    border: none;
    color: #fff !important;
    padding: 10px 15px;
    font-size: 16px !important;
    font-weight: bold !important;
  }

  .btn-demo-light-menu {
    background: #faab18;
    border-radius: 8px;
    border: none;
    color: #fff !important;
    padding: 10px 15px;
    font-size: 16px !important;
    font-weight: bold !important;
  }

  .btn-request-demo {
    background: #ffffff;
    border-radius: 8px;
    border: none;
    color: #2692b2 !important;
    padding: 10px 15px;
    font-size: 16px !important;
    font-weight: bold !important;
  }

  .btn-about-us {
    border: 2px solid #ffffff;
    border-radius: 8px;
    color: #fff !important;
    padding: 10px 15px;
    font-size: 16px !important;
    font-weight: bold !important;
  }

  .btn-demo-light,
  .btn-landing-yellow {
    font-family: $roboto-sans-font !important;
  }

  .list {
    list-style: none;
    padding-top: 10px;

    li {
      position: relative;
      font-size: 14px;
      color: #202124;
      padding-bottom: 11px;

      &::before {
        content: "✓";
        position: absolute;
        left: -24px;
        @include border-radius(50px);
      }
    }
  }

  .title {
    font-size: 32px !important;
    color: #202124;
    font-weight: bold;
  }

  .description {
    color: #5f6368;
    font-size: 16px !important;
    line-height: 26px;
    text-align: justify;
  }

  .site-section-highlight {
    display: inline-block;
    background: $landing-yellow;
    padding: 4px 12px;
    text-align: center;
    color: $white;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 24px;
  }

  // header
  .navbar {
    a {
      color: #202124;
      text-decoration: none;
      font-size: 18px;
    }

    .dropdown-menu {
      padding: 0.2rem 0;

      a {
        color: #8d94a0;
        line-height: 30px;
      }

      .dropdown-item {
        padding-inline: 16px;

        &:hover,
        &:active,
        &:focus {
          background-color: #f8f9fa;
        }
      }
    }

    .nav-link.active {
      color: #202124;
      font-weight: bold;
    }

    @media (min-width: 1300px) {
      .nav-link {
        padding: 0.5rem 1.5rem;
      }
    }

    &.sticky-top {
      @include box-shadow(0px 4px 4px #e0e0e040);
    }
  }

  // banner
  .landing-banner {
    position: relative;

    &.home-banner {
      background-color: #2193b0;
      background-image: linear-gradient(to right, #2193b0, #6dd5ed);
      padding: 75px;

      h1 {
        font-size: 43px;
        color: #ffffff;
        font-weight: 700;
      }

      p {
        font-size: 18px;
        color: #ffffff;
      }
    }

    img {
      width: 100%;
      margin: 0px auto;
    }

    .banner-content {
      padding: 75px 65px;
      position: absolute;
      top: 50px;

      h2,
      .newHeading {
        font-size: 43px;
        color: #ffffff;
        font-weight: 700;
      }
    }

    &::before {
      height: 190px;
      width: 35%;
      z-index: -1;
      left: 0;
      top: -210px;
      background-size: contain;
      background-position: left;
      background-repeat: no-repeat;

      // background-image: url("../../images/site-images/logo-bg.png");
      @media (max-width: 1023px) {
        height: 220px;
        width: 32%;
      }

      @media (min-width: 1024px) and (max-width: 1179px) {
        height: 220px;
        width: 22%;
      }

      @media (min-width: 1400px) {
        height: 230px;
      }

      @media (min-width: 1600px) {
        height: 260px;
      }
    }

    .banner-opts {
      .col-md-3:nth-child(odd) {
        margin-top: 30px;
      }

      .banner-card {
        position: relative;
        background: $white;
        text-align: center;
        padding: 30px 16px;
        @include box-shadow(0px 5px 25px rgba(0, 0, 0, 0.1));
        @include border-radius(5px);

        .card-number {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 46px;
          color: $white;
          background: #3c4858;
          padding: 8px;
          font-size: $size18;
          font-weight: $font-weight-bold;
          @include border-radius(0px 0px 5px 0px);
        }

        &:hover {
          transform: translateY(-10px);
          transition: 0.4s;
        }
      }
    }

    &.banner-slider::after {
      width: 34% !important;
      top: -180px !important;
    }
  }

  // about-block
  // .core-capability {
  //     position: relative;
  //     &::before {
  //         content: '';
  //         background-image: url("../../images/site-images/core-capability.png");
  //         position: absolute;
  //         float: left;
  //         left: 0px;
  //         top: 0px;
  //         width: 50%;
  //         height: 100%;
  //         background-repeat: no-repeat;
  //         background-position: top right;
  //         background-size: cover;
  //     }
  // }

  .site-about-block {
    padding: 0px 75px;

    .img-bg {
      position: relative;
      width: 48vw;

      &::before {
        content: "";
        position: absolute;
        right: -20px;
        top: 0;
        background-image: url("../../images/site-images/product-bg.svg");
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right;
        transform: rotate(0deg);
      }

      &.reverse {
        left: -100px;

        &::before {
          left: -20px;
          transform: rotate(180deg);
        }
      }

      img {
        z-index: 1;
        position: relative;
        padding: 20px 0;
      }
    }

    .font-sm {
      font-size: 18px;
    }

    .about-feat {
      background: #f5f7ff;
    }
  }

  .about-procurement {
    background: #051b34;
    color: #fff;
    padding: 100px;

    .title {
      font-size: 28px !important;
      color: #fff;
      font-weight: 400;
    }

    p {
      color: #fff;
    }

    h3 {
      font-weight: 400 !important;
    }
  }

  .desktop-version {
    display: block;
  }

  .mobile-version {
    display: none;
  }

  .core-values-tab {
    background: #f5f7ff;
    padding: 100px;

    .desktop-version {
      display: block;
    }

    .mobile-version {
      display: none;
    }

    h3 {
      color: #198cff;
      font-weight: 400;
    }

    p {
      font-size: 16px;
      font-weight: 400;
    }

    .customer-satisfaction {
      position: relative;
      left: 354px;
    }

    .team-work {
      position: absolute;
      text-align: end;
      margin-top: -400px;
      left: 5px;
    }

    .innovation {
      position: absolute;
      text-align: left;
      margin-top: -400px;
      right: 5px;
    }

    .respect {
      position: absolute;
      text-align: end;
      margin-top: -215px;
      left: 5px;
    }

    .accountability {
      position: absolute;
      text-align: left;
      margin-top: -215px;
      right: 5px;
    }
  }

  // services
  .services-block {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 375px;
      background-size: cover;
      height: calc(100% + 50px);
      right: 0;
      top: 0;
      background-image: url("../../images/site-images/services-bg.png");
      background-repeat: no-repeat;
    }
  }

  // blog
  .blog-block {
    .blog-card {
      margin-bottom: 25px;

      .blog-details {
        background: #f8f9fa;
        padding: 20px 16px;
        position: relative;
        z-index: 1;
        border-radius: 0px 0px 10px 10px;
        height: 300px;

        .description {
          padding-bottom: 24px;
          color: #727272;
          font-size: 16px !important;
          border-bottom: 1px solid #bbbbbb;
        }

        h3 {
          font-weight: 400;
          font-size: 20px;
          color: #202124;
          line-height: 30px;
        }

        .date {
          color: $landing-yellow;
        }

        .blog-read {
          padding: 10px;
        }

        h2 {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
  }

  // contact
  .contact-block {
    background: #2a4d69;
    padding: 115px;

    h2 {
      color: $white;
    }

    p {
      font-size: 16px;
      color: #ffffff87;
      margin-bottom: 5px;
    }

    h3 {
      margin-top: 10px;
      font-weight: 500;
      font-size: 16px;
      color: $white;
      text-transform: uppercase;
      font-weight: 700;
    }

    .form-group {
      position: relative;

      input {
        &:valid + .placeholder {
          display: none;
        }

        + .placeholder {
          position: absolute;
          pointer-events: none;
          top: 0;
          bottom: 0;
          padding: 10px;
          color: $gray-1000;
          font-size: $size14;

          &::after {
            content: "*";
            position: absolute;
            right: 0;
            color: $red;
          }
        }
      }
    }
    a.social-link {
      color: $white;
      margin-right: 14px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .free-trail-block {
    background: $yellow;
    padding: 40px;
    display: block;
    @include box-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
    @include border-radius(30px);
  }

  // footer
  .footer-block {
    height: 60px;
    background: #2a4d69;
    color: #ffffff87;
    padding: 20px 0;
    font-size: $size14;
    border-top: 1px solid #ffffff40;
    font-family: $roboto-sans-font !important;
  }

  // pricing
  .pricing-card {
    background: $white;
    padding: 0px;
    text-align: center;
    height: 100%;
    display: grid;
    align-items: center;
    @include box-shadow(0px 5px 25px rgba(0, 0, 0, 0.1));
    @include border-radius(8px);

    .price-head {
      background: #0384ce;
      border-radius: 8px 8px 0px 0px;
      padding: 15px;
      color: #fff;
    }

    h1 {
      font-size: 3.5rem !important;
    }

    .talk {
      font-size: 2rem !important;
    }

    &.highlight {
      background: $landing-yellow;
      color: $white;

      .list {
        list-style: none;

        li {
          position: relative;
          font-size: 1.1rem;
          color: #030303 !important;

          &::before {
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            left: -24px;
            top: 6px;
            background: #030303 !important;
            @include border-radius(50px);
          }
        }
      }
    }
  }

  .owl-dots {
    text-align: center;

    .owl-dot {
      height: 12px;
      width: 12px;
      opacity: 0.3;
      border: 2px solid $lightblack;
      margin-right: 8px;
      @include border-radius(50%);

      &:last-child {
        margin-right: 0;
      }

      &.active {
        background-color: $lightblack;
        opacity: 0.8;
      }
    }
  }

  b,
  strong {
    font-weight: 500;
  }

  p {
    color: #202124;
  }

  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
}

.testimonial-slider {
  .owl-carousel {
    .description-block {
      margin-top: 30px;
      margin-bottom: 20px;
      padding: 30px 40px;
      border: 2px dashed $landing-yellow;
    }

    .owl-nav {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px;

      .owl-prev,
      .owl-next {
        display: flex;
        margin: 0 8px 0 8px;
        padding: 4px 18px !important;
        border: 2px dashed $landing-yellow;

        span {
          content: url("../../images/site-images/arrow-right.svg");
          background-size: cover;
          width: 36px;
          height: 36px;
          background-repeat: no-repeat;
        }

        &.disabled {
          opacity: 0.5;
        }
      }

      .owl-prev {
        transform: rotate(180deg);
      }
    }
  }
}

.contact-img {
  max-width: 87%;
  height: 24%;
}

.container {
  max-width: 1200px;
}

ul.footer-site-menu {
  list-style-type: none;
  padding-left: 5px !important;

  li a {
    font-size: 16px !important;
    color: #ffffff87;
    line-height: 34px;
  }
}

.fa {
  padding: 10px;
  font-size: 24px;
  width: 44px;
  text-align: center;
  text-decoration: none;
  border-radius: 50px;
}

.fa:hover {
  color: #fff;
}

.fa-facebook-square {
  color: white;
}

.fa-twitter {
  color: white;
}

.fa-google {
  color: white;
}

.fa-linkedin {
  color: white;
}

.fa-youtube-play {
  color: white;
  margin-left: -8px;
}

.fa-instagram {
  color: white;
}

.fa-skype {
  color: white;
}

.site-landing {
  .pricing {
    &.landing-banner {
      &.banner-slider::after {
        top: -370px !important;
      }
    }
  }
}

.site-landing {
  .about-us {
    &.landing-banner {
      .inner-banner {
        background: #f8f9fa;
        padding: 30px;

        h2,
        .innerHeading {
          font-size: 45px;
          font-weight: 600;
          color: #1c2045;
          text-transform: uppercase;
        }
      }

      .blog-banner {
        img {
          width: 100%;
        }
      }
    }
  }
}

.blog-right-view {
  h2 {
    color: #1a73e8;
    font-size: 26px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .blog-details {
    background: #f8f9fa;
    border-radius: 0px 0px 10px 10px;
    padding: 10px;

    h3 {
      font-weight: 400;
      font-size: 17px;
      color: #202124;
      line-height: 30px;
      cursor: pointer;
    }
  }

  .blog-subscribe-card {
    background: #1a73e8;
    padding: 30px 25px;

    h3 {
      color: #fff;
      font-weight: 300;
      font-size: 26px;
    }

    p {
      color: #fff;
      font-size: 14px;
      font-weight: 300;
      margin-bottom: 0px;
    }

    input {
      padding: 5px;
      background: #1a73e8;
      border: 2px solid #fff;
      width: 100%;
    }

    ::placeholder {
      color: #fff;
      font-weight: 300;
    }

    button {
      background: #fff;
      border: none;
      padding: 7px;
      font-size: 12px;
      font-weight: bold;
      color: #1a73e8;
    }
  }
}

.blog-content-view {
  h2,
  .blogHeading {
    font-weight: 400;
    font-size: 34px;
    color: #202124;
    line-height: 45px;
  }

  p {
    text-align: justify;
    font-size: 16px;
  }

  h3 {
    font-weight: 500;
    font-size: 17px;
    color: #202124;
    line-height: 30px;
  }
}

.product-opts {
  .banner-card {
    background: $white;
    text-align: center;
    padding: 30px 16px;
    border: 1px solid $lightblack;
    // @include box-shadow(0px 5px 25px rgba(0, 0, 0, 0.1));
    @include border-radius(5px);
    margin-bottom: 115px;
    height: 300px;

    .icon-i {
      margin-top: -75px;
      margin-bottom: 50px;

      .icon {
        border-radius: 50%;
        border: 2px solid;
        color: #133563 !important;
        background-color: #edc914;
        padding: 30px;

        &-company-details:before {
          color: #133563 !important;
        }
      }
    }

    .btn {
      position: absolute;
      left: 95px;
      bottom: 150px;
    }
  }
}

.services-card {
  border: 3px solid #f9c935;
  border-radius: 12px;
  padding: 15px;

  h1 {
    font-size: 4rem;
  }

  h3 {
    font-size: 2.5rem;
    font-weight: 100 !important;
  }
}

.banner-description {
  font-size: 17px;
  color: #030303;
}

/*Responsive CSS */
@media only screen and (max-width: 768px) {
  .col-6,
  .col-5 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .site-landing .landing-banner .banner-opts .col-md-3 {
    margin-bottom: 20px;
  }

  .site-landing .landing-banner .banner-opts .col-md-3:nth-child(odd) {
    margin-top: 0px;
  }

  .site-about-block {
    padding-left: 10px;
    padding: 0px 30px !important;
  }

  .trail-section .sidebar-block {
    display: none !important;
  }

  .trail-section .right-block {
    width: 100% !important;
    padding: 30px !important;
  }

  // .main-screen {
  //   display: none !important;
  // }
  .mobile-screen {
    display: block;
    width: 100%;
  }

  .navbar-brand {
    width: 200px;
  }

  .site-landing {
    .contact-block {
      padding: 45px;
    }

    .title {
      font-size: 22px !important;
      text-align: center;
    }

    .description {
      text-align: inherit !important;
    }
  }

  .right-choice {
    .right-choice-xt {
      padding: 30px 5px !important;
    }
  }

  .banner-content {
    padding: 0px 10px !important;

    h2,
    .newHeading {
      font-size: 20px !important;
    }
  }

  .site-landing .about-us.landing-banner .inner-banner h2,
  .site-landing .about-us.landing-banner .inner-banner .innerHeading {
    font-size: 25px !important;
  }

  .features-block .title {
    font-size: 25px !important;
  }

  .site-landing .navbar a {
    margin-left: 0px;
  }

  .blog-content-view .blogHeading {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 15px;
  }

  .supplier-arrow {
    display: none;
  }

  .privacy-policy {
    p {
      text-align: inherit !important;
    }
  }

  .inner-about-banner {
    .inner-pr-content {
      left: 6px !important;

      .innerHeading {
        font-size: 24px !important;
      }
    }
  }

  .inner-pr-banner {
    .inner-pr-content {
      margin-top: -245px !important;
      left: 0px !important;

      .innerHeading {
        font-size: 40px !important;
      }
    }
  }

  .core-values-tab {
    padding: 50px !important;

    .desktop-version {
      display: none !important;
    }

    .mobile-version {
      display: block !important;
    }
  }

  .desktop-version {
    display: none !important;
  }

  .mobile-version {
    display: block !important;
  }

  .inner-page img {
    height: 250px !important;
  }

  .analytics-card2,
  .analytics-card4 {
    margin-top: 0px !important;
  }
}

.site-landing {
  .navbar {
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    position: sticky;
    top: 0;
    z-index: 20;
  }

  .nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1500px;
  }

  .main-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .nav-logo {
    color: #f5b921;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    flex-grow: 1;
  }

  .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    margin-right: 2rem;
  }

  .nav-links {
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
  }

  .fa-code {
    margin-left: 1rem;
  }

  // .nav-item {
  //   // line-height: 40px;
  //   // margin-right: 1rem;
  // }

  .nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
  }

  .nav-item:hover:after {
    width: 100%;
    background: #1a3765;
  }

  .nav-item.active {
    color: #1a3765;
  }

  .nav-icon {
    display: none;
  }

  @media screen and (max-width: 960px) {
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-top: 1pxsolid #fff;
      position: absolute;
      top: 80px;
      left: -110%;
      opacity: 1;
      transition: all 0.5s ease;
    }

    .nav-menu.active {
      text-align: left;
      background: #fff;
      left: 0px;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }

    .nav-item .active {
      color: #1a3765;
      border: none;
    }

    .nav-links {
      padding: 1.5rem;
      width: 100%;
      display: table;
    }

    .nav-icon {
      display: block;
      position: absolute;
      top: -10px;
      right: -40px;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
      color: #1a3765;
    }

    .navbar a {
      font-size: 18px;
    }
  }
}

.right-choice {
  // background-image: url("../../images/footer-bg-all.png");
  background: #f8f9fa;
  background-size: 100%;

  .right-choice-xt {
    padding: 50px;
  }

  h2 {
    font-size: 38px;
    margin-bottom: 50px;
  }
}

.features-block {
  padding: 50px;
  background-image: url("../../images/site-images/features-bg.png");
  background-size: cover;

  .title {
    color: #202124;
    font-size: 35px;
    margin-bottom: 20px;
  }

  .description {
    color: #5f6368;
    font-size: 16px !important;
    line-height: 23px;
    text-align: center;
  }

  h3 {
    color: #202124;
    font-weight: 700;
    text-transform: uppercase;
  }

  p {
    color: #8a8e96;
  }
}

.spend-management {
  background: #f3f6fa;
  padding: 75px;

  .title {
    font-size: 28px;
  }

  .description {
    font-size: 16px !important;
  }
}

.plan-includes {
  h1 {
    font-weight: 600;
    color: #202124;
  }

  h3 {
    color: #202124;
    font-weight: 600;
  }

  h5 {
    color: #5f6368;
  }

  .content::before {
    position: absolute;
    left: 20px;
    content: "✓";
    font-size: 26px;
    color: #f69522;
  }
}

.planHeading {
  font-weight: 600;
  color: #202124;
  font-size: 2.25rem;
}

.trail-section {
  .sidebar-block {
    line-height: 16px;

    h1 {
      color: #202124;
      font-weight: 400;
    }

    h2 {
      font-size: 20px;
      color: #202124;
    }

    p {
      color: #5f6368;
    }
  }
}

.solutions-sidebar {
  background: #f5f4f9;
  border-radius: 8px;
  padding: 10px;

  ul {
    list-style-type: none;
    font-size: 18px;

    li {
      padding: 10px;

      a {
        font-size: 18px;
        color: #8d94a0;
      }

      &.active {
        background: #fff;
        border-radius: 6px;

        a {
          color: $lightblack;
          font-weight: 500;
        }

        &::after {
          content: "\2192";
          position: absolute;
          right: 45px;
          font-size: 28px;
        }
      }
    }
  }
}

.solutions-content {
  .title {
    font-size: 32px !important;
    color: #202124;
    font-weight: 400;
  }

  h3 {
    color: #58a2ff;
    font-weight: 400;
  }

  p {
    font-size: 18px;
  }

  .cont-2-sol::before {
    content: "";
    border: 2px solid #1a73e8;
    width: 2px;
    height: 65px;
    position: absolute;
  }
}

.e-invoice-list {
  list-style-type: none;

  li {
    color: #6d6e71;
    font-size: 18px;
    line-height: 30px;

    &::before {
      content: "\2022";
      color: #58a2ff;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
      font-size: 28px;
      position: absolute;
    }
  }
}

.blog-pagination {
  a {
    padding: 10px;
    border: 1px solid #1a73e8;
    border-radius: 8px;
    margin: 5px;
    color: #202124;
    font-weight: 400;
  }
}

.follow-blog {
  background: #f8f9fa;
  padding: 100px;

  h2 {
    color: #202124;
    font-size: 32px;
    font-weight: 400;
  }

  h4 {
    color: #5f6368;
    font-size: 18px;
    font-weight: 300;
  }

  input {
    padding: 5px;
    background: #efefef;
    border: 2px solid #efefef;
    height: 50px;
  }

  ::placeholder {
    color: #5f6368;
    font-weight: 300;
  }

  button {
    background: #1a73e8;
    border: none;
    padding: 7px;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    height: 50px;
  }
}

.solutions-block {
  background: #f8f9fa;
  padding: 75px;
  color: #202124;

  h2 {
    font-weight: 600;
    font-size: 19px;
    color: #202124;
  }
}

.privacy-policy {
  p {
    margin-bottom: 5px;
    font-size: 16px !important;
    text-align: justify;
  }

  ul {
    li {
      font-size: 16px !important;
    }
  }

  h3 {
    font-size: 16px;
  }
}

.inner-page {
  img {
    height: 500px;
  }

  .innerHeading {
    font-size: 48px;
    color: #fff;
    padding: 45px 0px;
  }
}

.invoice-features-block {
  padding: 50px;
  background: #f8f9fa;

  h4 {
    font-size: 18px;
  }

  ul {
    li {
      font-size: 18px;
      color: #202124;
    }
  }

  .title {
    color: #202124;
    font-size: 35px;
    margin-bottom: 20px;
  }

  .description {
    color: #202124;
    font-size: 16px !important;
    line-height: 23px;
    text-align: center;
  }

  h3 {
    color: #202124;
    font-weight: 700;
    text-transform: uppercase;
  }

  p {
    color: #8a8e96;
  }
}

.sol-block {
  font-size: 18px;

  p {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .sol1 {
    background: #ed5d2e;
    padding: 8px;
    @include border-radius(4px);
    height: 50px;
  }

  .sol2 {
    background: #f95758;
    padding: 8px;
    @include border-radius(4px);
    height: 50px;
  }

  .sol3 {
    background: #5569d7;
    padding: 8px;
    @include border-radius(4px);
    height: 50px;
  }

  .sol4 {
    background: #1692ed;
    padding: 8px;
    @include border-radius(4px);
    height: 50px;
  }

  .sol5 {
    background: #3cd089;
    padding: 8px;
    @include border-radius(4px);
    height: 50px;
  }

  .sol6 {
    background: #3cd089;
    padding: 8px;
    @include border-radius(4px);
    height: 50px;
  }

  .sol7 {
    background: #3cc7d0;
    padding: 8px;
    @include border-radius(4px);
    height: 50px;
  }

  .sol8 {
    background: #3cc7d0;
    padding: 8px;
    @include border-radius(4px);
    height: 50px;
  }

  h2 {
    color: #202124;
    font-size: 20px;
    font-weight: 600;
  }
}

.our-benfits-block {
  background-color: #f8f9fa;
  padding: 25px;

  .benfit {
    background: #fff;
    padding: 15px 10px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
    @include border-radius(8px);

    p {
      margin-bottom: 0px;
      font-size: 18px;
      line-height: 28px;
      color: #202124;
    }
  }
}

.Collapsible {
  background-color: #ccc;
}

//The content within the collaspable area
.Collapsible__contentInner {
  padding: 25px;
  border: 1px solid #d5dce2;
  border-top: 0;
  background: #f8f9fa;

  p {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 28px;
    color: #202124;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    li {
      font-size: 18px;
      color: #202124;
      line-height: 28px;
    }
  }
}

//The link which when clicked opens the collapsable area
.Collapsible__trigger {
  display: block;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  border: 1px solid #d5dce2;
  padding: 10px 25px;
  background: #f8f9fa;
  color: #202124;
  font-size: 18px;

  &:after {
    font-family: "FontAwesome";
    content: "\f107";
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms;
  }

  &.is-open {
    &:after {
      transform: rotateZ(180deg);
    }
  }

  &.is-disabled {
    opacity: 0.5;
    background-color: grey;
  }
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #cbb700;
  color: black;
}

.analytics {
  padding: 50px 75px;

  .analytics-circle {
    border: 1px solid rgba(25, 140, 255, 1);
    border-radius: 50%;
    padding: 10px;
    background: #f8f9fa;
    width: 75px;
    height: 75px;
    margin-left: 83px;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    color: #202124;
  }

  .supplier-arrow {
    position: relative;
    left: 125px;
    bottom: 105px;
  }
}

.spend-analytics-flow {
  background-color: #f8f9fa;
  padding: 50px 10px;

  .analytics-card {
    text-align: center;
    padding: 30px 16px;
    border: 2px solid rgba(25, 140, 255, 1);
    border-radius: 5px;
    height: 200px;

    .analytics-img {
      border-radius: 50%;
      border: 2px solid rgba(25, 140, 255, 1);
      background-color: #f8f9fa;
      padding: 25px;
      margin-left: 90px;
      margin-top: -75px;
      width: 100px;
      height: 100px;
    }
  }

  .analytics-card1 {
    background-image: url("../../images/site-images/circ1.png");
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 50px;
    width: 330px;
    height: 335px;

    img {
      position: relative;
      left: 85px;
    }

    .analytics-text {
      text-align: center;
      padding: 10px;
      margin-top: 10px;
    }
  }

  .analytics-card2 {
    background-image: url("../../images/site-images/circ2.png");
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 50px;
    width: 330px;
    margin-top: 100px;
    height: 335px;
    margin-left: -40px;

    img {
      position: relative;
      left: 85px;
    }

    .analytics-text {
      text-align: center;
      padding: 10px;
      margin-top: 10px;
    }
  }

  .analytics-card3 {
    background-image: url("../../images/site-images/circ3.png");
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 50px;
    width: 330px;
    height: 335px;
    margin-left: -40px;

    img {
      position: relative;
      left: 85px;
    }

    .analytics-text {
      text-align: center;
      padding: 10px;
      margin-top: 10px;
    }
  }

  .analytics-card4 {
    background-image: url("../../images/site-images/circ4.png");
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 50px;
    width: 330px;
    margin-top: 100px;
    height: 355px;
    margin-left: -40px;

    img {
      position: relative;
      left: 94px;
    }

    .analytics-text {
      text-align: center;
      padding: 10px;
      margin-top: 10px;
    }
  }

  .description {
    color: #5f6368;
    font-size: 15px !important;
    line-height: 21px;
    text-align: center;
  }
}

.inner-pr-banner {
  .inner-pr-content {
    position: absolute;
    color: #fff;
    margin-top: -200px;
    left: 75px;
  }

  .innerHeading {
    font-size: 48px;
  }
}

.inner-about-banner {
  .inner-pr-content {
    position: absolute;
    color: #fff;
    margin-top: -225px;
    left: 50px;

    .btn.btn-outline-primary {
      color: #fff;
      border-color: #fff;
    }
  }

  .innerHeading {
    font-size: 48px;
  }
}

.contract-title {
  font-size: 34px;
  color: #202124;
  font-weight: 400 !important;
}

.contract-card-flow {
  border-bottom: 1px solid #c6ccd1;
  padding-bottom: 75px;
}

.spend-arrow {
  position: relative;
  left: 317px;
  top: -151px;
  background: #f8f9fa;
  width: 50px;
  z-index: 9;
}

.contact-new-design {
  h3 {
    font-size: 24px;
    color: #202124;
    font-weight: 400 !important;
    margin-bottom: 20px;
  }

  h2 {
    text-transform: uppercase;
  }

  .form-group-radio {
    font-size: 18px;
    color: #202124;
    line-height: 30px;
    padding-left: 25px;

    .checkmark {
      top: 7px;
    }
  }

  .form-block p {
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 14px;
  }
}

.book-demo-sec {
  background: #f5f7ff;
  padding: 100px 50px;

  .book-demo-1 {
    background-image: url("../../images/site-images/book-a-demo-1.png");
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 15px 10px 25px 140px;
    height: 145px;

    p {
      font-size: 16px;
    }
  }

  .book-demo-2 {
    background-image: url("../../images/site-images/book-a-demo-2.png");
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 15px 10px 25px 140px;
    height: 145px;

    p {
      font-size: 16px;
    }
  }

  .book-demo-3 {
    background-image: url("../../images/site-images/book-a-demo-3.png");
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 15px 10px 25px 140px;
    height: 145px;

    p {
      font-size: 16px;
    }
  }

  .book-demo-4 {
    background-image: url("../../images/site-images/book-a-demo-4.png");
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 15px 10px 25px 140px;
    height: 145px;

    p {
      font-size: 16px;
    }
  }
}

.abt-sol {
  h3 {
    font-size: 18px;
  }

  p {
    font-size: 16px;
  }
}

.client-logos {
  padding: 50px;
  border-radius: 8px;
  @include box-shadow(0px 4px 4px #e0e0e040);

  .marquee {
    width: 500px;
    margin: 0 auto;
    overflow: hidden;
    white-space: nowrap;
  }

  .marquee div {
    display: inline-block;
    font-size: 20px;
    position: relative;
    // left: 100%;
    // animation: marquee 10s linear infinite;
  }

  .marquee--inner {
    width: 200%;
    position: absolute;
    animation: marquee 15s linear infinite;

    &:hover {
      animation-play-state: paused;
    }
  }

  // .marquee div:nth-child(1) {
  //   animation-delay: 0s;
  // }

  // .marquee div:nth-child(2) {
  //   animation-delay: 3.5s;
  // }

  // .marquee div:nth-child(3) {
  //   animation-delay: 7.5s;
  // }

  // .marquee div:nth-child(4) {
  //   animation-delay: 12.5s;
  // }

  @keyframes marquee {
    0% {
      transform: translateX(50%);
    }

    100% {
      transform: translatex(-210%);
    }
  }
}

.client-img {
  margin-right: 300px;
}

.quote-txt {
  padding: 20px;
  border: 2px solid #000;
  margin-bottom: 10px;
}

.quote-txt::before {
  content: "\201C";
  color: #000;
  font-size: 60px;
  position: absolute;
  left: 5px;
  margin-top: -27px;
  background: #fff;
}

.quote-txt::after {
  content: "\201D";
  color: #000;
  font-size: 60px;
  position: absolute;
  right: -2px;
  margin-top: 30px !important;
  background: #fff;
  padding: 20px 10px;
}
