.landing_section {
    display: flex;
    align-items: center;
    // background: url("./../../images/signin.png");
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;

    .landing_sidebar {
        width: 40%;
        height: 100vh;
        display: flex;
        align-items: center;
        background: #F8F9FA;

        // background-color: $yellow;
        .logo-block {
            display: flex;
            align-items: center;
            position: absolute;
            top: 18px;
            left: 24px;
        }

        .owl-carousel {
            text-align: center;
            // right: 100px;

            .item {
                max-width: 50%;
                margin: 0 auto;

                &-img {
                    max-width: 300px;
                    margin: 0 auto;
                    margin-bottom: 32px;
                }

                &-title {
                    color: $black;
                    margin-bottom: 12px;
                }

                &-content {
                    color: $lightblack;
                    margin-bottom: 16px;
                }
            }

            .owl-dots {
                .owl-dot {
                    height: 8px;
                    width: 8px;
                    background-color: $lightblack;
                    opacity: 0.3;
                    margin-right: 5px;
                    @include border-radius(50%);

                    &:last-child {
                        margin-right: 0;
                    }

                    &.active {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .right-content {
        // position: relative;
        // padding: 28px 0;
        // width: 60%;
        // height: 100vh;
        display: flex;
        align-items: center;
        padding: 30px;
        width: 60%;

        // margin-top: -80px;
        .top-content {
            position: absolute;
            top: 15px;
            right: 32px;
            font-size: $size14;
        }

        .form-block {
            max-width: 400px;
            width: 100%;
            margin: 0 auto;

            // max-height: calc(100vh - 20vh);
            .header-text {
                position: relative;
                font-weight: $font-weight-bold;
                margin-bottom: 30px;
                padding: 10px 0;

                @media screen and (min-width: 1366px) {
                    padding: 10px 0;
                }

                &::after {
                    content: "";
                    position: absolute;
                    width: 25px;
                    left: 0;
                    bottom: -5px;
                    height: 4px;
                    background-color: $primary;
                    @include border-radius(10px);
                }
            }

            .content-block {
                // max-height: calc(100vh - 40vh);
                padding: 15px 10px 10px 5px;
                overflow: hidden;
            }

            &.block-lg {
                max-width: 100%;

                .content-block,
                .header-text {
                    padding: 10px 0%;

                    @media screen and (min-width: 1366px) {
                        padding: 10px 0%;
                    }
                }

                .header-text::after {
                    left: 0%;

                    @media screen and (min-width: 1366px) {
                        left: 0%;
                    }
                }
            }

            .app-text {
                font-size: $size14;
                color: $gray-trout;
            }
        }

        .grecaptcha-badge {
            visibility: visible !important;
        }
    }
}

.trail-section {
    display: flex;
    align-items: center;

    .sidebar-block {
        width: 40%;
        height: 100vh;
        display: flex;
        align-items: center;
        background-color: #F8F9FA;

        .logo-block {
            display: flex;
            align-items: center;
            position: absolute;
            top: 18px;
            left: 24px;
        }

        .trail-item-wrapper {
            padding: 15px 50px;

            .trail-list {
                padding-left: 0;
                margin-bottom: 0;
                list-style: none;

                li {
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        z-index: 2;
                        left: -20px;
                        top: 4px;
                        width: 8px;
                        height: 16px;
                        border: solid #0384CE;
                        border-width: 0 3px 3px 0;
                        @include border-radius(2px);
                        @include transform(rotate(45deg));
                    }
                }
            }
        }
    }

    .right-block {
        position: relative;
        padding: 28px 0;
        width: 60%;
        height: 100vh;
        display: flex;
        align-items: center;
        overflow-y: auto;
        overflow-x: hidden;

        .top-content {
            position: absolute;
            top: 15px;
            right: 32px;
            font-size: $size14;
        }

        .form-block {
            max-width: 420px;
            width: 100%;
            margin: 0 auto;
            max-height: calc(100vh - 20vh);

            .header-text {
                position: relative;
                font-weight: $font-weight-bold;
                margin-bottom: 30px;
                padding: 10px 0;

                @media screen and (min-width: 1366px) {
                    padding: 10px 0;
                }

                &::after {
                    content: "";
                    position: absolute;
                    width: 25px;
                    left: 0;
                    bottom: -5px;
                    height: 4px;
                    background-color: $primary;
                    @include border-radius(10px);
                }
            }

            .content-block {
                max-height: calc(100vh - 30vh);
                padding: 15px 10px 10px 5px;
                overflow: auto;
            }

            &.block-lg {
                max-width: 100%;

                .content-block,
                .header-text {
                    padding: 10px 20%;

                    @media screen and (min-width: 1366px) {
                        padding: 10px 26%;
                    }
                }

                .header-text::after {
                    left: 20%;

                    @media screen and (min-width: 1366px) {
                        left: 26%;
                    }
                }
            }

            .app-text {
                font-size: $size14;
                color: $gray-trout;
            }
        }
    }
}

.welcome-modal {
    .welcome-screen {
        display: flex;

        .content-blck {
            position: relative;
            padding: 30px;
            background-color: $yellow;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;

            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 60px;
                left: 0;
                bottom: 0;
                background-image: url("../../../assets/images/illustrations/leftsidebar-bg.svg");
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        .list-blck {
            padding: 30px 36px 30px 36px;

            ul {
                list-style: none;
                padding-left: 0;
                margin-bottom: 0;

                li {
                    position: relative;
                    padding-left: 30px;
                    margin-bottom: 24px;
                    font-size: $size14;

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 2px;
                        width: 16px;
                        height: 16px;
                        background-color: $purple;
                        border: 1px solid $purple;
                        @include border-radius(2px);
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        left: 6px;
                        top: 4px;
                        width: 4px;
                        height: 10px;
                        border: solid $white;
                        border-width: 0 2px 2px 0;
                        @include transform(rotate(45deg));
                    }
                }
            }
        }
    }
}

img.landing-logo {
    width: 180px;
}

.loginError-msg {
    color: #ef4141;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 15px;
    margin-top: -30px;
    line-height: 18px;
}

.content-block.custom-scrollbar {
    padding: 15px 10px 10px 5px !important;
}

.accept-text {
    padding-top: 10px;
}

.captcha-block div:first-child {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.captcha-block a {
    font-size: 32px;
}

.copyright,
.terms-service {
    font-size: $size14;
}

.terms-service a {
    padding-left: 5px;
}