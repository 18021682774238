.profile-overview {
    .profile-details {
        display: flex;
        align-items: center;
        img {
            width: 72px;
            height: 72px;
            margin-right: 16px;
            @include border-radius(8px);
        }
        .user-details {
            .label-txt {
                font-size: $size14;
            }
        }
    }
}