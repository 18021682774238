.btn {

  // site
  &-demo {
    font-family: $pt-sans-font !important;
    text-transform: capitalize !important;
    background: #3c4858;
    border: 1px solid #3c4858;
    color: $white;
    font-size: 1.1rem;
    @include border-radius(3px);

    &:hover {
      color: $white;
    }

    box-shadow: none !important;
  }

  &-demo-light {
    font-family: $pt-sans-font !important;
    text-transform: none !important;
    background: transparent;
    border: 1px solid $black;
    color: $black;
    @include border-radius(3px);

    &:hover {
      background: $landing-yellow;
      color: $white;
    }
  }

  &-pure-secondary {
    font-family: $pt-sans-font !important;
    text-transform: none !important;
    background: transparent;
    border: none;
    color: $gray-trout;
    padding: 0;
    font-weight: $font-weight-semibold !important;

    &:hover {
      background: transparent;
      color: $lightblack;
    }
  }

  &-landing-yellow {
    font-family: $pt-sans-font !important;
    text-transform: none !important;
    background: $landing-yellow;
    border: none;
    color: $black;
    @include border-radius(3px);

    &:hover {
      background: $landing-yellow;
      color: $white;
    }
  }

  font-family: $ubuntu-font;
  font-size: $size14;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  @include border-radius(4px);

  // @include box-shadow(0px 3px 7px #0000004d !important);
  &.btn-rounded {
    @include border-radius(20px);
  }

  &.btn-primary {
    background-color: #368BFC !important;
    color: $white !important;
    border-color: #368BFC !important;

    // @include box-shadow(0px 0px 4px rgba(0, 0, 0, 0.8) !important);
    &:active,
    &:focus {
      background-color: #368BFC !important;
      border-color: #368BFC !important;
      // @include box-shadow(0px 0px 4px rgba(0, 0, 0, 0.8) !important);
    }
  }

  &.btn-outline-primary {
    color: #368BFC;
    border-color: #368BFC;

    &:active,
    &:hover {
      border-color: #368BFC !important;
      // background-color: #368BFC !important;
      color: #fff !important;
      @include box-shadow(0px 0px 4px rgba(0, 0, 0, 0.8) !important);

      .icon-Add-Question:before {
        color: #368BFC;
      }
    }
  }

  &.btn-add-new {
    i {
      vertical-align: text-bottom;
    }
  }

  &.btn-primary-light {
    background: #ffeeea;
  }

  &.btn-link {
    text-transform: capitalize;
    font-size: $size12;

    &.verify-user {
      margin-left: -25px;
      padding: 5px;
    }
  }

  &.signin-custom {
    background-color: #fff;
    color: #f37658 !important;
    border: 1px solid #f37658 !important;
  }

  &-secondary {
    background: #fff;
  }
}

.download-spend {
  background-color: #f37658 !important;
  border: 1px solid #f37658 !important;
}

.button-dropdown {
  &-menu {
    top: 0 !important;
    padding: 0 !important;
    min-width: 225px;
    max-width: 260px;
    background: $white;
    border: 1px solid rgba(45, 140, 255, 0.2);
    @include box-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
    @include border-radius(5px);

    .user-name {
      color: $black;
      font-family: $mulish-font !important;
      font-weight: $font-weight-bold;
      padding: 0.25rem 1.5rem;
      margin-bottom: 0;
      font-size: 14px;
    }

    .menu-option {
      color: $black;
      font-size: $size14;
      font-weight: $font-weight-regular;
      padding: 12px 20px !important;
      display: flex;
      align-items: center;

      i.icon {
        &-check-circle {
          color: $green-300;
        }

        &-x-circle {
          color: $red;
        }
      }

      a {
        display: block;
        color: $black;
        @include user-select(none);
      }

      &:hover {
        background-color: $yellow;
        color: $lightblack;
        font-weight: $font-weight-semibold;
      }

      &.active {
        background-color: $yellow;
      }
    }

    .dropdown-divider {
      margin: 0.5rem 15px;
    }
  }

  .btn {
    position: relative;
    min-width: 160px;

    .icon-dropdown-arrow {
      position: absolute;
      top: 10px;
      right: 20px;

      &::before {
        color: $white;
      }
    }
  }
}

.verify-user img {
  filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
}

button.preview-button-toggle {
  background: none;
  border: none;
  box-shadow: none !important;
  margin: 0px;
  padding: 0px;
}

.chart-filter {
  button {
    box-shadow: none !important;
  }
}

.btn.dwnl {
  .icon-po-download:before {
    color: #fff !important;
  }

  margin-top: -12px !important;
}

.btn.cance {
  margin-top: -50px !important;
}

.excel-btn {
  // position: absolute;
  right: 7px;
  top: 4px;
  z-index: 2;
  margin-right: 10px;
}

.add-btn-inv {
  right: 145px !important;
}

.add-btn-inv-lines {
  right: 150px !important;
  // position: absolute;
  top: 4px;
  z-index: 2;
  margin-right: 10px;
}

.table-inside-button {
  background-color: #368BFC !important;
  border: 1px solid #368BFC !important;
  box-shadow: none !important;
  color: #fff;
  font-size: 12px;

  &:hover,
  &:active,
  &:focus {
    color: #fff;
  }
}