.country{
    font-size: $size14;
    color: $gray-1000;
    margin-left: 35px;
    position: relative;

    &:after{
        content: '';
        position: absolute;
        top: 4px;
        width: 1px;
        height: 12px;
        right: -10px;
        background-color: #000000;
        opacity: 0.3;
    }
}
.rating{
    font-size: $size14;
    color: $gray;
    font-weight: $font-weight-bold;
}
.account-status{
    font-size: $size12 !important;
    font-weight: $font-weight-semibold;
    margin-left: 4px;
    margin-right: 22px;
    position: relative;
}
.supplier-subhead{
    border-bottom: 1px solid $gray-700;
    padding: 10px 0;
    font-size: $size14;
    color: $gray-nevada;
}
.supplier-head{
    border-bottom: 1px solid $gray-700;
    padding-bottom: 15px;
    font-size: $size14;
    position: relative;
    .title-text {
        font-weight: $font-weight-semibold;
        font-size: $size14;
        color: $lightblack;
        font-family: $mulish-font;
    }
    .more-option{
        margin-left: 20px;
        &::after{
            top: -5px;
        }
    }
    h5{
        color: $lightblack;
    }
    span{
        font-weight: $font-weight-semibold;
    }
}
.sup-grid-head{
    padding-bottom: 14px;
    border-bottom: 1px solid #EDF3FF;
    span:not(.link){
        font-size: $size12;
        color: $gray;
    }
}
.sup-grid-top{
    .more-option{
        &::after{
            top: -18px;
            right: -10px;
        }
    }
}
.supplier-body{
    font-size: $size14;
    //color: $gray-nevada;
    margin-top: 10px;
    .service-name{
        font-weight: $font-weight-semibold;
    }
}
.supplier-name{
    width: 66%;
    margin-left: 12px;
    h5{
        color: $lightblack;
    }
}