/***
* Common Styling
***/
html {
  max-width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scrollbar-color: $gray-300 $gray-500;
  scrollbar-width: thin;
  height: 100%;
  @media only screen and (max-width: 1024px) {
    font-size: 11px;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 17px;
  }
  &::-webkit-scrollbar-track {
    background-color: $gray-300;
    @include border-radius(10px);
  }
  &::-webkit-scrollbar {
    width: 4px;
    background-color: $gray-300;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $gray-700;
    @include border-radius(10px);
  }
  &::selection {
    color: $white;
    background: $blue;
  }
  &::-moz-selection {
    color: $white;
    background: $blue;
  }
}

body {
  background: #fff;
  color: $lightblack;
  font-family: $mulish-font;
  height: 100%;
  & > * {
    ::-webkit-scrollbar-track {
      background-color: $gray-300;
      @include border-radius(10px);
    }
    ::-webkit-scrollbar {
      width: 4px;
      background-color: $gray-300;
    }
    ::-webkit-scrollbar-thumb {
      background-color: $gray-700;
      @include border-radius(10px);
    }
  }
  & > iframe {
    display: none;
  }
}

a {
  color: $blue;
  &:hover,
  &:focus {
    text-decoration: none !important;
  }
}

p {
  font-size: $size14;
  color: $gray;
}

ul > * {
  font-size: $size14;
}

button,
.dropdown-menu {
  text-decoration: none !important;
  &:focus,
  &:active {
    outline: none;
  }
}

.loader-block {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 45px;
  height: 45px;
  display: inline-block;
  padding: 0px;
  border-radius: 100%;
  border: 5px solid;
  border-top-color: $primary;
  border-bottom-color: #a3aed1;
  border-left-color: $primary;
  border-right-color: #a3aed1;
  @include animation(loader 1s ease-in-out infinite);
}

@keyframes loader {
  from {
    @include transform(rotate(0deg));
  }
  to {
    @include transform(rotate(360deg));
  }
}

.custom-scrollbar {
  &::-webkit-scrollbar-track {
    background-color: $gray-300;
    @include border-radius(10px);
  }
  &::-webkit-scrollbar {
    width: 8px;
    background-color: $gray-300;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $gray-700;
    @include border-radius(10px);
  }
}

.icon10 {
  font-size: $size10;
}

.icon12 {
  font-size: $size12;
}

.icon14 {
  font-size: $size14;
}

.icon16 {
  font-size: $base-font-size;
}

.icon18 {
  font-size: $size18;
}

.icon20 {
  font-size: $size20;
}

.icon22 {
  font-size: $size22;
}

.icon24 {
  font-size: $size24;
}

.icon32 {
  font-size: $size32;
}

.icon40 {
  font-size: $size40;
}

.icon56 {
  font-size: 56px;
}

.icon80 {
  font-size: 4rem;
}

.custom-tooltip {
  .tooltip-inner {
    max-width: 360px;
    font-size: $size12;
    background-color: $white;
    color: $gray-1000;
    border: 1px solid $gray-300;
    padding: 10px 20px;
    text-align: left;
    @include box-shadow(0px 2px 2px rgba(6, 17, 39, 0.1));
    @include border-radius(8px);
  }
  &[x-placement^="top"] {
    .arrow {
      &::before {
        border-top-color: $gray-300 !important;
      }
    }
  }
  &[x-placement^="right"] {
    .arrow {
      &::before {
        border-right-color: $gray-300 !important;
      }
    }
  }
  &[x-placement^="bottom"] {
    .arrow {
      &::before {
        border-bottom-color: $gray-300 !important;
      }
    }
  }
  &[x-placement^="left"] {
    .arrow {
      &::before {
        border-left-color: $gray-300 !important;
      }
    }
  }
}

.Toastify__toast-container--top-right {
  top: 4em !important;
}

.custom-toast {
  width: 350px;
  &.size-lg {
    width: 720px;
  }
  &.Toastify__toast {
    min-height: 36px;
    padding: 8px 18px 8px 36px;
    margin-bottom: 0;
    @include border-radius(8px);
    .Toastify__toast-body {
      font-family: $mulish-font;
      font-size: $size12;
      font-weight: $font-weight-bold;
    }
    &-container--top-right {
      top: 60px !important;
    }
    &--dark {
      background-color: $lightblack;
      &::before {
        content: "";
        position: absolute;
        z-index: 2;
        left: 16px;
        top: 12px;
        width: 8px;
        height: 16px;
        border: solid $white;
        border-width: 0 3px 3px 0;
        @include transform(rotate(45deg));
      }
    }
    &--error {
      &::before {
        content: "\0021";
        position: absolute;
        z-index: 2;
        left: 22px;
        top: 8px;
        font-size: 1.4rem;
      }
    }
    .Toastify__close-button {
      display: none;
    }
  }
}

.arrow-down-solid {
  content: "";
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid $lightblack;
  transform: rotate(90deg);
  cursor: pointer;
}

.arrow-down {
  border: solid #454a58;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  cursor: pointer;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.more-option {
  position: relative;
  &::after {
    content: "";
    background-image: url("./../../images/illustrations/more-option.svg");
    background-position: center;
    height: 30px;
    width: 30px;
    cursor: pointer;
    position: absolute;
    top: -10px;
    right: 0;
    background-repeat: no-repeat;
  }
  &:hover::after {
    background-color: #eeeeee;
    @include border-radius(50%);
  }
}

.collapsedSidebar {
  .header-block {
    left: 68px;
    width: calc(100% - 68px);
    @media only screen and (max-width: 1024px) {
      padding: 8px 20px;
    }
  }
  .block-pads,
  .dashboard-pads {
    padding-left: 100px;
    @media only screen and (min-width: 1600px) {
      padding-left: 100px;
    }
  }
  .fixed-footer,
  .footer-block {
    left: 84px;
    width: calc(100% - 100px);
    margin: 0;
    @media only screen and (min-width: 1600px) {
      left: 120px;
      width: calc(100% - 180px);
    }
  }
}

// Margin Top 20px
.mt-20 {
  margin-top: 1.25rem !important;
}

// Margin Top 30px
.mt-30 {
  margin-top: 1.875rem !important;
}

// Margin Top 30px
.mt-40 {
  margin-top: 2.5rem !important;
}

// Margin Top 50px
.mt-50 {
  margin-top: 3.125rem !important;
}

// Margin Top 60px
.mt-60 {
  margin-top: 3.75rem !important;
}

// Margin Top 70px
.mt-70 {
  margin-top: 4.375rem !important;
}

// Width 70 %
.w-70 {
  width: 70% !important;
}

// Max Width 75%
.mw-75 {
  max-width: 75% !important;
}

//Margin bottom 10 px
.mb-10 {
  margin-bottom: $size10 !important;
}

//Margin bottom 20 px
.mb-20 {
  margin-bottom: 1.25rem !important;
}

//Margin bottom 30 px
.mb-30 {
  margin-bottom: 1.875rem !important;
}

// Margin bottom 40px
.mb-40 {
  margin-bottom: 2.5rem !important;
}

// Margin Top 70px
.mb-70 {
  margin-bottom: 4.375rem !important;
}

//Margin left 10 px
.ml-10 {
  margin-left: 0.625rem !important;
}

//Margin left 20 px
.ml-20 {
  margin-left: 1.25rem !important;
}

//Margin left 30 px
.ml-30 {
  margin-left: 1.875rem !important;
}

.ml-3:not(.sub-heading) {
  margin-left: 0.1875rem !important;
}

//Margin right 10 px
.mr-10 {
  margin-right: 0.625rem !important;
}

//Margin right 30 px
.mr-30 {
  margin-right: 1.875rem !important;
}

// Margin Right 70px
.mr-70 {
  margin-right: 4.375rem !important;
}

// Padding Left 30px
.pl-30 {
  padding-left: 1.875rem !important;
}

.h-10 {
  height: 10% !important;
}

.auth {
  background-color: #f26535;
  font-size: $size10;
  color: $white;
  font-weight: $font-weight-bold;
  padding: 5px 8px;
  cursor: pointer;
  @include border-radius(12px);
}

.drop-down-option {
  padding: 15px 0 !important;
  background: $white;
  border: 1px solid rgba(45, 140, 255, 0.2);
  @include box-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
  @include border-radius(5px);
  @include user-select(none);
  .drop-down-item {
    color: $black;
    font-size: $size14;
    font-weight: $font-weight-regular;
    a,
    span.list-item {
      display: block;
      color: $black;
      &:hover {
        background-color: $yellow;
        color: $black;
        font-weight: $font-weight-bold;
      }
    }
    &:hover {
      background-color: $yellow;
      color: $black;
      font-weight: $font-weight-bold;
    }
  }
}

.notification-item {
  display: flex;
  padding: 0;
  background: transparent;
  .shortname {
    width: 36px;
    height: 36px;
    font-family: $ubuntu-font;
    font-size: $size14;
    font-weight: $font-weight-medium;
    color: $white;
    margin-right: 10px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    background: $lightblack;
    text-transform: uppercase;
    @include border-radius(8px);
    &-bg-0 {
      background-color: $primary;
    }
    &-bg-1 {
      background-color: #2ed573;
    }
    &-bg-2 {
      background-color: $dodger-blue;
    }
    &-bg-3 {
      background-color: $gray-trout;
    }
  }
  .content-description {
    .title {
      color: $lightblack;
    }
    .label {
      color: $gray;
      font-size: $size12;
    }
  }
  &.read {
    background-color: #ffffff !important;
    .title {
      color: #6d6e71 !important;
    }
  }
}

.task-item {
  display: flex;
  padding: 0;
  background: transparent;
  .shortname {
    width: 36px;
    height: 36px;
    font-family: $ubuntu-font;
    font-size: $size14;
    font-weight: $font-weight-medium;
    color: $white;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include border-radius(8px);
    &-bg-0 {
      background-color: $primary;
    }
    &-bg-1 {
      background-color: #2ed573;
    }
    &-bg-2 {
      background-color: $dodger-blue;
    }
    &-bg-3 {
      background-color: $gray-trout;
    }
    &-bg-4 {
      background-color: $burnt-sienna;
    }
  }
  .content-description {
    .title {
      color: $lightblack;
    }
  }
  .label {
    color: $gray;
    font-size: $size12;
  }
}

.search-box-input {
  height: 45px;
  position: relative;
  border-bottom: 1px solid #dfddf3;
  input {
    height: 100%;
    padding-right: 40px;
    background-color: $gray-400;
    border: none;
    outline: none;
    font-size: $base-font-size;
    @include border-radius(none);
    @include box-shadow(none);
    &:active,
    &:focus {
      border: none;
      outline: none;
      background-color: $gray-400;
      @include border-radius(none);
      @include box-shadow(none);
    }
    &:focus ~ .focus-line {
      width: 100%;
    }
  }
  .focus-line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: $dodger-blue;
    transition: 0.4s;
  }
  i {
    position: absolute;
    top: 16px;
    right: 12px;
    z-index: 1;
    cursor: pointer;
    &::before {
      color: $lightblack;
    }
  }
}

.notification-list-item {
  padding: 10px 12px !important;
  .content-description {
    word-break: break-all;
  }
}

.notification-description {
  display: flex;
  align-items: center;
  word-break: break-word;
  .icon-block {
    flex: 0 0 auto;
    width: 26px;
    height: 26px;
    border: 1px solid #bdbdbd;
    margin-right: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include border-radius(50%);
    i::before {
      color: #bdbdbd;
    }
  }
  &-block {
    h4 {
      color: $lightblack;
    }
  }
  .label-text {
    color: $gray-trout;
    font-size: $base-font-size;
  }
}

.task-description {
  display: flex;
  align-items: center;
  .icon-block {
    flex: 0 0 auto;
    width: 26px;
    height: 26px;
    border: 1px solid #bdbdbd;
    margin-right: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include border-radius(50%);
    i::before {
      color: #bdbdbd;
    }
  }
  &-block {
    h4 {
      color: $lightblack;
    }
  }
  .label-text {
    color: $gray-trout;
    font-size: $base-font-size;
  }
}

.text-dull {
  color: $silver;
}

.text-capital {
  text-transform: capitalize !important;
}

.badge.badge-pill.badge-danger {
  background-color: #f2653a1a;
  color: #f26535;
  font-size: $size12;
}

.badge.badge-success.badge-pill {
  color: #40d1da;
  background-color: $java;
  font-size: $size12;
}

.minimize {
  content: "";
  width: 16px;
  height: 2px;
  background-color: $white;
  display: inline-block;
  transform: rotate(180deg);
  cursor: pointer;
}

table {
  .status {
    padding: 4px 6px;
    font-size: 11px;
    line-height: 15px;
    font-weight: $font-weight-bold;
    color: $white;
    text-align: center !important;
    // @include border-radius(4px);
    &-malibu {
      background-color: $maliblu;
    }
    &-light-green {
      background-color: #96b859;
    }
    &-voilet {
      background-color: #984ba4;
    }
    &-light-blue {
      background-color: #48b2bc;
    }

    &-pink-salmon {
      background-color: $pink-salmon;
    }
    &-yellow-orange {
      background-color: $yellow-orange;
    }
    &-light-brown {
      background-color: #b28a42;
    }

    &-malachite {
      background-color: $malachite;
    }
    &-amber {
      background-color: #fb4;
    }
    &-dodger-blue {
      background-color: #5093e1;
    }
    &-danger {
      background-color: $red;
    }
    &-tea-green {
      background-color: #2eb66e;
    }
    &-false {
      background-color: $red;
    }
    &-met-blue {
      background-color: #7624a4;
    }
  }
}

/*table {
  .status {
    padding: 4px 6px;
    font-size: $size10;
    font-weight: $font-weight-bold;
    color: $white;
    @include border-radius(4px);
    &-malibu {
      background-color: $maliblu;
    }
    &-pink-salmon {
      background-color: $pink-salmon;
    }
    &-yellow-orange {
      background-color: #f9dca8;
      color: #d68b00;
    }
    &-malachite {
      background-color: #e3fbdd;
      color: #00c655;
    }
    &-amber {
      background-color: #fcf4dd;
      color: $amber;
    }
    &-dodger-blue {
      background-color: #e8f6fd;
      color: $dodger-blue;
    }
    &-danger,
    &-false {
      background-color: #ffb5b5;
      color: #eb5757;
    }
    &-tea-green {
      background-color: #b5f7a3;
      color: #288404;
    }
  }
}*/

.truncate {
  &-one {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.star {
  color: $amber;
  font-size: $size20 !important;
}

.icon-minimize-alt {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(-45deg);
  width: 22px;
  height: 2px;
  border-left: 10px solid;
  border-right: 10px solid;
  &::after,
  &::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 6px;
    height: 6px;
    border-top: 2px solid;
    top: -2px;
  }
  &::before {
    border-right: 2px solid;
    left: -7px;
    transform: rotate(45deg);
  }
  &::after {
    border-left: 2px solid;
    right: -7px;
    transform: rotate(-45deg);
  }
}

.redirectionLink {
  color: #206ecc;
  cursor: pointer;
}

.read-more-collapse {
  font-weight: $font-weight-semibold;
  font-size: $h6-size !important;
  color: $dodger-blue !important;
  cursor: pointer;
}

