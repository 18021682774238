@font-face {
  font-family: "icomoon";
  src: url("../../fonts/icomoon.eot?9cpox3");
  src: url("../../fonts/icomoon.eot?9cpox3#iefix") format("embedded-opentype"),
    url("../../fonts/icomoon.ttf?9cpox3") format("truetype"),
    url("../../fonts/icomoon.woff?9cpox3") format("woff"),
    url("../../fonts/icomoon.svg?9cpox3#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak-as: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-items:before {
  content: "\e96c";
}
.icon-item:before {
  content: "\e96c";
}
.icon-auctions:before {
  content: "\e96c";
}
.icon-access_time:before {
  content: "\e95e";
}
.icon-check-circle:before {
  content: "\e968";
}
.icon-edit:before {
  content: "\e964";
}
.icon-power:before {
  content: "\e965";
}
.icon-share:before {
  content: "\e967";
}
.icon-thumbs-up:before {
  content: "\e97f";
}
.icon-user:before {
  content: "\e966";
}
.icon-x-circle:before {
  content: "\e969";
}
.icon-trophy:before {
  content: "\e987";
}
.icon-rupee-icon:before {
  content: "\e986";
  color: #34ba5d;
}
.icon-calendar-event:before {
  content: "\e984";
}
.icon-currency-event:before {
  content: "\e985";
}
.icon-savings-event:before {
  content: "\e9a3";
}
.icon-suppliers-event:before {
  content: "\e9a4";
}
.icon-t-n-c:before {
  content: "\e9a5";
}
.icon-productmaster:before {
  content: "\e9a6";
}
.icon-Technical-Response:before {
  content: "\e980";
}
.icon-notes-icon:before {
  content: "\e981";
}
.icon-comment-icon:before {
  content: "\e982";
}
.icon-Financial-Response:before {
  content: "\e983";
}
.icon-Tasks-To-Do:before {
  content: "\e979";
}
.icon-Recent-Activity:before {
  content: "\e97a";
}
.icon-My-Requests:before {
  content: "\e97b";
}
.icon-Recent-Comments:before {
  content: "\e97c";
}
.icon-Pending-Approval-Requests:before {
  content: "\e97d";
}
.icon-Available-Budgets:before {
  content: "\e97e";
}
.icon-sd_bids:before {
  content: "\e978";
}
.icon-Terms-and-Conditions:before {
  content: "\e98a";
}
.icon-Evaluation-Criteria:before {
  content: "\e98b";
}
.icon-Scope-of-Work:before {
  content: "\e98c";
}
.icon-Evaluation-Team:before {
  content: "\e98d";
}
.icon-Bid-Response:before {
  content: "\e98e";
}
.icon-Instructions:before {
  content: "\e98f";
}
.icon-sd_inventory:before {
  content: "\e974";
}
.icon-sd_mysubscription:before {
  content: "\e975";
}
.icon-subscription:before {
  content: "\e976";
}
.icon-dashboard-1:before {
  content: "\e977";
}
.icon-administration-new:before {
  content: "\e973";
}
.icon-requisition-new:before {
  content: "\e972";
}
.icon-user-management-new:before {
  content: "\e96a";
}
.icon-sd_invoices-new:before {
  content: "\e96b";
}
.icon-sd_products-services-new:before {
  content: "\e96c";
}
.icon-sd_mytask-new:before {
  content: "\e96d";
}
.icon-sd_suppliers-new:before {
  content: "\e96e";
}
.icon-sd_spend-analysis-new:before {
  content: "\e96f";
}
.icon-sd_sourcing-new:before {
  content: "\e970";
}
.icon-sd_integration:before {
  content: "\e971";
}
.icon-edit-icon:before {
  content: "\e961";
}
.icon-remove:before {
  content: "\e962";
}
.icon-eye-view:before {
  content: "\e963";
}
.icon-admin-contact:before {
  content: "\e95d";
}
.icon-clear-chat:before {
  content: "\e960";
  color: #efba17;
}
.icon-checkbox:before {
  content: "\e95c";
  color: #5d4ce5;
}
.icon-chat-bot:before {
  content: "\e958";
  color: #fff;
}
.icon-chat-hamburger:before {
  content: "\e959";
  color: #aaa;
}
.icon-chat-send:before {
  content: "\e95a";
  color: #aaa;
}
.icon-chat-speaker:before {
  content: "\e95b";
  color: #aaa;
}
.icon-shuffle:before {
  content: "\e95f";
  color: #bdbdbd;
}
.icon-Add-Image:before {
  content: "\e90a";
  color: #133563;
}
.icon-Add-Question:before {
  content: "\e92a";
  color: #368BFC;
}
.icon-Add-Video:before {
  content: "\e941";
  color: #133563;
}
.icon-Dropdown:before {
  content: "\e952";
  color: #5d4ce5;
}
.icon-Duplicate:before {
  content: "\e953";
  color: #5d4ce5;
}
.icon-Import-Question:before {
  content: "\e954";
  color: #133563;
}
.icon-Multiple-Choice:before {
  content: "\e955";
  color: #5d4ce5;
}
.icon-Paragraph:before {
  content: "\e956";
  color: #5d4ce5;
}
.icon-Short-Answer:before {
  content: "\e957";
  color: #5d4ce5;
}
.icon-user-management:before {
  content: "\e94f";
  color: #133563;
}
.icon-administration:before {
  content: "\e950";
  color: #133563;
}
.icon-edit1:before {
  content: "\e94c";
  color: #5d4ce5;
}
.icon-no:before {
  content: "\e94d";
  color: #ff2a2a;
}
.icon-yes:before {
  content: "\e94e";
  color: #46cb5b;
}
.icon-mobile-new:before {
  content: "\e91f";
  color: #a6aab5;
}
.icon-map:before {
  content: "\e920";
  color: #a6aab5;
}
.icon-mail-new:before {
  content: "\e921";
  color: #133563;
}
.icon-table-view:before {
  content: "\e922";
  color: #f37658;
}
.icon-list-view:before {
  content: "\e923";
  color: #f37658;
}
.icon-hide-password:before {
  content: "\e925";
  color: #989898;
}
.icon-show-password:before {
  content: "\e93f";
  color: #989898;
}
.icon-reset-password:before {
  content: "\e940";
  color: #133563;
}
.icon-notification:before {
  content: "\e948";
  color: #133563;
}
.icon-gridview:before {
  content: "\e949";
  color: #f37658;
}
.icon-question-arrow:before {
  content: "\e951";
  color: #5d4ce5;
}
.icon-print:before {
  content: "\e94a";
  color: #133563;
}
.icon-po-download:before {
  content: "\e94b";
  color: #133563;
}
.icon-time:before {
  content: "\e91a";
  color: #2d8cff;
}
.icon-quick-links:before {
  content: "\e924";
  color: #ff6900;
}
.icon-export-as:before {
  content: "\e943";
  color: #989898;
}
.icon-enqquires:before {
  content: "\e944";
  color: #efba17;
}
.icon-disable-account:before {
  content: "\e945";
  color: #989898;
}
.icon-delete-account:before {
  content: "\e946";
  color: #989898;
}
.icon-contracts:before {
  content: "\e947";
  color: #77b3ff;
}
.icon-back-arrow:before {
  content: "\e93e";
}
.icon-Authorized:before {
  content: "\e942";
  color: #fff;
}
.icon-addnew:before {
  content: "\e900";
  color: #2d8cff;
}
.icon-all-suppliers:before {
  content: "\e901";
  color: #2d8cff;
}
.icon-approved:before {
  content: "\e902";
  color: #0ce881;
}
.icon-autosave:before {
  content: "\e903";
  color: #2d8cff;
}
.icon-back:before {
  content: "\e904";
}
.icon-banking:before {
  content: "\e905";
  color: #fff;
}
.icon-Businessdetails01:before {
  content: "\e906";
  color: #7eda63;
}
.icon-Businessdetails02:before {
  content: "\e907";
  color: #af73ff;
}
.icon-Businessdetails03:before {
  content: "\e908";
  color: #ff9f9f;
}
.icon-Businessdetails04:before {
  content: "\e909";
  color: #8ba2ff;
}
.icon-calendar2:before {
  content: "\e90b";
}
.icon-Call:before {
  content: "\e90c";
  color: #133563;
}
.icon-certificates:before {
  content: "\e90d";
  color: #fff;
}
.icon-close:before {
  content: "\e90e";
  color: #2d8cff;
}
.icon-company-details:before {
  content: "\e90f";
  color: #fff;
}
.icon-contact-reference:before {
  content: "\e910";
  color: #fff;
}
.icon-delete:before {
  content: "\e911";
  color: #fff;
}
.icon-dropdown-arrow:before {
  content: "\e912";
  color: #2d8cff;
}
.icon-enquires:before {
  content: "\e913";
  color: #0383fe;
}
.icon-expired-documents:before {
  content: "\e914";
  color: #f9415c;
}
.icon-expired-invitation:before {
  content: "\e915";
  color: #9b51e0;
}
.icon-export:before {
  content: "\e916";
  color: #3a436a;
}
.icon-eye:before {
  content: "\e917";
  color: #737373;
}
.icon-eye-slash:before {
  content: "\e918";
  color: #737373;
}
.icon-Filter:before {
  content: "\e919";
  color: #133563;
}
.icon-help:before {
  content: "\e91b";
}
.icon-home:before {
  content: "\e91c";
  color: #fff;
}
.icon-info:before {
  content: "\e91d";
  color: #737373;
}
.icon-language-selection:before {
  content: "\e91e";
  color: #737373;
}
.icon-Location:before {
  content: "\e926";
}
.icon-mail:before {
  content: "\e927";
  color: #fff;
}
.icon-mail-filled:before {
  content: "\e928";
  color: #133563;
}
.icon-mobile:before {
  content: "\e929";
  color: #fff;
}
.icon-ongoing-register:before {
  content: "\e92b";
  color: #ffa912;
}
.icon-saving:before {
  content: "\e92c";
  color: #2ed573;
}
.icon-scope-of-supply:before {
  content: "\e92d";
  color: #fff;
}
.icon-sd_contracts:before {
  content: "\e92e";
  color: #133563;
}
.icon-sd_dashboard:before {
  content: "\e92f";
  color: #133563;
}
.icon-sd_invoices:before {
  content: "\e930";
  color: #133563;
}
.icon-sd_myreports:before {
  content: "\e931";
  color: #133563;
}
.icon-sd_mytask:before {
  content: "\e932";
  color: #133563;
}
.icon-sd_mytasks:before {
  content: "\e933";
  color: #fff;
}
.icon-sd_products-services:before {
  content: "\e934";
  color: #133563;
}
.icon-sd_saving:before {
  content: "\e935";
  color: #2ed573;
}
.icon-sd_sourcing:before {
  content: "\e936";
  color: #133563;
}
.icon-sd_spend-analysis:before {
  content: "\e937";
  color: #133563;
}
.icon-sd_suppliers:before {
  content: "\e938";
  color: #133563;
}
.icon-search:before {
  content: "\e939";
  color: #133563;
}
.icon-sortby:before {
  content: "\e93a";
  color: #3a436a;
}
.icon-Starrating:before {
  content: "\e93b";
  color: #ffd32a;
}
.icon-Website:before {
  content: "\e93c";
  color: #133563;
}
.icon-www:before {
  content: "\e93d";
  color: #fff;
}
