.content-block {
  &-view {
    &-section {
      .categories {
        margin-left: -30px;
        &-block {
          min-width: 180px;
          max-width: 180px;
          height: auto;
          padding: 26px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          border: 1px solid $primary-lighter;
          margin-right: 0px;
          margin-left: 30px;
          margin-bottom: 30px;
          cursor: pointer;
          @include border-radius(8px);
          &:hover,
          &.active {
            background-color: $primary-light;
            @include box-shadow(0px 0px 4px rgba(176, 212, 255, 0.5));
          }
          img {
            margin-bottom: 20px;
          }
          .label-text {
            font-size: $size14;
            font-weight: $font-weight-semibold;
          }
          &:last-child {
            margin-right: 0;
          }
          @media only screen and (max-width: 1024px) {
            min-width: 22.8%;
            margin-left: 15px;
            margin-bottom: 15px;
          }
          @media only screen and (min-width: 1600px) {
            min-width: 14.3%;
          }
        }
      }
      .selectedService {
        @include box-shadow(0px 0px 8px rgba(176, 212, 255, 0.5));
      }
    }
  }
}

.stickybar {
  position: fixed;
  z-index: 999;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 20px 50px;
  background: $white;
  box-shadow: 5px 0px 20px rgba(184, 184, 184, 0.35);
  border-radius: 8px 8px 0px 0px;
  @include user-select(none);
  .services-list {
    max-height: 250px;
    overflow: auto;
    padding-right: 10px;
    &::-webkit-scrollbar-track {
      background-color: $gray-300;
      @include border-radius(10px);
    }
    &::-webkit-scrollbar {
      width: 8px;
      background-color: $gray-300;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $gray-700;
      @include border-radius(10px);
    }
  }
}

.table-list {
  position: relative;
  border-collapse: separate;
  border-spacing: 0 20px;
  thead {
    line-height: 0;
    tr {
      font-size: $size14;
      color: $gray;
    }
  }
  tbody {
    tr {
      &::before {
        content: "";
        border: 1px solid $gray-900;
        width: 100%;
        position: absolute;
        z-index: -1;
        height: 64px;
        @include border-radius(6px);
      }
      td {
        vertical-align: middle;
        span.text-title {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 280px;
        }
      }
    }
  }
}
